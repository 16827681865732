import { Control, useFormState  } from "react-hook-form"
import { FormHelperText } from "@mui/material";
import { Inputs, facilityReservationInputKey } from '../../../../../containers/facilityReservationForm/facilityReservationFormService';
import { getValueByNameStr } from "../../../../../utils/objectUtil";
import { GContainer, GItem, LabelGItemBase } from "../../../../components/common/grids"
import { NumberBox } from '../../../../components/common/inputs/numberBox'
import { TitleLabel } from "../../../../components/common/titleLabel"
import { AttentionLabel } from "../../../../components/common/attentionLabel"
import { translate } from '../../../../../i18n'

const PADDING = '0 8px'

interface IntegerTypeFieldProps {
    name: string
    title: string
    label: string
    unit: string
    required: boolean
    control: Control<Inputs>
    max?: number
    min?: number
    placeholder?: number
    isSubField?: boolean
}

const IntegerTypeField = (props: IntegerTypeFieldProps) => {
    const { 
        name, 
        title,
        label,
        unit,
        required, 
        control,
        max,
        min,
        placeholder,
        isSubField,
    } = props

    const { errors } = useFormState({ control });
    const error = getValueByNameStr(errors, name as facilityReservationInputKey)

    return (
        <GItem xs={12} mt={isSubField? 1: undefined}>
            {
                isSubField
                ?   <LabelGItemBase>{translate(title)}</LabelGItemBase>
                :   <div>
                        <TitleLabel>
                            {
                                required && (
                                    <AttentionLabel>*</AttentionLabel>
                                )
                            }
                            {translate(title)}
                        </TitleLabel>
                    </div>
            }
            <GContainer alignItems="flex-end">
                <GItem xs={1}>
                    <NumberBox
                        name={name}
                        label={translate(label)}
                        max={max}
                        min={min}
                        placeholder={placeholder?.toString()}
                        control={control}
                        required={required}
                    />
                </GItem>
                <GItem xs="auto" style={{ display: 'flex', alignItems: 'center', padding: PADDING }}>
                    {unit}
                </GItem>
            </GContainer>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </GItem>
    )

}

export default IntegerTypeField;
