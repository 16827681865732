import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  executePostAccountIdentityVerifySubmit,
  executePutAccountIdentityVerifyResendEmail,
} from '../../dataAccess/webApi/dao/accountDao'
import { accountIdentityVerifySubmitCategory } from '../../dataAccess/webApi/dto/accountDto'
import { translate } from '../../i18n'
import { getSessionAccessToken, verifyTel } from '../../utils/authUtil'
import { setFormErrors } from '../../utils/reactUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { detectLoginStatus, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'
import { useQueryParams } from '../common/transition'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface QueryParams {
  code?: string
}
interface LocationState {
  from: Location
}

interface Inputs {
  email: string
  emailConfirmation: string
}
type InputTypePropName = keyof Inputs

export const useAction = () => {
  const queryParams = useQueryParams<QueryParams>()
  const location = useLocation<LocationState>()
  const from = location.state?.from
  const { addOperationLog } = useOperationLog()

  const dispatch = useDispatch()
  const errorHandle = useErrorHandle()

  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)

  const [resultCode, setResultCode] = useState<{ verifySubmit?: number; resendEmail?: number }>()

  const formMethods = useForm<Inputs>()

  const verifySubmit = useCallback(
    async (code: string) => {
      dispatch(
        showLoading(
          errorHandle(async () => {
            // メールアドレス検証OK 以降の処理
            const resp = await executePostAccountIdentityVerifySubmit({
              accessToken: await getSessionAccessToken(),
              category: accountIdentityVerifySubmitCategory.email,
              passcode: code,
            })
            if (resp.resultCode) {
              // 検証失敗時
              setResultCode({ verifySubmit: resp.resultCode })
            } else {
              // 検証成功時
              if (!isVerifiedTel) {
                // 電話番号の検証が未実施の場合はワンタイムパスワードを発行し、
                // ワンタイムパスワード確認画面へ遷移
                await verifyTel()
                dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
              }
              await dispatch(detectLoginStatus())
            }
          })
        )
      )
    },
    [dispatch, errorHandle, isVerifiedTel]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    const { code } = queryParams
    if (isVerifiedEmail) {
      return
    }
    if (code) {
      verifySubmit(code)
    } else {
      dispatch(showLoading(errorHandle(async () => await dispatch(detectLoginStatus()))))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []

      if (data.email !== data.emailConfirmation) {
        errors.push({
          name: 'emailConfirmation',
          message: translate('system.error.notSameEmail'),
        })
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000059 })

      setResultCode(undefined)
      if (validateItems(data)) {
        return
      }

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const resp = await executePutAccountIdentityVerifyResendEmail({
              accessToken: await getSessionAccessToken(),
              email: data.email,
            })
            if (resp.resultCode) {
              // 送信失敗時
              setResultCode({ resendEmail: resp.resultCode })
            } else {
              // 送信成功時
              dispatch(notifyMessage(translate('identityEmailVerification.success.resendEmail')))
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [dispatch, errorHandle, validateItems, addOperationLog]
  )

  return {
    from,
    isVerifiedEmail,
    resultCode,
    formMethods,
    onSubmit,
  }
}
