// 画面共通のヘッダー部
//

import { AppBar, Box, styled, Toolbar } from '@mui/material'
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLogoutActions } from '../../../containers/common/application'
import { accountCreateFormUrl, rootUrl } from '../../../containers/common/constant/appUrl'
import { openSideMenu } from '../../../containers/common/store/slices/application'
import { 
  // selectIsLoggedIn,
  selectIsOTPAuthenticated,
} from '../../../containers/common/store/slices/authority'
import { translate } from '../../../i18n'
import { ButtonOutlinedBuleS } from './buttons/buttonOutlinedBuleS'
import { IconLinkButton } from './buttons/iconLinkButton'
import { RightGItem } from './grids'
import { HamburgerMenuIcon } from './icons/hamburgerMenuIcon'
import { SideMenu } from './sideMenu'
import { SystemLogo } from './systemLogo'
import { selectSystemControl } from '../../../containers/common/store/slices/systemControl'
import awsmobile from '../../../aws-exports'

const StyledBar = styled(AppBar)({
  position: 'fixed',
})
const LogoIconButton = styled(IconLinkButton)({
  padding: 0,
})
const HeaderButtom = styled(ButtonOutlinedBuleS)({
  margin: '0 3px',
  borderWidth: 1,
  whiteSpace: 'nowrap',
  minWidth: '5rem',
  minHeight: '2rem',
})
const CityIcon = styled('img')({
  width: 35,
  paddingRight: 5,
})
const Logo = styled(SystemLogo)(({}) => ({
  width: 180,
}))

interface AppHeaderProps {
  isHiddenButton: boolean
}
const ButtonGItem = styled(RightGItem)(({}) => ({
  flexWrap: 'wrap',
  overflow: 'hidden',
  height: '2rem',
  justifyContent: 'end',
  flexDirection: 'row-reverse',
}))

/** 画面共通ヘッダー */
export const AppHeader = memo(function AppHeader(props: AppHeaderProps) {
  const { isHiddenButton } = props
  const { logout } = useLogoutActions()
  const dispatch = useDispatch()

  // const isLoggedIn = useSelector(selectIsLoggedIn)
  const isOTPAuthenticated = useSelector(selectIsOTPAuthenticated)

  const iconImageName = useSelector(selectSystemControl).iconImageName
  const s3BucketNameIcon = useSelector(selectSystemControl).s3BucketNameIcon
  const region = awsmobile.aws_project_region
  const iconUrl = iconImageName
    ? `https://${s3BucketNameIcon}.s3.${region}.amazonaws.com/${encodeURIComponent(iconImageName)}`
    : null

  const open = useCallback(() => dispatch(openSideMenu()), [])

  return (
    <>
      <StyledBar>
        <Toolbar>
          {isHiddenButton ? (
            <div>
              {iconUrl && <CityIcon src={iconUrl} alt="cityIcon" />}
              <Logo />
            </div>
          ) : (
            <LogoIconButton to={rootUrl.url()}>
              {iconUrl && <CityIcon src={iconUrl} alt="cityIcon" />}
              <Logo />
            </LogoIconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {!isHiddenButton && !isOTPAuthenticated && (
            <ButtonGItem>
              <HeaderButtom onClick={logout}>{translate('system.appHeader.login')}</HeaderButtom>
              <HeaderButtom to={accountCreateFormUrl.url()}>{translate('system.appHeader.createAccount')}</HeaderButtom>
            </ButtonGItem>
          )}
          {!isHiddenButton && (
            <IconLinkButton onClick={open} edge="end">
              <HamburgerMenuIcon />
            </IconLinkButton>
          )}
        </Toolbar>
      </StyledBar>
      <SideMenu />
    </>
  )
})
