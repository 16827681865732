// 予約機能で共通する利用予約に関する表示部分を定義
// 情報照会、変更内容確認で共有

import React from 'react'
import { Stack } from '@mui/material'
import { ElapsedMillisecond, formatYmdWeek, toApiHms } from '../../../../utils/dateUtil'
import { RowSpacerGItem } from '../grids'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { GetUseReasonDto } from '../../../../dataAccess/webApi/dto/useReasonDto'
import { GetProjectDetailDto } from '../../../../dataAccess/webApi/dto/projectsDto'
import { ChildReservationInfoType, ParentInfoType, GoingTimeType } from '../../../../containers/common/reservation'
import ChildContent from "./childContent"
import CommonDetailContent from "./commonDetailContent"
import BasicDetailContent from "./basicDetailContent"
import { translate } from '../../../../i18n'

interface Reservation {
  facilityName: string
  
  useReasonCategory?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
  childInfoList: ChildReservationInfoType[]
  parentInfo: ParentInfoType
  // 事業マスタ
  projectMst: GetProjectDetailDto
}

export interface ReservationConfirmation extends Reservation {
  lunchFlag: string
  lunchAcceptFlag: string
  snackFlag: string
  snackAcceptFlag: string
  citizenNote?: string
  
  postponeCancelWaitFlag: string
  postponeCancelWaitAcceptFlag: string
  immediatelyReservationFlag: string
}

export interface ReservationDelete extends ReservationConfirmation {
  goingTime: GoingTimeType
  usageDate: Date | ElapsedMillisecond
  useFromDatetime: Date | ElapsedMillisecond
  useToDatetime: Date | ElapsedMillisecond
  status: string
}

export interface ReservationReference extends ReservationDelete {
  reservationDatetime: Date
  reservationChangeDatetime?: Date
}

/** 入力時に必要なプロパティ */
interface Input {
  reservation: Reservation
  useReason: GetUseReasonDto
  displayType?: never
}
 
/** 入力内容確認時に必要なプロパティ */
interface Confirmation {
  reservation: ReservationConfirmation
  useReason: GetUseReasonDto
  displayType: 'confirmation'
}

/** 利用予約情報削除時の詳細照会に必要なプロパティ */
interface Delete {
  reservation: ReservationDelete
  useReason: GetUseReasonDto
  displayType: 'delete'
}

/** 詳細照会時に必要なプロパティ */
interface Reference {
  reservation: ReservationReference
  useReason: GetUseReasonDto
  displayType: 'reference'
}

export type ReservationDetailGItemsProps = Input | Confirmation | Delete | Reference;

/** 予約機能の利用予約情報コンテンツ部分 */
const ReservationDetailGItems = (props: ReservationDetailGItemsProps) => {
  const isShownReservationItem =
    props.displayType === 'confirmation' || props.displayType === 'delete' || props.displayType === 'reference'
  const isShownReservationDatetimeItem = props.displayType === 'reference'
  
  const childInfoList = props.reservation.childInfoList;

  return (
    <>
      <BasicDetailContent {...props} />
      <RowSpacerGItem />

      {
        childInfoList.map((child, index) => (
          <React.Fragment key={index}>
            <ChildContent 
              childInfo={child}
              projectMst={isShownReservationItem? props.reservation.projectMst: undefined}
            />
            <RowSpacerGItem />
          </React.Fragment>
        ))
      }


      { isShownReservationItem && (
      <>
        <CommonDetailContent 
          lunchAcceptFlag={props.reservation.lunchAcceptFlag}
          lunchFlag={props.reservation.lunchFlag}
          snackAcceptFlag={props.reservation.snackAcceptFlag}
          snackFlag={props.reservation.snackFlag}
          immediatelyReservationFlag={props.reservation.immediatelyReservationFlag}
          postponeCancelWaitAcceptFlag={props.reservation.postponeCancelWaitAcceptFlag}
          postponeCancelWaitFlag={props.reservation.postponeCancelWaitFlag}
        />
        {/* 備考 */}
        <LabelGItem>{translate('system.reservationDetailGItems.label.note')}</LabelGItem>
        <ValueGItem>{props.reservation.citizenNote}</ValueGItem>        
      </>
      )}
      
      { isShownReservationDatetimeItem && (
        <>
          <RowSpacerGItem />
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationReceptionDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.reservationDatetime)}</div>
              <div>{toApiHms(props.reservation.reservationDatetime)}</div>
            </Stack>
          </ValueGItem>
          { props.reservation.reservationChangeDatetime != null && (
            <>
              <LabelGItem>{translate('system.reservationDetailGItems.label.reservationChangeDatetime')}</LabelGItem>
              <ValueGItem>
                <Stack>
                  <div>{formatYmdWeek(props.reservation.reservationChangeDatetime)}</div>
                  <div>{toApiHms(props.reservation.reservationChangeDatetime)}</div>
                </Stack>
              </ValueGItem>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ReservationDetailGItems