import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, styled } from '@mui/material'
import React from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../../i18n'
import { getValueByNameStr } from '../../../../utils/objectUtil'

interface RadioButtonProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /** 横に並べて表示する場合true */
  row?: boolean
  /** 選択肢の値と表示文言配列 */
  buttonValueLabels: { value: string; label: string }[]
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
  /** 読み取り専用の場合true */
  disabled?: boolean
  
  validation?: (value: any) => true | string
}

const Root = styled(RadioGroup)(() => ({
  '& .MuiRadio-root': {
    padding: '0 9px',
  },
}))

/** ラジオボタンフォームコントロール */
export const RadioButtonGroup = (props: RadioButtonProps) => {
  const { name, label, required, buttonValueLabels, control, disabled, validation, ...through } = props
  const {
    field: { ref, ...ctrlProps },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: {
        value: !!required,
        message: translate('system.error.requiredSelection', label),
      },
      validate: (validation 
        ? {
          customRule: validation
        }: {})
    },
    defaultValue: '',
    control,
  })

  const error = getValueByNameStr(errors, name)
  return (
    <FormControl error={!!error} required={required} disabled={disabled}>
      <Root {...ctrlProps} {...through}>
        {buttonValueLabels.map(({ value, label }, index) => (
          <FormControlLabel
            key={value}
            {...{ value, label }}
            control={<Radio />}
            {...(index === 0 && { inputRef: ref })}
          />
        ))}
      </Root>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
