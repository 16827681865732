export const GRID_SIZE = {
  /** 通常用 */
  NORMAL: [7, 'auto'],
  /** 文字数が長い場合用(基本は時間外料金に適用) */
  LONG: ['auto', 'auto'],
  /** 共通の料金用 */
  COMMON: [8, 'auto'],
  /** 利用料金設定にて平日のみ設定されている場合の通常用 */
  NORMAL_ONLY_DAY: [4, 'auto'],
  /** 利用料金設定にて平日のみ設定されている場合の共通の料金用 */
  COMMON_ONLY_DAY: [5, 'auto'],
} as const
export type GRID_SIZE = (typeof GRID_SIZE)[keyof typeof GRID_SIZE]
