import React from 'react';
import { Control } from 'react-hook-form';
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import SelectBoxField from "./common/selectBoxField"

export interface DiagonosisFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}
const DiagonosisField: React.FC<DiagonosisFieldProps> = (props) => {
    const { childNum, required, options, control } = props;
    
    const firstName = `children.${childNum}.diagonosis.first`
    const secondName = `children.${childNum}.diagonosis.second`
    const showOtherSelection = ['その他'];
    
    return (
        <>
            <SelectBoxField 
                title={"facilityReservationForm.form.label.diagonosis.field"}
                name={firstName}
                selectLabel={"facilityReservationForm.form.label.diagonosis.field"}
                otherLabel={"facilityReservationForm.form.label.diagonosis.other"}
                required={required}
                control={control}
                options={options}
                showOtherSelection={showOtherSelection}
            />
            <SelectBoxField 
                title={"facilityReservationForm.form.label.diagonosis2.field"}
                name={secondName}
                selectLabel={"facilityReservationForm.form.label.diagonosis2.field"}
                otherLabel={"facilityReservationForm.form.label.diagonosis2.other"}
                required={false}
                control={control}
                options={options}
                addUnselected
                showOtherSelection={showOtherSelection}
            />
        </>
    )
    
}
export default DiagonosisField;
