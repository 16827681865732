import { Stack, styled } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import {
  interviewReservationStep,
  interviewReservationStep2,
  yesNo,
  interviewAcceptMethod
} from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationCompletion/interviewReservationCompletionService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { CompletionMessageLabel } from '../components/common/completionMessageLabel'
import { CenterGItem, GContainer, GItem, RowSpacerGItem } from '../components/common/grids'
import { StepBar } from '../components/common/stepBar'

const CompletionMessageGItem = styled(CenterGItem)({
  padding: '35px 0',
})
const SubMessageGItem = styled(GItem)({
  whiteSpace: 'pre-wrap',
  display: 'flex',
  justifyContent: 'center',
})
const AttentionMessageLabel = styled(AttentionLabel)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL2,
}))

export const InterviewReservationCompletion = () => {
  const { steps, interviewDailyAcceptFlag, facility } = useAction()
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <StepBar
            activeStepValue={
              (interviewDailyAcceptFlag === yesNo.yes
                && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
              ? interviewReservationStep2.step4
              : interviewReservationStep.step3
            }
            stepValueLabels={steps}
          />
          <GContainer>
            <CompletionMessageGItem xs={12}>
              <CompletionMessageLabel>
                {translate('interviewReservationCompletion.label.completion')}
              </CompletionMessageLabel>
            </CompletionMessageGItem>
            <CenterGItem xs={12}>
              <AttentionMessageLabel>
                {
                  (interviewDailyAcceptFlag === yesNo.yes
                    && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
                  ? translate('interviewReservationCompletion.text.mainMessage.dailyAccept')
                  : translate('interviewReservationCompletion.text.mainMessage.dailyNotAccept')
                }
              </AttentionMessageLabel>
            </CenterGItem>
            {facility.interviewRequestCompletionInformation
              && facility.interviewRequestCompletionInformation.length && (
              <>
                <RowSpacerGItem />
                <SubMessageGItem xs={12}>
                  {facility.interviewRequestCompletionInformation}
                </SubMessageGItem>
              </>
            )}
            {facility
              && !(interviewDailyAcceptFlag === yesNo.yes
                  && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots) && (
              <>
                <RowSpacerGItem />
                <GContainer>
                  <CenterGItem xs={12}>
                    <GItem>{facility.facilityName}</GItem>
                  </CenterGItem>
                  <CenterGItem xs={12}>
                    <GItem>{facility.tel}</GItem>
                  </CenterGItem>
                  {facility.inquiryTime && facility.inquiryTime.length && (
                    <>
                      <RowSpacerGItem />
                      <CenterGItem xs={12}>
                        <GItem>{'【' + translate('interviewReservationCompletion.label.inquiryTime') + '】'}</GItem>
                      </CenterGItem>
                      <CenterGItem xs={12}>
                        <GItem>{facility.inquiryTime}</GItem>
                      </CenterGItem>
                    </>
                  )}
                </GContainer>
              </>
            )}
            <RowSpacerGItem />
            <SubMessageGItem xs={12}>{translate('interviewReservationCompletion.text.subMessage')}</SubMessageGItem>
          </GContainer>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
