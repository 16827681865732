import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import SelectBoxField from "./common/selectBoxField"

export interface ToiletAbilityFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const ToiletAbilityField = (props: ToiletAbilityFieldProps) => {
    const { childNum, required, options, control } = props;
    
    const name = `children.${childNum}.toiletAbility`
    
    return (
        <SelectBoxField 
            title={"facilityReservationForm.form.label.toiletAbility"}
            name={name}
            selectLabel={"facilityReservationForm.form.label.toiletAbility"}
            required={required}
            control={control}
            options={options}
            addUnselected
        />
    )
}

export default ToiletAbilityField;

