// 登園・降園予定時間表示制御
export const GOING_DATETIME_DISPLAY_METHODS = {
  NOT_USED: '1',
  USED_ALL_FACILITY: '2',
  /**
   * TODO: 施設毎の制御が必要になった際に復活させる
   *       [decode_mstにデータ追加 & nameの変更が必要](https://docs.google.com/spreadsheets/d/1fa9Ugn0nRODwvUYxQxL16XmNYQtaTUDVBzXWlIKSA-k/edit?gid=1735270161#gid=1735270161&range=AH125)
   *       `/FexibleChildcareManagement/amplify/backend/function/projectMst/src/schema/regProjectMst.json`の90行目辺りにあるpatternの修正が必要
   */
  // SET_BY_FACILITY: '3',
} as const
export type GOING_DATETIME_DISPLAY_METHODS = (typeof GOING_DATETIME_DISPLAY_METHODS)[keyof typeof GOING_DATETIME_DISPLAY_METHODS]

// 項目利用
export const INPUT_FIELD_USAGE = {
  NO_USE: '0',
  OPTIONAL: '1',
  NECESSARY: '2',
} as const

//　医師連絡票アップロード
export const MEDICAL_DOC_USAGE = {
  NO_USE: '0',
  OPTIONAL: '1',
  NECESSARY: '2',
  TILL_UPLOAD: '3'
} as const

