import { Box, Stack, styled } from '@mui/material'
import { 
  interviewReservationFormUrl,
  facilityDetailUrl,
  interviewReservationSelectionCreateUrl
} from '../../containers/common/constant/appUrl'
import { loginUrl } from '../../containers/common/constant/appUrl'
import { yesNo, interviewAcceptMethod } from '../../containers/common/constant/classification'
import { useAction, convertToDate } from '../../containers/facilityDetailAvailability/facilityDetailAvailabilityService'
import { translate, translateNode } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { AvailabilityCalendar } from '../components/common/inputs/availabilityCalendar'
import { DatePickerNoBind } from '../components/common/inputs/datePickerNoBind'
import { Form } from '../components/common/inputs/form'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { TitleLabel } from '../components/common/titleLabel'
import { AttentionLabel } from '../components/common/attentionLabel'
import { UseReasonRadioGroup } from '../components/facilityReservationForm/useReasonRadioGroup'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'

const FacilityNoteCard = styled(InfoCard)({
  whiteSpace: 'pre-wrap',
})

const getChildAgeStr = (isLoggedInDisp: boolean | undefined) => {
  if (isLoggedInDisp) {
    return 'facilityDetailAvailability.label.selectionChild'
  }
  return 'facilityDetailAvailability.label.ageClass'
}

// だし分けが必要 => 一旦非表示に統一
// const renderText = () => {
//   return (
//     <Box>お子さま、利用目的、利用希望日を選択してください。</Box>
//   )
// }

const getUsageGuideStr = (isLoggedInDisp: boolean | undefined, facility: NullPropsToUndefinedType<GetFacilityDto>) => {
  if (facility.immediatelyReservationFlag === yesNo.yes) {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (isLoggedInDisp) {
        return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.1'
      } else {
        return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
      }
    } else {
      if (isLoggedInDisp) {
        return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.2'
      } else {
        return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
      }
    }
  } else {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        if (isLoggedInDisp) {
          return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.3'
        } else {
          return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
        }
      } else {
        if (isLoggedInDisp) {
          return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.5'
        } else {
          return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
        }
      }
    } else {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        if (isLoggedInDisp) {
          return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.4'
        } else {
          return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
        }
      } else {
        if (isLoggedInDisp) {
          return 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.6'
        } else {
          return 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
        }
      }
    }
  }
}

/** 共通設定時のレンダリング */
const FacilityDetailAvailabilityCommon = () => {
  const {
    facilityId,
    facility,
    childs,
    activeChildId,
    activeDate,
    availabilities,
    formMethods,
    isExistPermitChilds,
    currentLocation,
    isLoggedIn,
    onNextPrevExec,
    changeChild,
    changeChildBirthday,
    changeDate,
    callTel,
    sysCtrl,
    minBirthday,
    maxBirthday
  } = useAction()

  if (facility == null) {
    return null
  }

  const isInterviewSlots = sysCtrl.interviewDailyAcceptFlag === yesNo.yes
                            && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots
  const isPreinterviewAccept = facility.preinterviewAcceptFlag === yesNo.yes
  const isLoggedInDisp = isExistPermitChilds

  const childAgeStr = getChildAgeStr(isLoggedInDisp)
  const usageGuideStr = getUsageGuideStr(isLoggedInDisp, facility)

  return (
    <Stack spacing={2}> 
      <Form formMethods={formMethods}>
        <GContainer>
        <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Box>
              <GContainer rowSpacing={1}>
                <GItem xs={12}>
                  <Link to={facilityDetailUrl.url(facilityId)}>
                    <TitleLabel>{facility.facilityName}</TitleLabel>
                  </Link>
                </GItem>
                {/** 
                  10/09 非表示統一に一旦変更
                  <GItem xs={12}>{renderText()}</GItem>
                */}
                <GItem xs={12}>
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      {translate(childAgeStr)}
                    </MiddleGItem>
                    {
                      ( isLoggedInDisp && childs )
                      ? <MiddleGItem xs={7} md={8} mb={1}>
                          <SelectNoBind fullWidth onChange={changeChild} valueLabels={childs} value={activeChildId} />
                        </MiddleGItem>
                      : <MiddleGItem xs={7} md={8} mb={1}>
                          <DatePickerNoBind fullWidth value={activeChildId?convertToDate(activeChildId): null} onChange={changeChildBirthday} minDate={minBirthday} maxDate={maxBirthday}/>
                        </MiddleGItem>
                    }
                  </GContainer>
  
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      {translate('facilityDetailAvailability.label.selectTheDesiredDateOfUse')}
                    </MiddleGItem>
                    <MiddleGItem xs={7} md={8}>
                      <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                    </MiddleGItem>
                    <RowSpacerGItem />
                  </GContainer>
                </GItem>
  
                <GItem xs={12}>
                  <Stack spacing={2}>
                    <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>
                    {
                      activeDate
                      ? <AvailabilityCalendar
                          name="usageDesiredDatetimes"
                          label={translate('facilityDetailAvailability.label.availabilityCalendar')}
                          baseDate={activeDate}
                          receptionTimePattern={facility.receptionTimePattern}
                          availabilities={availabilities ?? []}
                          onChangeBaseDate={onNextPrevExec}
                          control={formMethods.control}
                          isDisplayOnly={true}
                        />
                      : <></>
                    }
                  </Stack>
                </GItem>
  
                <GItem xs={12}>
                  {facility && facility.detailSetting.length && facility.detailSetting.map ((e, index) => {
                    return (<FacilityNoteCard key={index}>{e.note}</FacilityNoteCard>)
                  })}
                </GItem>
              </GContainer>
            </Box>
            <div>{translate('facilityDetailAvailability.label.annotation')}</div>
            {!isLoggedIn && (
              <div>
                {translateNode(
                  'facilityDetailAvailability.text.loginAnnotation',
                  <Link
                    to={{
                      pathname: loginUrl.url(),
                      state: { from: currentLocation },
                    }}
                    replace
                  >
                    {translate('facilityDetailAvailability.text.loginHere')}
                  </Link>
                )}
              </div>
            )}
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
  
     
        <BottomButtonGroup>
          {isLoggedInDisp &&
            facility.reservationAcceptFlag === yesNo.no &&
            facility.notLoginCalendarDisplayFlag === yesNo.yes && (
              <ButtonOutlinedWarningL fullWidth onClick={() => callTel(facility.tel)}>
                {translate('facilityDetailAvailability.button.telReservation')}
              </ButtonOutlinedWarningL>
          )}
          {isPreinterviewAccept && !isInterviewSlots && (
            <ButtonOutlinedWarningL fullWidth to={interviewReservationFormUrl.url(facilityId)}>
              {translate('facilityDetailAvailability.button.usageApplication')}
            </ButtonOutlinedWarningL>
          )}
          {isPreinterviewAccept && isInterviewSlots && (
            <ButtonOutlinedWarningL fullWidth to={interviewReservationSelectionCreateUrl.url(facilityId)}>
              {translate('facilityDetailAvailability.button.usageApplication')}
            </ButtonOutlinedWarningL>
          )}
          <BackButton />
        </BottomButtonGroup>
      </Form>
    </Stack>
  )
}

/** 利用目的設定時のレンダリング */
const FacilityDetailAvailabilityUseReason = () => {
  const {
    facilityId,
    facility,
    childs,
    activeChildId,
    activeDate,
    availabilities,
    formMethods,
    isExistPermitChilds,
    currentLocation,
    isLoggedIn,
    onNextPrevExec,
    changeChild,
    changeChildBirthday,
    changeDate,
    callTel,
    sysCtrl,
    useReason,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    isCheckUseReason,
    changeUseReason,
    minBirthday,
    maxBirthday
  } = useAction()

  if (facility == null) {
    return null
  }
  const isInterviewSlots = sysCtrl.interviewDailyAcceptFlag === yesNo.yes
                            && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots
  const isPreinterviewAccept = facility.preinterviewAcceptFlag === yesNo.yes
  const isLoggedInDisp = isExistPermitChilds

  const childAgeStr = getChildAgeStr(isLoggedInDisp)
  const usageGuideStr = getUsageGuideStr(isLoggedInDisp, facility)
  
  return (
    <Stack spacing={2}> 
      <Form formMethods={formMethods}>
        <GContainer>
        <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Box>
              <GContainer rowSpacing={1}>
                <GItem xs={12}>
                  <Link to={facilityDetailUrl.url(facilityId)}>
                    <TitleLabel>{facility.facilityName}</TitleLabel>
                  </Link>
                </GItem>
                {/** 
                  10/09 非表示統一に一旦変更
                  <GItem xs={12}>{renderText()}</GItem>
                */}
                <GItem xs={12}>
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      {translate(childAgeStr)}
                    </MiddleGItem>
                    {
                      ( isLoggedInDisp && childs )
                      ? <MiddleGItem xs={7} md={8} mb={1}>
                          <SelectNoBind fullWidth onChange={changeChild} valueLabels={childs} value={activeChildId} />
                        </MiddleGItem>
                      : <MiddleGItem xs={7} md={8} mb={1}>
                          <DatePickerNoBind fullWidth value={activeChildId?convertToDate(activeChildId): null} onChange={changeChildBirthday} minDate={minBirthday} maxDate={maxBirthday}/>
                        </MiddleGItem>
                    }
                  </GContainer>
              
                  <GContainer>
                    <MiddleGItem xs={5} md={4}>
                      ご利用目的<AttentionLabel>*</AttentionLabel>
                    </MiddleGItem>
                    <MiddleGItem xs={7} md={8} mb={1}>
                      <UseReasonRadioGroup
                        useReason={useReason}
                        facility={facility}
                        purposeOfUse={purposeOfUse}
                        useReasonDetailIrregular={useReasonDetailIrregular}
                        useReasonDetailEmg={useReasonDetailEmg}
                        useReasonDetailRefresh={useReasonDetailRefresh}
                        onChange={changeUseReason} 
                        isHidden={true}
                      />
                    </MiddleGItem>
                  </GContainer>
  
                  {(isCheckUseReason === true) && (
                    <GContainer>
                      <MiddleGItem xs={5} md={4}>
                        {translate('facilityDetailAvailability.label.selectTheDesiredDateOfUse')}
                      </MiddleGItem>
                      <MiddleGItem xs={7} md={8}>
                        <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                      </MiddleGItem>
                      <RowSpacerGItem />
                    </GContainer>
                  )}
                </GItem>
  
                {(isCheckUseReason === true) && (
                  <>
                    <GItem xs={12}>
                      <Stack spacing={2}>
                        <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>
                        {
                          activeDate
                          ? <AvailabilityCalendar
                              name="usageDesiredDatetimes"
                              label={translate('facilityDetailAvailability.label.availabilityCalendar')}
                              baseDate={activeDate}
                              receptionTimePattern={facility.receptionTimePattern}
                              availabilities={availabilities ?? []}
                              onChangeBaseDate={onNextPrevExec}
                              control={formMethods.control}
                              isDisplayOnly={true}
                            />
                          : <></>
                        }
                      </Stack>
                    </GItem>
      
                    <GItem xs={12}>
                      {facility && facility.detailSetting.length && (
                        <FacilityNoteCard >{facility.detailSetting.find((v) => v.useReasonCode === purposeOfUse)?.note}</FacilityNoteCard>
                      )}
                    </GItem>
                  </>
                )}
              </GContainer>
            </Box>
            <div>{translate('facilityDetailAvailability.label.annotation')}</div>
            {!isLoggedIn && (
              <div>
                {translateNode(
                  'facilityDetailAvailability.text.loginAnnotation',
                  <Link
                    to={{
                      pathname: loginUrl.url(),
                      state: { from: currentLocation },
                    }}
                    replace
                  >
                    {translate('facilityDetailAvailability.text.loginHere')}
                  </Link>
                )}
              </div>
            )}
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
  
     
        {(isCheckUseReason === true) && (
          <BottomButtonGroup>
            {isLoggedInDisp &&
              facility.reservationAcceptFlag === yesNo.no &&
              facility.notLoginCalendarDisplayFlag === yesNo.yes && (
                <ButtonOutlinedWarningL fullWidth onClick={() => callTel(facility.tel)}>
                  {translate('facilityDetailAvailability.button.telReservation')}
                </ButtonOutlinedWarningL>
            )}
            {isPreinterviewAccept && !isInterviewSlots && (
              <ButtonOutlinedWarningL fullWidth to={interviewReservationFormUrl.url(facilityId)}>
                {translate('facilityDetailAvailability.button.usageApplication')}
              </ButtonOutlinedWarningL>
            )}
            {isPreinterviewAccept && isInterviewSlots && (
              <ButtonOutlinedWarningL fullWidth to={interviewReservationSelectionCreateUrl.url(facilityId)}>
                {translate('facilityDetailAvailability.button.usageApplication')}
              </ButtonOutlinedWarningL>
            )}
            <BackButton /> 
          </BottomButtonGroup>
        )}
      </Form>
    </Stack>
  )
}

export const FacilityDetailAvailability = () => {
  const {
    facility,
    childs,
    isExistPermitChilds,
    commonFlag,
  } = useAction()

  if (facility === null) return (<></>)
  if (commonFlag === null) return (<></>) 

  return (
    (!isExistPermitChilds && !childs && commonFlag === yesNo.no) ? (
      <FacilityDetailAvailabilityUseReason />
    ) : (
        <FacilityDetailAvailabilityCommon />
    )
  )
}
