// 利用予約情報に関する共通処理を定義
import { executeGetReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { fromApiYmd, fromApiYmdHms, formatYmd, formatHm, ElapsedMillisecond } from '../../utils/dateUtil'
import { nullPropsToUndefined, combineDecimalParts } from '../../utils/objectUtil'
import { DecimalType } from '../../utils/typeUtil'
import { splitDecimal } from '../../utils/stringUtil'
import { yesNo } from './constant/classification'
import { dateToNumber } from '../../utils/objectUtil'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { Flag } from './constant/classification';
import { getDecodeMstValue } from './getDecodeMstValue'
import { GetReservationDto } from '../../dataAccess/webApi/dto/reservationsDto'

export type ParentInfoType = {
  name: string
  kana: string
  tel: string
  email: string
  residenceCategory?: string
}

export type ChildInfoType = {
  name: string
  kana: string
  birthday: string
  gender: string
}

type SelectType = {
  select: string | null
  stringify?: string
}

type SelectAndOtherType = SelectType & {
  other: string | null
}

type DecimalWithStringify = DecimalType & {
  stringify?: string
}

export type DocType = {
  fileName: string | null
  delFlg: string | null
  tempUploadKey: string | null
}

export type MedicalDoc = {
  docs: DocType[]
  hasSubmitted: string
  status?: string
}

export type SymptomType = {
  symptoms: string[]
  other: string | null
  stringify?: string
}

export type DiagonosisType = {
  first: SelectAndOtherType
  second: SelectAndOtherType
  stringify?: string
  stringify2?: string
}

export type MedicineType = {
  rx: string | null
  otc: string | null
}

export type FebrileSeizuresType = string

export type EmergencyAction = {
  action: SelectType
  note: string | null
}

export type EatingMethod = {
  method: SelectType
  detail: SelectType
  milkAmount: DecimalWithStringify
  milkFrequency?: number | null
}

export type AllergyType = {
  flag: string,
  foodFlag: string | null
} 

export type ActionsType = {
  action: SelectType
  sleepMethod: string | null
}

export type ChildReservationInfoType = {
  childId: string
  childInfo?: ChildInfoType
  medicalDoc?: MedicalDoc
  symptom?: SymptomType
  diagonosis?: DiagonosisType
  epidemic?: SelectAndOtherType
  medicine?: MedicineType
  temperature?: DecimalWithStringify 
  febrileSeizures?: string | null
  emergencyAction?: EmergencyAction
  height?: DecimalWithStringify
  weight?: DecimalWithStringify
  eatingMethod?: EatingMethod
  allergy?: AllergyType
  eatingAbility?: SelectType
  actions?: ActionsType
  toiletAbility?: SelectType
  reductionType?: SelectType
}

export type UsageDatetimeType = {
  usageDate: ElapsedMillisecond
  useFromDatetime: ElapsedMillisecond
  useToDatetime: ElapsedMillisecond
  usageBaseDate: string
  status: string
}

export type GoingTimeType = {
  goingToFacilityDate: string
  goingToFacilityTime: string
  goingHomeDate: string
  goingHomeTime: string
}

export type ShowMessage = {
  Date: string;
  message: string;
}

/**
 * 指定した受付Noの利用予約情報を返す。
 *
 * @param reservationNo 受付No
 * @returns 利用予約情報
 */
export const getReservation = async (reservationNo: string) => {
  const response = await executeGetReservation(reservationNo)
  const {
    usageDate,
    useFromDatetime,
    useToDatetime,
    canUpdate,
    canCancel,
    reservationDatetime,
    reservationChangeDatetime,
    name,
    kana,
    tel,
    email,
    childId,
    childName,
    childKana,
    childBirth,
    childGender,
    hasMedicalDocSubmitted,
    symptoms,
    symptomOther,
    diagonosis,
    diagonosisOther,
    diagonosis2,
    diagonosisOther2,
    epidemic,
    epidemicOther,
    rxMedicineFlag,
    otcMedicineFlag,
    bodyTemperature,
    febrileSeizuresFlag,
    emergencyAction,
    emergencyActionNote,
    height,
    weight,
    eatingMethod,
    eatingMethodDetail,
    milkAmount,
    milkFrequency,
    allergyFlag,
    foodAllergyFlag,
    eatingAbility,
    actions,
    sleepMethod,
    toiletAbility,
    reductionType,
    goingToFacilityDatetime, 
    goingHomeDatetime,
    ...other
  } = response.result

  // 病児病後児病児病後児関連書類
  const docs = [];
  for ( let i = 1; i <= 5; i++ ) {
    const medicalDocKey = `medicalDoc${i}` as keyof GetReservationDto;
    const fileNameKey = `medicalDoc${i}FileName` as keyof GetReservationDto;
  
    const medicalDoc = response.result[medicalDocKey] as string;
    const fileName = response.result[fileNameKey] as string;
    
    if ( !medicalDoc ) break;
    docs.push( {
      fileName: fileName,
      delFlg: Flag.OFF,
      tempUploadKey: medicalDoc
    })  
  }
  
  const childInfo: Required<ChildReservationInfoType> = {
    childId: childId,
    childInfo: {
      name: childName,
      kana: childKana,
      birthday: dateToNumber(childBirth),
      gender: childGender
    },
    medicalDoc: {
      docs: docs,
      hasSubmitted: hasMedicalDocSubmitted?? Flag.OFF
    },
    symptom: {
      symptoms: (symptoms) ? symptoms.split(',') : [],
      other: symptomOther?? ''
    },
    diagonosis: {
      first: {
        select: diagonosis,
        other: diagonosisOther?? ''
      },
      second: {
        select: diagonosis2,
        other: diagonosisOther2?? ''
      }
    },
    epidemic: {
      select: epidemic,
      other: epidemicOther?? ''
    },
    medicine: {
      rx: rxMedicineFlag?? Flag.OFF,
      otc: otcMedicineFlag?? Flag.OFF,
    },
    temperature: splitDecimal(bodyTemperature),
    febrileSeizures: febrileSeizuresFlag?? Flag.OFF,
    emergencyAction: {
      action: {
        select: emergencyAction
      },
      note: emergencyActionNote?? ''
    },
    height: splitDecimal(height),
    weight: splitDecimal(weight),
    eatingMethod: {
      method: {
        select: eatingMethod
      },
      detail:  {
        select: eatingMethodDetail
      },
      milkAmount: splitDecimal(milkAmount),
      milkFrequency: milkFrequency
    },
    allergy: {
      flag: allergyFlag?? Flag.OFF,
      foodFlag: foodAllergyFlag
    },
    eatingAbility: {
      select: eatingAbility
    },
    actions: {
      action: {
        select: actions
      },
      sleepMethod: sleepMethod?? ''
    },
    toiletAbility: {
      select: toiletAbility
    },
    reductionType: {
      select: reductionType
    }
  }
  
  // 登降園時間を使用しない設定（事業マスタ）場合は'-'でデータが渡ってくる
  const goingDatetimeConverted = ( goingToFacilityDatetime === '-' )? null: fromApiYmdHms(goingToFacilityDatetime);
  const goingHomeDatetimeConverted = ( goingHomeDatetime === '-' )? null: fromApiYmdHms(goingHomeDatetime);
  
  const useFromDatetimeConverted = fromApiYmdHms(useFromDatetime);
  const useToDatetimeConverted = fromApiYmdHms(useToDatetime);

  return nullPropsToUndefined({
    ...other,
    
    reservationNo,
    
    usageDate: dateToNumber(fromApiYmd(usageDate)),
    useFromDatetime: dateToNumber(useFromDatetimeConverted),
    useToDatetime: dateToNumber(useToDatetimeConverted),
    goingTime: {
      goingToFacilityDate: formatYmd(goingDatetimeConverted? goingDatetimeConverted: useFromDatetimeConverted),
      goingToFacilityTime: formatHm(goingDatetimeConverted? goingDatetimeConverted: useFromDatetimeConverted),
      goingHomeDate: formatYmd(goingHomeDatetimeConverted? goingHomeDatetimeConverted: useToDatetimeConverted),
      goingHomeTime: formatHm(goingHomeDatetimeConverted? goingHomeDatetimeConverted: useToDatetimeConverted)
    },
    parentInfo: {
      name: name,
      kana: kana,
      tel: tel,
      email: email
    },
    childInfoList: [childInfo],
    canUpdate: canUpdate === yesNo.yes,
    canCancel: canCancel === yesNo.yes,
    reservationDatetime: fromApiYmdHms(reservationDatetime),
    reservationChangeDatetime: fromApiYmdHms(reservationChangeDatetime),
  })
}

/** 利用予約情報の型 */
export type Reservation = ReturnType<typeof getReservation> extends Promise<infer T> ? T : never

/**
 * チェックボックス項目の内容を文字列化する
 * @param decodeMst デコードマスタデータ
 * @param param 判定値
 * @param otherParam その他入力情報
 * @returns
 */
export const stringifyCheckBox = (decodeMst: PostDecodeMstDto[], param?: string[] | null, otherParam?: string | null) => {
  if (!param && !otherParam) {
    return ''
  }
  const tmpArray = param
  if (!tmpArray) return ''
  const response = [];
  for (const tmpData of tmpArray) {
    const findData: String | undefined = decodeMst?.find((c) => c.code === tmpData)?.name
    if (findData) {
      response.push(findData)
      continue
    }
  }

  if (otherParam) response.push(otherParam) 
  return response.join(', ')
}

/**
 * セレクトボックス項目の内容を文字列化する
 * @param decodeMst デコードマスタデータ
 * @param param 判定値
 * @param otherParam その他入力情報
 * @param otherParamTrigger その他入力情報を使用するトリガーとなる判定値
 * @returns
 */
export const stringifySelectBox = (decodeMst: PostDecodeMstDto[], param?: string | null, otherParam?: string | null, otherParamTrigger?: string[]) => {
  if ( !param ) {
    return ''
  }
  
  const targetDecodeMstData = decodeMst?.find((c) => c.code === param)
  const name = targetDecodeMstData?.name || '';

  if ((otherParamTrigger?.some((trigger) => name.includes(trigger))) && otherParam) {
    return otherParam?? '';
  }
  
  return name.trimStart();
}

/**
 * 病児病後児関連書類提出ステータス
 */
export const MEDICAL_DOC_SUBMIT_STATUS = {
  UN_SUBMITTED: '未提出',
  UPLOADED: 'アップロード提出済',
  HANDED: '施設に直接提出済み'
} as const;

export type MedicalDocSubmitStatus = typeof MEDICAL_DOC_SUBMIT_STATUS[keyof typeof MEDICAL_DOC_SUBMIT_STATUS];

export const meicalDocSubmitStatusTranslationMap = {
  [MEDICAL_DOC_SUBMIT_STATUS.UN_SUBMITTED]: 'facilityReservation.medicalDocStatus.unsubmitted',
  [MEDICAL_DOC_SUBMIT_STATUS.UPLOADED]: 'facilityReservation.medicalDocStatus.uploaded',
  [MEDICAL_DOC_SUBMIT_STATUS.HANDED]: 'facilityReservation.medicalDocStatus.handed',
};

export const getMeicalDocSubmitStatus = (hasMedicalDocSubmitted: string | null | undefined, medicalDocList: DocType[]| null | undefined) => {
  if ( hasMedicalDocSubmitted === Flag.ON ) return MEDICAL_DOC_SUBMIT_STATUS.HANDED;
  const filteredMedicalDocList = medicalDocList?.filter(( medicalDoc ) => medicalDoc.fileName && medicalDoc.tempUploadKey && medicalDoc.delFlg === Flag.OFF)
  if ( medicalDocList && filteredMedicalDocList && filteredMedicalDocList.length > 0 ) return MEDICAL_DOC_SUBMIT_STATUS.UPLOADED;
  return MEDICAL_DOC_SUBMIT_STATUS.UN_SUBMITTED;
};

export const displayMeicalDocSubmitStatus = (hasMedicalDocSubmitted: string | null | undefined, medicalDocList: DocType[]| null | undefined) => {
  const status = getMeicalDocSubmitStatus(hasMedicalDocSubmitted, medicalDocList);
  return meicalDocSubmitStatusTranslationMap[status];
};

/**
 * デコードマスタ
 */
export type ReservationSettingDecodeMstKey<T> = {
  diagonosis: T
  symptom: T
  epidemic: T
  emergency: T
  eatingMethod: T
  brestFeedingDetail: T
  weaningDetailOptions: T
  eatingAbility: T
  actions: T
  toiletAbility: T
  reduction: T
}

export const DECODE_MST_INPUT_LIST: ReservationSettingDecodeMstKey<string> = {
  /** 病名 */
  diagonosis: 'diagonosis',
  // 症状
  symptom: 'symptom',
  // 流行り病
  epidemic: 'epidemic',
  // 児童の容体が変化した場合
  emergency: 'emergency_action',
  // 栄養方法
  eatingMethod: 'eating_method',
  // 栄養方法＞授乳中詳細
  brestFeedingDetail: 'brest_feeding_detail',
  // 栄養方法＞離乳中詳細
  weaningDetailOptions: 'weaning_detail_options',
  // 食事状況
  eatingAbility: 'eating_ability',
  // 行動
  actions: 'actions',
  // 排泄
  toiletAbility: 'toilet_ability',
  // 排泄
  reduction: 'reduction_type'
}

export type ReservationSettingDecodeMst = ReservationSettingDecodeMstKey<PostDecodeMstDto[]>

export const getReservationDecodeMstValue = async () => {
  return await getDecodeMstValue(DECODE_MST_INPUT_LIST)
}

export const setStringifyNamesChildInfoList = <T extends ChildReservationInfoType>(childInfoList: T[], decodeMstValue: ReservationSettingDecodeMst):T[] => {
  return childInfoList.map((childInfo) => {
    const returnChildInfo = { ...childInfo };
    
    if ( childInfo.medicalDoc ) {
      returnChildInfo.medicalDoc = {
        ...childInfo.medicalDoc,
        status: displayMeicalDocSubmitStatus(childInfo.medicalDoc.hasSubmitted, childInfo.medicalDoc.docs),
      };
    }
    
    if ( childInfo.symptom ) {
      returnChildInfo.symptom = {
        ...childInfo.symptom,
        stringify: stringifyCheckBox(decodeMstValue.symptom, childInfo.symptom.symptoms, childInfo.symptom.other),
      };
    }
    
    if ( childInfo.diagonosis ) {
      returnChildInfo.diagonosis = {
        first: {
          ...childInfo.diagonosis.first,
          stringify: stringifySelectBox(decodeMstValue.diagonosis, childInfo.diagonosis.first.select, childInfo.diagonosis.first.other, ['その他'])
        },
        second: {
          ...childInfo.diagonosis.second,
          stringify: stringifySelectBox(decodeMstValue.diagonosis, childInfo.diagonosis.second.select, childInfo.diagonosis.second.other, ['その他'])
        },
        // 後で削除
        stringify: stringifySelectBox(decodeMstValue.diagonosis, childInfo.diagonosis.first.select, childInfo.diagonosis.first.other, ['その他']),
        stringify2: stringifySelectBox(decodeMstValue.diagonosis, childInfo.diagonosis.second.select, childInfo.diagonosis.second.other, ['その他'])
      };
    }
    
    if ( childInfo.epidemic ) {
      returnChildInfo.epidemic = {
        ... childInfo.epidemic,
        stringify: stringifySelectBox(decodeMstValue.diagonosis, childInfo.epidemic.select, childInfo.epidemic.other, ['その他'])
      }
    }
    
    if ( childInfo.temperature ) {
      returnChildInfo.temperature = {
        ... childInfo.temperature,
        stringify: combineDecimalParts(childInfo.temperature)?.toString()
      }
    }
    
    if ( childInfo.emergencyAction ) {
      returnChildInfo.emergencyAction = {
        ... childInfo.emergencyAction,
        action: {
          select: childInfo.emergencyAction.action.select,
          stringify: stringifySelectBox(decodeMstValue.emergency, childInfo.emergencyAction.action.select)
        }
        
      }
    }
    
    if ( childInfo.height ) {
      returnChildInfo.height = {
        ... childInfo.height,
        stringify: combineDecimalParts(childInfo.height)?.toString()
      }
    }
    
    if ( childInfo.weight ) {
      returnChildInfo.weight = {
        ... childInfo.weight,
        stringify: combineDecimalParts(childInfo.weight)?.toString()
      }
    }
    
    if ( childInfo.eatingMethod ) {
      returnChildInfo.eatingMethod = {
        ... childInfo.eatingMethod,
        method: {
          select: childInfo.eatingMethod.method.select,
          stringify: stringifySelectBox(decodeMstValue.eatingMethod, childInfo.eatingMethod.method.select)
        }
        
      }
    }
    
    if ( childInfo.eatingAbility ) {
      returnChildInfo.eatingAbility = {
        ... childInfo.eatingAbility,
        stringify: stringifySelectBox(decodeMstValue.eatingAbility, childInfo.eatingAbility.select)
      }
    }
    
    if ( childInfo.actions ) {
      returnChildInfo.actions = {
        ... childInfo.actions,
        action: {
          select: childInfo.actions.action.select,
          stringify: stringifySelectBox(decodeMstValue.actions, childInfo.actions.action.select)
        }
      }
    }
    
    if ( childInfo.toiletAbility ) {
      returnChildInfo.toiletAbility = {
        ... childInfo.toiletAbility,
        stringify: stringifySelectBox(decodeMstValue.toiletAbility, childInfo.toiletAbility.select)
      }
    }
    
    if ( childInfo.reductionType ) {
      returnChildInfo.reductionType = {
        ... childInfo.reductionType,
        stringify: stringifySelectBox(decodeMstValue.reduction, childInfo.reductionType.select)
      }
    }
    
    return returnChildInfo;
  })
}
