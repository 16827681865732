import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { CheckBox } from '../../../components/common/inputs/checkBox'
import { translate } from '../../../../i18n'
import { GItem } from "../../../components/common/grids"
import { Grid } from '@mui/material';
import { yesNo } from '../../../../containers/common/constant/classification'
import { TitleLabel } from "../../../components/common/titleLabel"
import { AttentionLabel } from "../../../components/common/attentionLabel"
import PreserveLineText from "./common/preserveLineText"

interface ConsentCheckFieldProps {
    checkNote: string
    control: Control<Inputs>
}

const ConsentCheckField = (props: ConsentCheckFieldProps) => {
    const { checkNote, control } = props;

    return (
        <GItem xs={12}>
            <div style={{ paddingBottom: '10px' }}>
                <TitleLabel>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('facilityReservationForm.form.label.consentCheck')}
                </TitleLabel>
                <PreserveLineText content={checkNote} />
            </div>
            <Grid item>
                <GItem xs={12}>
                    <CheckBox
                        name={'consentCheck'}
                        label={translate('facilityReservationForm.form.label.consentCheck')}
                        valueAtCheck={yesNo.yes}
                        required
                        valicationMessageKeys={{ required: 'system.error.consentInput' }}
                        control={control}
                    >
                        {translate('facilityReservationForm.form.common.isComfirm')}
                    </CheckBox>
                </GItem>
            </Grid>
       </GItem>
    )
}

export default ConsentCheckField;
