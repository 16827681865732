import React, { useState, useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import { reservationEmergencyAction } from "../../../../containers/common/constant/classification"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto'
import { GItem } from "../../../components/common/grids"
import { TextBox } from '../../../components/common/inputs/textBox'
import { translate } from '../../../../i18n'
import SelectBoxField from "./common/selectBoxField"

export interface EmergencyActionFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const EmergencyActionField: React.FC<EmergencyActionFieldProps> = ({ childNum, required, options, control }) => {
    const [ isOtherRequired, setIsOtherRequired ] = useState(false);
    const name = `children.${childNum}.emergencyAction`
    const actionName = `${name}.action`
    
    const actionWatch = useWatch({
        control,
        name:  `${actionName}.select` as unknown as keyof Inputs
    })
    
    useEffect(() => {
        if ( !actionWatch ) return;
        
        if ( actionWatch === reservationEmergencyAction.other ) {
            setIsOtherRequired(true)
            return
        }
        setIsOtherRequired(false)
    }, [actionWatch])
    
    return (
        <SelectBoxField 
                title={"facilityReservationForm.form.label.emergency.field"}
                name={actionName}
                selectLabel={"facilityReservationForm.form.label.emergency.field"}
                required={required}
                control={control}
                options={options}
        >
            <GItem xs={12} sx={{ mt: 1 }}>
                <TextBox 
                    name={`children.${childNum}.emergencyAction.note`}
                    label={translate("facilityReservationForm.form.label.emergency.note")}
                    maxLength={200} 
                    rows={3}
                    required={isOtherRequired}
                    multiline 
                />
            </GItem>
        </SelectBoxField>
    );
};

export default EmergencyActionField;
