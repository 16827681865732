import React from 'react';
import { Control } from 'react-hook-form'
import { ChildReservationInfoType } from '../../../containers/common/reservation'
import { FieldsType, FieldRule, getByChildFormFieldRule } from '../../../containers/common/projectMst'
import { Inputs } from "../../../containers/facilityReservationForm/facilityReservationFormService"
import { GetProjectDetailDto } from "../../../dataAccess/webApi/dto/projectsDto"
import { PostDecodeMstDto } from '../../../dataAccess/webApi/dto/decodeMstDto';
import { GContainer, GItem } from "../../components/common/grids"
import { InfoCard } from "../../components/common/infoCard"
import { TitleLabel } from "../../components/common/titleLabel"
import ChildContent from "../../components/common/reservationDetail/childContent"
import DiagonosisField, { DiagonosisFieldProps }from "./fields/diagonosisField"
import SymptomField, { SymptomFieldProps } from"./fields/symptomField"
import EpidemicField, { EpidemicFieldProps } from "./fields/epidemicField"
import MedicineField, { MedicineFieldProps } from "./fields/medicineField"
import BodyTemperatureField, { BodyTempatureFieldProps } from "./fields/bodyTempatureField"
import FebrileSeizuresField, { FebrileSeizuresFieldProps } from "./fields/febrileSeizuresField"
import MedicalDocField, { MedicalDocFieldProps } from "./fields/medicalDocField"
import EmergencyActionField, { EmergencyActionFieldProps } from "./fields/emergencyActionField"
import HeightField, { HeightFieldProps } from "./fields/heightField"
import WeightField, { WeightFieldProps } from "./fields/weightField"
import EatingMethodField, { EatingMethodFieldProps } from "./fields/eatingMethodField"
import FoodAllergyField, { FoodAllergyFieldProps } from "./fields/foodAllergyField"
import EatingAbilityField, { EatingAbilityFieldProps } from "./fields/eatingAbilityField"
import ActionsField, { ActionsFieldProps } from "./fields/actionsField"
import ToiletAbilityField, { ToiletAbilityFieldProps } from "./fields/toiletAbilityField"
import ReductionTypeField, { ReductionTypeFieldProps } from "./fields/reductionTypeField"

type Field<T> = {
    component:React.FC<T>
    getProps: (rule: FieldRule, childNum: number) => T
}

type SortedField<T> = Field<T> & {
    rule: FieldRule
}

type FieldComponentsType = FieldsType<
    | Field<DiagonosisFieldProps>
    | Field<SymptomFieldProps>
    | Field<EpidemicFieldProps>
    | Field<MedicineFieldProps>
    | Field<BodyTempatureFieldProps>
    | Field<FebrileSeizuresFieldProps>
    | Field<MedicalDocFieldProps>
    | Field<EmergencyActionFieldProps>
    | Field<HeightFieldProps>
    | Field<WeightFieldProps>
    | Field<EatingMethodFieldProps>
    | Field<FoodAllergyFieldProps>
    | Field<EatingAbilityFieldProps>
    | Field<ActionsFieldProps>
    | Field<ToiletAbilityFieldProps>
    | Field<ReductionTypeFieldProps>
>;

type ByChildFormUnitProps = {
    control: Control<Inputs>
    projectMst: GetProjectDetailDto
    childInfoList: ChildReservationInfoType[]
    diagnosisOptions: PostDecodeMstDto[]
    symptomOptions: PostDecodeMstDto[]
    epidemicOptions: PostDecodeMstDto[]
    emergencyOptions: PostDecodeMstDto[]
    eatingMethodOptions: PostDecodeMstDto[]
    brestFeedingDetailOptions: PostDecodeMstDto[]
    weaningDetailOptions: PostDecodeMstDto[]
    eatingAbilityOptions: PostDecodeMstDto[]
    actionsOptions: PostDecodeMstDto[]
    toiletAbilityOptions: PostDecodeMstDto[]
    reductionOptions: PostDecodeMstDto[]
}

const ByChildFormUnit: React.FC<ByChildFormUnitProps> = (props) => {
    const { 
        control, 
        projectMst, 
        childInfoList,
        diagnosisOptions,
        symptomOptions,
        epidemicOptions,
        emergencyOptions,
        eatingMethodOptions,
        brestFeedingDetailOptions,
        weaningDetailOptions,
        eatingAbilityOptions,
        actionsOptions,
        toiletAbilityOptions,
        reductionOptions
    } = props
    
    const fieldComponents: FieldComponentsType = {
        diagonosis:  {
            component: DiagonosisField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: diagnosisOptions,
                control
            })
        },
        symptom: {
            component: SymptomField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: symptomOptions,
                control
            })
        },
        epidemic: {
            component: EpidemicField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: epidemicOptions,
                control
            })
        },
        medicine: {
            component: MedicineField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required
            })
        },
        bodyTemp: {
            component: BodyTemperatureField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                control
            })
        },
        febrileSeizures: {
            component: FebrileSeizuresField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required
            })
        },
        medicalDoc: {
            component: MedicalDocField,
            getProps: (rule, childNum) => ({
                childNum,
                note: projectMst.medicalDocNote,
                medicalDocUsage: projectMst.medicalDocUsage,
                control
            })
        },
        emergency: {
            component: EmergencyActionField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: emergencyOptions,
                control
            })
        },
        height: {
            component: HeightField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                control
            })
        },
        weight: {
            component: WeightField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                control
            })
        },
        eatingMethod: {
            component: EatingMethodField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                methodOptions: eatingMethodOptions,
                brestFeddingDetailOptions: brestFeedingDetailOptions,
                weaningDetailOptions: weaningDetailOptions,
                control
            })
        },
        foodAllergy: {
            component: FoodAllergyField,
            getProps: (rule, childNum) => ({
                childNum: childNum,
                required: rule.required
            })
        },
        eatingAbility: {
            component: EatingAbilityField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: eatingAbilityOptions,
                control
            })
        },
        action: {
            component: ActionsField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: actionsOptions,
                control
            })
        },
        toiletAbility: {
            component: ToiletAbilityField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: toiletAbilityOptions,
                control
            })
        },
        reduction: {
            component: ReductionTypeField,
            getProps: (rule, childNum) => ({
                childNum,
                required: rule.required,
                options: reductionOptions,
                note: projectMst.reductionNote,
                control
            })
        },
    }
    const fieldRule = getByChildFormFieldRule(projectMst)
    const isMultipleChild = childInfoList.length !== 1
    
    const sortedFields: SortedField<any>[] = Object.keys(fieldRule)
        .filter((fieldKey) => fieldRule[fieldKey as keyof FieldsType<FieldRule>].show) 
        .map((fieldKey) => {
            const field = fieldKey as keyof FieldsType<FieldRule>;
            return {
                component: fieldComponents[field].component,
                getProps: fieldComponents[field].getProps,
                rule: fieldRule[field],
            };
        })
        .sort((a, b) => (a.rule.order ?? Number.MAX_SAFE_INTEGER) - (b.rule.order ?? Number.MAX_SAFE_INTEGER));
    
    return (
        <>
            {childInfoList.map((childInfo, childNum) => {
            
                return (
                    <React.Fragment　key={childNum}>
                        {
                            ( isMultipleChild && sortedFields.length > 0 ) && (
                                <>
                                    <GItem xs={12}>
                                        <TitleLabel>お子さま{childNum + 1}</TitleLabel>
                                    </GItem>
                                    <InfoCard>
                                        <GContainer>
                                            <ChildContent childInfo={childInfo} />
                                        </GContainer>
                                    </InfoCard>
                                </>
                            )
                        }
                        {
                            sortedFields.map(({component: Component, getProps, rule}, index) => (
                                <Component key={index} {...getProps(rule, childNum)} />
                            ))
                        }
                    </React.Fragment>
                
                )
            })}
            
        </>
    )
}



export default ByChildFormUnit;