import { GItem } from "../../../components/common/grids"
import { TextBox } from '../../../components/common/inputs/textBox'
import { TitleLabel } from "../../../components/common/titleLabel"
import { translate } from '../../../../i18n'

const NoteField = () => {
    return (
        <GItem xs={12} marginBottom={3}>
            <TitleLabel>
              {translate("facilityReservationForm.form.label.note")}
            </TitleLabel>
            <TextBox name="note" label="備考" maxLength={200} multiline rows={4} fullWidth />
        </GItem>
    )
}

export default NoteField;
