import React from 'react'
import {
  getDoNotWaitlistLabel,
  getYesNoLabel,
} from '../../../../containers/common/codeMaster'
import { yesNo } from '../../../../containers/common/constant/classification'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { translate } from '../../../../i18n'

type CommonDetailContentProps = {
  lunchAcceptFlag: string
  lunchFlag: string
  snackAcceptFlag: string
  snackFlag: string
  immediatelyReservationFlag: string
  postponeCancelWaitAcceptFlag: string
  postponeCancelWaitFlag: string
}

const CommonDetailContent:React.FC<CommonDetailContentProps> = (props: CommonDetailContentProps) => {
  return (
    <>
      {/* 給食 */}
      {props.lunchAcceptFlag === yesNo.yes && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.lunch')}</LabelGItem>
          <ValueGItem>{getYesNoLabel(props.lunchFlag)}</ValueGItem>
        </>
      )}
      {/* おやつ */}
      {props.snackAcceptFlag === yesNo.yes && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.snack')}</LabelGItem>
          <ValueGItem>{getYesNoLabel(props.snackFlag)}</ValueGItem>
        </>
      )}
      {/* キャンセル待ち可否 */}
      {props.immediatelyReservationFlag === yesNo.no &&
        props.postponeCancelWaitAcceptFlag === yesNo.yes && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.postponeCancelWait')}</LabelGItem>
            <ValueGItem>{getDoNotWaitlistLabel(props.postponeCancelWaitFlag)}</ValueGItem>
          </>
        )}
    </>
  )
}

export default CommonDetailContent