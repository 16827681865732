import React from 'react';
import { useState, useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import { reservationEatingMethod } from "../../../../containers/common/constant/classification"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto'
import SelectBoxField from "./common/selectBoxField"
import DecimalTypeField from "./common/decimalTypeField"
import IntegerTypeField from "./common/integerTypeField"

export interface EatingMethodFieldProps {
    childNum: number
    required: boolean
    methodOptions?: PostDecodeMstDto[]
    brestFeddingDetailOptions?: PostDecodeMstDto[]
    weaningDetailOptions?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const EatingMethodField: React.FC<EatingMethodFieldProps> = (props) => {
    const { 
        childNum, 
        required, 
        methodOptions, 
        brestFeddingDetailOptions,
        weaningDetailOptions,
        control
    } = props
    const [ detailOption, setDetailOption ] = useState<PostDecodeMstDto[]>()
    
    const name = `children.${childNum}.eatingMethod`
    const methodName = `${name}.method`
    const detailName = `${name}.detail`
    const milkAmountName = `${name}.milkAmount`
    const milkFrequencyName = `${name}.milkFrequency`
    
    const methodWatch = useWatch({
        control,
        name:  `${methodName}.select` as unknown as keyof Inputs
    })
    
    useEffect(() => {
        if ( !methodWatch ) return

        // 「授乳中」または「離乳中」であれば詳細項目表示
        if ( methodWatch === reservationEatingMethod.brestFeeding) {
            setDetailOption(brestFeddingDetailOptions)
            return
        }
        if ( methodWatch === reservationEatingMethod.weaning) {
            setDetailOption(weaningDetailOptions)
            return
        }
        setDetailOption(undefined)
    }, [ methodWatch ])
    
    return (
        <>
            {/** 栄養方法 */}
            <SelectBoxField 
                title={"facilityReservationForm.form.label.eatingMethod.method"}
                name={methodName}
                selectLabel={"facilityReservationForm.form.label.eatingMethod.method"}
                required={required}
                control={control}
                options={methodOptions}
                addUnselected
            >
                {
                    detailOption && (
                        <>
                            {/** 詳細 */}
                            <SelectBoxField 
                                title={"facilityReservationForm.form.label.eatingMethod.detail"}
                                name={detailName}
                                selectLabel={"facilityReservationForm.form.label.eatingMethod.detail"}
                                required={required}
                                control={control}
                                options={detailOption}
                                isSubField
                            />
                            
                            {
                                methodWatch === reservationEatingMethod.brestFeeding && (
                                    <>
                                        {/** ミルク量 */}
                                        <DecimalTypeField 
                                            name={milkAmountName}
                                            title={"facilityReservationForm.form.label.eatingMethod.milkAmount.field"}
                                            intLabel={"facilityReservationForm.form.label.eatingMethod.milkAmount.int"}
                                            fracLabel={"facilityReservationForm.form.label.eatingMethod.milkAmount.frac"}
                                            unit={"cc"}
                                            required={required}
                                            control={control}
                                            intMax={999}
                                            intMin={0}
                                            fracMac={99}
                                            fracMin={0}
                                            isSubField
                                        />
                                        {/** ミルク回数 */}
                                        <IntegerTypeField 
                                            name={milkFrequencyName}
                                            title={"facilityReservationForm.form.label.eatingMethod.milkFrequency"}
                                            label={"facilityReservationForm.form.label.eatingMethod.milkFrequency"}
                                            unit={"回"}
                                            required={required}
                                            control={control}
                                            max={99}
                                            min={0}
                                            isSubField
                                        />
                                    </>
                                )
                            }
                            
                        </>
                        
                    )
                }
                
            </SelectBoxField>
        </>
    )
    
}
export default EatingMethodField;
