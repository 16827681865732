import { DecimalType } from './typeUtil'

// Objectに対する操作を扱うユーティリティ関数
//

import {
  DatePropsToNumberType,
  DateToNumberType,
  KeysInclude,
  NullPropsToUndefinedType,
  NumberPropsToDateType,
  UndefinedPropsToNullType,
  UndefinedPropsToOptionalType,
} from './typeUtil'

/**
 * nullをundefinedに変換する
 *
 * @param value 値
 * @returns nullならundefined
 */
export const nullToUndefined = <T,>(value: T) => {
  return value ?? undefined
}

/**
 * 値の各プロパティに対し、nullをundefinedに変換する
 *
 * @param value 値
 * @returns 各プロパティのnullをundefinedに変換したもの
 */
export const nullPropsToUndefined = <T extends Object>(value: T): NullPropsToUndefinedType<T> => {
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [key, nullToUndefined(value)])
  ) as NullPropsToUndefinedType<T>
}

/**
 * undefinedをnullに変換する
 *
 * @param value 値
 * @returns undefinedならnull
 */
export const undefinedToNull = <T,>(value: T) => {
  return value ?? null
}

/**
 * 値の各プロパティに対し、undefinedをnullに変換する
 *
 * @param value 値
 * @returns 各プロパティのundefinedをnullに変換したもの
 */
export const undefinedPropsToNull = <T extends Object>(value: T): UndefinedPropsToNullType<T> => {
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [key, undefinedToNull(value)])
  ) as UndefinedPropsToNullType<T>
}

/**
 * Dateをnumber(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)に変換する
 *
 * @param value 日付
 * @returns number(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)
 */
export const dateToNumber = <T,>(value: T): DateToNumberType<T> => {
  return (value instanceof Date ? value.getTime() : value) as DateToNumberType<T>
}

/**
 * 値の日付型の各プロパティに対し、Dateをnumber(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)に変換する
 *
 * @param value 値
 * @returns 日付型の各プロパティに対し、Dateをnumber(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)に変換したもの
 */
export const datePropsToNumber = <T extends Object>(value: T): DatePropsToNumberType<T> => {
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [key, dateToNumber(value)])
  ) as DatePropsToNumberType<T>
}

/**
 * 指定した変換対象プロパティに対し、number(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)をDateに変換する
 *
 * @param value 値
 * @param keys 変換対象プロパティ名配列。
 * Conditional typesを用いて値のプロパティの内number型のみを指定するように型チェックしている
 *
 * @returns 指定した変換対象プロパティに対し、number(1970 年 1 月 1 日 00:00:00 UTCからの経過ミリ秒)をDateに変換したもの
 */
export const numberPropsToDate = <T extends Object, K extends KeysInclude<T, number>>(value: T, keys: K[]) => {
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [
      key,
      value && (keys as string[]).includes(key) ? new Date(value) : value,
    ])
  ) as NumberPropsToDateType<T, K>
}

/**
 * undefinedを含むプロパティをオプショナルに変換して返す。
 * @param value 変換対象のオブジェクト
 * @returns undefined,nullのプロパティを除去したオブジェクトを返す。
 * 戻り値の型は、undefinedを含むプロパティをオプショナル型に変換した型
 */
export const undefinedPropsToOptional = <T extends Object>(value: T) => {
  return Object.fromEntries(
    Object.entries(value).filter(([, value]) => value != null)
  ) as UndefinedPropsToOptionalType<T>
}

/**
 * targetからnameのオブジェクト名の値を返す
 * @param target 対象のオブジェクト
 * @param name 取得対象のオブジェクト名
 * @returns
 */
export const getValueByNameStr = (target: any, name: string) => {
  const parts = name.split('.')
  let obj = target
  for (const part of parts) {
    if (obj == null) {
      return obj
    }
    obj = obj[part]
  }
  return obj
}

export const getSizeOfObject = (object: any): number => {
  const objectString = JSON.stringify(object);
  // Encode the JSON string as a UTF-8 byte array
  return new TextEncoder().encode(objectString).length;
}

export const combineDecimalParts = (decimalObj: DecimalType | null): number | null => {
  if (!decimalObj || decimalObj.integer === null || decimalObj.integer === undefined) {
    return null;
  }

  const integerPart = decimalObj.integer;
  const fractionalPart = decimalObj.fractional ?? 0;

  // 数値に変換して返す
  return parseFloat(`${integerPart}.${fractionalPart}`);
}