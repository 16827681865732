import { ReactNode, useState, useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Inputs, facilityReservationInputKey } from '../../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../../dataAccess/webApi/dto/decodeMstDto';
import { CheckBoxSelections } from '../../../../components/common/inputs/checkBoxSelections'
import { GItem, LabelGItemBase } from "../../../../components/common/grids"
import { TextBox } from '../../../../components/common/inputs/textBox';
import { TitleLabel } from "../../../../components/common/titleLabel"
import { AttentionLabel } from "../../../../components/common/attentionLabel"
import { translate } from '../../../../../i18n'

interface CheckBoxFieldProps {
    name: string
    label: string
    title: string
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
    otherName?: string
    otherLabel?: string
    otherTitle?: string
    isOtherRequired?: (value: any) => boolean
    children?: ReactNode
}

const CheckBoxField = (props: CheckBoxFieldProps) => {
    const { 
        name, 
        label,
        title, 
        required, 
        options, 
        control,
        otherName,
        otherLabel,
        otherTitle,
        isOtherRequired,
        children
    } = props;
    const [ checkBoxRequired, setCheckBoxRequired ] = useState<boolean>(true);
    const [ otherRequired, setOtherRequired ] = useState<boolean>(false);
    
    const checkBoxWatch = useWatch({
        control,
        name: name as facilityReservationInputKey
    })
    const otherWatch = useWatch({
        control,
        name: otherName as facilityReservationInputKey
    })
    
    const valueLabels = options?.map(( option ) => (
        {
            value: option.code,
            label: option.name
        }
    ))
    
    useEffect(() => {
        if ( !isOtherRequired ) return;
        if ( isOtherRequired(otherWatch) ) {
            setOtherRequired(true)
        } else {
            setOtherRequired(false)
        }
    }, [ checkBoxWatch ])
    
    useEffect(() => {
        if ( otherWatch && typeof otherWatch === 'string' && otherWatch.length > 0 ) {
            setCheckBoxRequired(false);
        } else {
            setCheckBoxRequired(true);
        }
    }, [ otherWatch ])
    
    return (
        <GItem xs={12}>
            <div>
                <TitleLabel>
                    {
                        required && (
                            <AttentionLabel>*</AttentionLabel>
                        )
                    }
                    {translate(title)}
                </TitleLabel>
            </div>
            <CheckBoxSelections 
              name={name}
              label={translate(label)}
              valueLabels={valueLabels??[]}
              control={control}
              required={ required? checkBoxRequired: false }
            />
            {
                ( otherName && otherLabel ) && (
                    //  その他
                    <GItem xs={12} sx={{ mt: 1 }}>
                        <LabelGItemBase>{translate(otherTitle?? '')}</LabelGItemBase>
                        <TextBox 
                            name={otherName} 
                            label={translate(otherLabel)}
                            maxLength={100} 
                            rows={2}
                            multiline 
                            required={ isOtherRequired? otherRequired: !checkBoxRequired }
                        />
                    </GItem>
                )
            }
            {children && (
                <>{children}</>
            )}
        </GItem>
    )
}

export default CheckBoxField;
