import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { selectCognitoUser } from '../common/store/slices/cognitoUser'
import { setFormErrors } from '../../utils/reactUtil'
import { translate } from '../../i18n'
import { completeNewPassword } from '../../utils/authUtil'
import { getAuthenticatedUser } from '../../utils/authUtil'
import {
  setLogin,
  setIsOTPAuthenticated,
} from '../common/store/slices/authority'
import { loginUrl } from '../common/constant/appUrl'
import { clearCognitoUser } from '../common/store/slices/cognitoUser'
import { usePasswordValidationMessage } from '../../containers/common/passwordRule'

interface Inputs {
  password: string
  passwordConfirmation: string
}

type InputTypePropName = keyof Inputs

/**
 * 初期パスワード変更画面フォームアクション
 */
export const useAction = () => {
  const formMethods = useForm<Inputs>()
  const { ssoUseFlag, ssoUseName } = useSelector(selectSystemControl)
  const cognitoUser = useSelector(selectCognitoUser)
  const history = useHistory()
  const dispatch = useDispatch()
  const { validationMessage, passwordRule } = usePasswordValidationMessage()

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []
      
      if (data.password !== data.passwordConfirmation) {
        errors.push({
          name: 'passwordConfirmation',
          message: translate('system.error.notSamePassword'),
        })
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (validateItems(data)) {
        return
      }

      // 入力情報を取得
      const { password } = data
      
      try {
        if (cognitoUser) {
          // 初期パスワード変更
          await completeNewPassword(cognitoUser, password)
          
          // ログイン後のユーザー情報を取得
          const user = await getAuthenticatedUser(true)

          // メールアドレス・電話番号の検証可否を取得
          const isVerifiedEmail = !!user.attributes.email_verified
          const isVerifiedTel = !!user.attributes.phone_number_verified

          // Reduxストアにログインエントリを送信
          dispatch(
            setLogin({
              loginId: user.attributes.email,
              verifications: { tel: isVerifiedTel, email: isVerifiedEmail },
            })
          )

          dispatch(setIsOTPAuthenticated(true))
          
          dispatch(clearCognitoUser());

          history.push(loginUrl.url())
        }
      } catch (error) {
        console.log(error)
      }
    },
    []
  )
  
  return {
    formMethods,
    onSubmit,
    ssoUseFlag,
    ssoUseName,
    validationMessage,
    passwordRule,
  }
}
