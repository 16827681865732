import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import CheckBoxField from "./common/checkBoxField"

export interface SymptomFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const SymptomField = (props: SymptomFieldProps) => {
    const { childNum, required, options, control } = props;
    
    const selectName = `children.${childNum}.symptom.symptoms`
    const otherName = `children.${childNum}.symptom.other`
    
    return (
        <CheckBoxField 
            name={selectName}
            otherName={otherName}
            label={"facilityReservationForm.form.label.symptom.field"}
            otherLabel={"facilityReservationForm.form.label.symptom.other"}
            title={"facilityReservationForm.form.label.symptom.field"}
            otherTitle={"facilityReservationForm.form.label.symptom.other"}
            required={required}
            options={options}
            control={control}
        />
    )
}

export default SymptomField;
