/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "9872177abcb9440f966dbe70888ecf6a",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9872177abcb9440f966dbe70888ecf6a",
            "region": "ap-northeast-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "citizen",
            "endpoint": "https://51ppo2v6nh.execute-api.ap-northeast-1.amazonaws.com/demo",
            "region": "ap-northeast-1"
        },
        {
            "name": "public",
            "endpoint": "https://avqtcp6bhj.execute-api.ap-northeast-1.amazonaws.com/demo",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:5915f14d-f75c-439d-be1f-82a640d6444f",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_etBf6OZSZ",
    "aws_user_pools_web_client_id": "1k2n0b025em97ngbshfruorhgh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
