import { medicalDocUsage, reservationFieldUsage } from "./constant/classification"
import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil';
import { executeGetProjectDetail } from '../../dataAccess/webApi/dao/projectsDao';
import { GetProjectDetailDto } from '../../dataAccess/webApi/dto/projectsDto';
import { getFacility } from './facility';

/**
 * 複数日程に対して参照する事業マスタ情報を取得する
 * 24/11 HOIKU-99にて予約希望日ないで最も古い日付における事業マスタを参照することに決定
 */
export const getProjectMstDataToRefer = async ( facilityId: string, usageDates: Date[] | ElapsedMillisecond[], projectId?: string ): Promise<GetProjectDetailDto> => {
    // 日付を古い順にソート
    usageDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    
    if ( !projectId ) {
        const facilityData = await getFacility(facilityId, usageDates[0]);
        projectId = facilityData.projectId;
    }
    
    
    const getProjectDetailReturn = await executeGetProjectDetail(projectId, toApiYmd(usageDates[0]));
    
    return getProjectDetailReturn.result;
}

// 予約時利用項目
export type FieldsType<T> = {
    diagonosis: T,
    symptom: T,
    epidemic: T,
    medicine: T,
    bodyTemp: T,
    foodAllergy: T,
    febrileSeizures: T,
    medicalDoc: T,
    emergency: T,
    height: T,
    weight: T,
    eatingMethod: T,
    eatingAbility: T,
    action: T,
    toiletAbility: T,
    reduction: T
}

export type FieldRule = {
    show: boolean
    required: boolean
    order: number | null
}

// 予約時利用項目表示有無情報カラム
const fieldShowRuleMap: FieldsType<string> = {
    diagonosis: "diagonosisInput",
    symptom: "symptomsInput",
    epidemic: "epidemicInput",
    medicine: "medicineInput",
    bodyTemp: "bodyTemperatureInput",
    febrileSeizures: "febrileSeizuresInput",
    medicalDoc: "medicalDocUsage",
    emergency: "emergencyActionInput",
    height: "heightInput",
    weight: "weightInput",
    eatingMethod: "eatingMethodInput",
    foodAllergy: "allergyInput",
    eatingAbility: "eatingAbilityInput",
    action: "actionInput",
    toiletAbility: "toiletAbilityInput",
    reduction: "reductionInput"
}

// 予約時利用項目並び順情報カラム
const fieldOrderRuleMap: FieldsType<string> = {
    diagonosis: "diagonosisOrder",
    symptom: "symptomsOrder",
    epidemic: "epidemicOrder",
    medicine: "medicineOrder",
    bodyTemp: "bodyTemperatureOrder",
    febrileSeizures: "febrileSeizuresOrder",
    medicalDoc: "medicalDocOrder",
    emergency: "emergencyActionOrder",
    height: "heightOrder",
    weight: "weightOrder",
    eatingMethod: "eatingMethodOrder",
    foodAllergy: "allergyOrder",
    eatingAbility: "eatingAbilityOrder",
    action: "actionOrder",
    toiletAbility: "toiletAbilityOrder",
    reduction: "reductionOrder"
}

export const getByChildFormFieldRule = (projectMst: GetProjectDetailDto): FieldsType<FieldRule> => {
    // 並び順未設定の場合はこの順番で表示される
    const rule: FieldsType<FieldRule> = {
        diagonosis: { show: false, required: false, order: null },
        symptom: { show: false, required: false, order: null },
        epidemic: { show: false, required: false, order: null },
        medicine: { show: false, required: false, order: null },
        bodyTemp: { show: false, required: false, order: null },
        foodAllergy: { show: false, required: false, order: null },
        febrileSeizures: { show: false, required: false, order: null },
        medicalDoc: { show: false, required: false, order: null },
        emergency: { show: false, required: false, order: null },
        height: { show: false, required: false, order: null },
        weight: { show: false, required: false, order: null },
        eatingMethod: { show: false, required: false, order: null },
        eatingAbility: { show: false, required: false, order: null },
        action: { show: false, required: false, order: null },
        toiletAbility: { show: false, required: false, order: null },
        reduction: { show: false, required: false, order: null }
    };
    
    Object.keys(fieldShowRuleMap).forEach((key) => {
        const fieldKey = key as keyof FieldsType<string>;
        const ruleKeyName = fieldShowRuleMap[fieldKey] as keyof GetProjectDetailDto;
        
        if ( ruleKeyName === fieldShowRuleMap.medicalDoc ) {
            if ( projectMst[ruleKeyName] !== medicalDocUsage.noUse) {
                rule.medicalDoc.show = true
            }
            return
        }
        if ( projectMst[ruleKeyName] !== reservationFieldUsage.noUse) {
            rule[fieldKey].show = true
            if ( projectMst[ruleKeyName] === reservationFieldUsage.necessary ) {
                rule[fieldKey].required = true
            }
        }
    });
    
    Object.keys(fieldOrderRuleMap).forEach((key) => {
        const fieldKey = key as keyof FieldsType<string>;
        const ruleKeyName = fieldOrderRuleMap[fieldKey] as keyof GetProjectDetailDto;
        
        rule[fieldKey].order = projectMst[ruleKeyName] as (number | null)
    });
    
    return rule;
}