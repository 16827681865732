import React from "react"
import { getYesNoLabel } from '../../../../containers/common/codeMaster'
import { ChildReservationInfoType } from '../../../../containers/common/reservation'
import { FieldsType, FieldRule, getByChildFormFieldRule } from '../../../../containers/common/projectMst'
import { GetProjectDetailDto } from '../../../../dataAccess/webApi/dto/projectsDto'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { translate } from '../../../../i18n'

type ReservationDetailFieldType = {
    component: React.FC<{ childInfo: ChildReservationInfoType }>
}
type SortedReservationDetailFieldType = ReservationDetailFieldType & {
    rule: FieldRule
}

export type ByChildDetailContentProps = {
  childInfo:  ChildReservationInfoType
  projectMst: GetProjectDetailDto
}

const ByChildDetailContent: React.FC<ByChildDetailContentProps> = (props: ByChildDetailContentProps) => {
    // eslint-disable-next-line react/prop-types
    const { childInfo, projectMst } = props;
    const unfilled = translate('system.reservationDetailGItems.value.unfilled')
    const getValueToShow = (value?: string | null, appendix?: string) => {
        return value? value + (appendix?? ''): unfilled
    }

    const reservationDetail: FieldsType<ReservationDetailFieldType> = {
        diagonosis: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 病名１ */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.diagonosis')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo.diagonosis?.first.stringify)}</ValueGItem>
                  {/* 病名２ */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.diagonosis2')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo.diagonosis?.second.stringify)}</ValueGItem>
                </>
            )
        },
        symptom: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 症状 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.symptoms')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo.symptom?.stringify)}</ValueGItem>
                </>
            )
        },
        epidemic:{
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 流行り病 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.epidemic')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.epidemic?.stringify)}</ValueGItem>
                </>
            )
        },
        medicine: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 処方薬 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.medicine.rx')}</LabelGItem>
                  <ValueGItem>{getValueToShow(getYesNoLabel(childInfo.medicine?.rx?? undefined))}</ValueGItem>
                  {/* 市販薬 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.medicine.otc')}</LabelGItem>
                  <ValueGItem>{getValueToShow(getYesNoLabel(childInfo.medicine?.otc?? undefined))}</ValueGItem>
                </>
            )
        },
        bodyTemp: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 体温 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.bodyTemperature')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo.temperature?.stringify, '℃')}</ValueGItem>
                </>
            )
        },
        febrileSeizures: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 熱性けいれん */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.febrileSeizures')}</LabelGItem>
                  <ValueGItem>{getValueToShow(getYesNoLabel(childInfo?.febrileSeizures?? undefined))}</ValueGItem>
                </>
            )
        },
        medicalDoc: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 医師連絡票提出状況 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.medicalDoc')}</LabelGItem>
                  <ValueGItem>{getValueToShow(getYesNoLabel(childInfo?.medicalDoc?.hasSubmitted?? undefined))}</ValueGItem>
                </>
            )
        },
        emergency: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 容態変化時の対応 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.emergency')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.emergencyAction?.action.stringify)}</ValueGItem>
                </>
            )
        },
        height: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 身長 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.height')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.height?.stringify, 'cm')}</ValueGItem>
                </>
            ) 
        },
        weight: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 体重 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.weight')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.weight?.stringify, 'kg')}</ValueGItem>
                </>
            )
        },
        eatingMethod: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 栄養方法 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.eatingMethod')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.eatingMethod?.method.stringify)}</ValueGItem>
                </>
            )
        },
        foodAllergy: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 食物アレルギー */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.foodAllergy')}</LabelGItem>
                  <ValueGItem>{getValueToShow(getYesNoLabel(childInfo?.allergy?.foodFlag?? undefined))}</ValueGItem>  
                </>
            )
        },
        eatingAbility: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 食事状況 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.eatingAbility')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.eatingAbility?.stringify)}</ValueGItem>
                </>
            )
        },
        action: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 行動 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.action')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.actions?.action.stringify)}</ValueGItem>
                </>
            )
        },
        toiletAbility: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 排便 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.toiletAbility')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.toiletAbility?.stringify)}</ValueGItem>
                </>
            )
        },
        reduction: {
            component: ({ childInfo }: { childInfo: ChildReservationInfoType }) => (
                <>
                  {/* 減免の種類 */}
                  <LabelGItem>{translate('system.reservationDetailGItems.label.reduction')}</LabelGItem>
                  <ValueGItem>{getValueToShow(childInfo?.reductionType?.stringify)}</ValueGItem>
                </>
            )
        }
    }
    const fieldRule = getByChildFormFieldRule(projectMst)
    const sortedDetail: SortedReservationDetailFieldType[] = Object.keys(fieldRule)
        .filter((fieldKey) => fieldRule[fieldKey as keyof FieldsType<FieldRule>].show) 
        .map((fieldKey) => {
            const field = fieldKey as keyof FieldsType<FieldRule>;
            return {
                component: reservationDetail[field].component,
                rule: fieldRule[field]
            }
        })
        .sort((a, b) => (a.rule.order ?? Number.MAX_SAFE_INTEGER) - (b.rule.order ?? Number.MAX_SAFE_INTEGER));
        
    return (
        <>
            {sortedDetail.map(({ component: Component }, index) => (
                <Component key={index} childInfo={childInfo} />
            ))}
        </>
    );
}
export default ByChildDetailContent;