import React from 'react'
import { ParentInfoType } from '../../../../containers/common/reservation'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { translate } from '../../../../i18n'

const ParentContent: React.FC<ParentInfoType> = (props: ParentInfoType) => {
  return (
    <>
      {/* 保護者 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.userName')}</LabelGItem>
      <ValueGItem>{props.name}</ValueGItem>
      {/* フリガナ */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.userNameKana')}</LabelGItem>
      <ValueGItem>{props.kana}</ValueGItem>
      {/* 携帯電話番号 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.tel')}</LabelGItem>
      <ValueGItem>{props.tel}</ValueGItem>
      {/* メールアドレス */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.email')}</LabelGItem>
      <ValueGItem>{props.email}</ValueGItem>
    </>
  )
}

export default ParentContent