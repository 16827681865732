import { SelectChangeEvent } from '@mui/material'
import { addMonths, isAfter, isSameMonth, parse, startOfMonth, subMonths } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetUsageRecords } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { groupBy } from '../../utils/arrayUtil'
import { formatHyphenYmd, fromApiYmd, fromApiYmdHms, getNow, toApiYm } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToNull } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { usageHistoryStatusSearchCondition, yesNo } from '../common/constant/classification'
import { PROVISION_CERTIFICATE_USER_REISSUE_STATUS } from '../common/constant/commonConstant'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { useProvisionCertificateIssuance } from '../provisionCertificateIssuance/useProvisionCertificateIssuance'
import { executePostIssuableFacilityIds } from '../../dataAccess/webApi/dao/certificateDao'
import { executePostFacilityIdsHaveUnsettledAndConfirmReservations } from '../../dataAccess/webApi/dao/reservationsDao'
import { useControl } from '../common/useControl'

type GetUsageHistoriesReturnType = Awaited<ReturnType<typeof getUsageHistories>>

interface LocationState {
  /** アクティブな年月の値 */
  yyyymm?: Date
  /** アクティブなお子さまID */
  childId?: string
  /** アクティブな利用予約の検索条件 */
  statusSearchCondition?: string
}

interface UsageHistory {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityId: string
  facilityName: string
  certificateFlag: string
  issueCertificateFlag: string
  childName: string
  status: string
  usageMinute?: string
  paymentStatus: string
}

export interface PageState {
  activeYyyymm: Date
  activeChildId?: string
  activeStatusSearchCondition: string
  usageHistories?: UsageHistory[]
  childs: ChildrenChoice[]
  shouldShowDownloadCertificate: boolean
  facilityIds: string[]
}

export type Load = (
  isInitialize: boolean,
  activeYyyymm: Date,
  loadedChilds: { value: string; label: string }[],
  activeStatusSearchCondition: string,
  activeChildId?: string
) => void

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { addOperationLog } = useOperationLog()
  const history = useHistory<LocationState | undefined>()
  const sysCtrl = useSelector(selectSystemControl)

  const locationState = history.location.state

  const [state, setState] = useState<PageState>({
    activeYyyymm: locationState?.yyyymm ?? startOfMonth(getNow()),
    activeChildId: locationState?.childId,
    activeStatusSearchCondition: locationState?.statusSearchCondition ?? usageHistoryStatusSearchCondition.usedOnly,
    childs: [],
    shouldShowDownloadCertificate: false,
    facilityIds: [],
  })

  const { getControl } = useControl({})

  const load = useCallback<Load>(
    (isInitialize, activeYyyymm, loadedChilds, activeStatusSearchCondition, activeChildId) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            await getControl()

            const provisionCertificateUserReissueStatus = sysCtrl.provisionCertificateUserReissueStatus
            const [childs, usageHistories] = await Promise.all([
              isInitialize ? getChildrenChoices() : loadedChilds,
              getUsageHistories(activeYyyymm, activeStatusSearchCondition, activeChildId),
            ])

            const filteredUsageHistories = usageHistories.filter(
              (usageHistory) => usageHistory.certificateFlag === yesNo.yes
            )

            // 発行可能施設
            const facilityIds = await getIssuableFacilityId(
              filteredUsageHistories,
              activeChildId,
              activeYyyymm,
              provisionCertificateUserReissueStatus
            )

            // 発行ボタン表示フラグ
            const shouldShowDownloadCertificate = getShouldShowDownloadCertificate(
              filteredUsageHistories,
              provisionCertificateUserReissueStatus,
              facilityIds
            )

            setState({
              activeChildId,
              activeYyyymm,
              usageHistories,
              childs,
              activeStatusSearchCondition,
              shouldShowDownloadCertificate,
              facilityIds,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    [dispatch, errorHandle, getControl, sysCtrl.provisionCertificateUserReissueStatus]
  )

  const {
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    resetCertificateResultResponse,
  } = useProvisionCertificateIssuance({
    childId: state.activeChildId ?? '',
    usageFromDate: formatHyphenYmd(state.activeYyyymm),
    facilityIds: state.facilityIds,
    state,
    load,
  })

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブな選択を復元する為に履歴に保管
    history.replace({
      ...history.location,
      state: {
        yyyymm: state.activeYyyymm,
        childId: state.activeChildId,
        statusSearchCondition: state.activeStatusSearchCondition,
      },
    })
  }, [state.activeChildId, state.activeYyyymm, state.activeStatusSearchCondition])

  useEffect(() => {
    resetCertificateResultResponse()
  }, [state, resetCertificateResultResponse])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, event.target.value)
    },
    [state.activeYyyymm, state.childs, state.activeStatusSearchCondition, addOperationLog]
  )

  const changeMonth = useCallback(
    (value: Date) => {
      load(false, value, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    },
    [state]
  )

  const previousMonth = useCallback(() => {
    changeMonth(subMonths(state.activeYyyymm, 1))
  }, [state])

  const nextMonth = useCallback(() => {
    changeMonth(addMonths(state.activeYyyymm, 1))
  }, [state])

  const statusSearchConditionChangeHandle = useCallback(
    (event: SelectChangeEvent<string>) => {
      load(false, state.activeYyyymm, state.childs, event.target.value, state.activeChildId)
    },
    [state]
  )

  const dateChecker = useCallback(() => {
    const results: boolean[] = []

    // NOTE: 選択している月が過去月か
    const now = getNow()
    const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth())
    results.push(state.activeYyyymm < startOfCurrentMonth)

    /**
     * NOTE: 提供証明書出力可能年月が設定されているか
     *       設定されている場合は表示年月と比較をする
     *
     * @example 提供証明書出力可能年月 > 表示年月 = false
     *          提供証明書出力可能年月 =< 表示年月 = true
     */
    if (sysCtrl.issuableDate) {
      const issuableDate = parse(sysCtrl.issuableDate, 'yyyy/MM', new Date())
      results.push(isAfter(state.activeYyyymm, issuableDate) || isSameMonth(issuableDate, state.activeYyyymm))
    }

    return results.every((result) => result)
  }, [state.activeYyyymm, sysCtrl])

  return {
    ...state,
    changeChild,
    previousMonth,
    nextMonth,
    statusSearchConditionChangeHandle,
    provisionCertificateIssuanceShowFlag,
    onClickIssueProvisionCertificateIssuance,
    provisionCertificateIssuanceHandler,
    cancelHandler,
    certificateResultCode,
    certificateResultResponseData,
    provisionCertificateFeatureStatus: sysCtrl.provisionCertificateFeatureStatus,
    paymentStatusFlag: sysCtrl.paymentStatusFlag,
    provisionCertificatePaymentStatusFlag: sysCtrl.provisionCertificatePaymentStatusFlag,
    dateChecker,
  }
}

const getUsageHistories = async (yyyymm: Date, activeStatusSearchCondition: string, childId?: string) => {
  const response = await executeGetUsageRecords({
    ...undefinedPropsToNull({ yyyymm: toApiYm(yyyymm), childId }),
    ...(activeStatusSearchCondition === usageHistoryStatusSearchCondition.usedOnly && { usedOnlyGetFlag: yesNo.yes }),
  })

  return response.result.map((usage) => {
    const { usageDate, useFromDatetime, useToDatetime, usageMinute, ...other } = usage
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
      usageMinute: formatLocaleTimeAmountHm(usageMinute),
    })
  })
}

/**
 * 発行可能な施設IDを返す
 *
 * @param usageHistories GetUsageHistoriesReturnType
 * @param activeChildId string | null
 * @param activeYyyymm Date
 * @param provisionCertificateUserReissueStatus PROVISION_CERTIFICATE_USER_REISSUE_STATUS
 * @returns string[]
 */
const getIssuableFacilityId = async (
  usageHistories: GetUsageHistoriesReturnType,
  activeChildId: string | undefined,
  activeYyyymm: Date,
  provisionCertificateUserReissueStatus: PROVISION_CERTIFICATE_USER_REISSUE_STATUS
) => {
  const facilityIds =
    Array.from(
      new Set(
        usageHistories
          ?.filter(
            (usageHistory) =>
              usageHistory.certificateFlag === yesNo.yes && usageHistory.issueCertificateFlag === yesNo.yes
          )
          .map((usageHistory) => usageHistory.facilityId)
      )
    ) ?? []
  const filteredFacilityIds: string[] = []
  const facilityIdsHaveUnsettledAndConfirmReservations: string[] = []

  if (activeChildId) {
    const targetDate = formatHyphenYmd(activeYyyymm)

    if (
      facilityIds.length > 0 &&
      (provisionCertificateUserReissueStatus === PROVISION_CERTIFICATE_USER_REISSUE_STATUS.REISSUE_ALLOWED ||
        provisionCertificateUserReissueStatus === PROVISION_CERTIFICATE_USER_REISSUE_STATUS.REISSUE_DISABLED)
    ) {
      const issuableFacilityIdsRes = await executePostIssuableFacilityIds({
        usageFromDate: targetDate,
        childId: activeChildId,
        facilityIds,
      })

      filteredFacilityIds.push(
        ...facilityIds.filter((facilityId) => {
          const length = issuableFacilityIdsRes.result.facilityIds.filter((item) => item === facilityId).length

          return provisionCertificateUserReissueStatus === PROVISION_CERTIFICATE_USER_REISSUE_STATUS.REISSUE_ALLOWED
            ? length >= 0 && length <= 1
            : length === 0
        })
      )
    } else filteredFacilityIds.push(...facilityIds)

    // NOTE: 未確定・確定済みの予約が存在する施設IDを取得
    const facilityIdsHaveUnsettledAndConfirmReservationsRes =
      await executePostFacilityIdsHaveUnsettledAndConfirmReservations({
        targetDate,
        childId: activeChildId,
      })
    facilityIdsHaveUnsettledAndConfirmReservations.push(
      ...facilityIdsHaveUnsettledAndConfirmReservationsRes.result.facilityIds
    )
  }

  // NOTE: 未確定・確定済みの予約が存在しない施設IDのみ返す
  return filteredFacilityIds.filter(
    (facilityId) => !facilityIdsHaveUnsettledAndConfirmReservations.includes(facilityId)
  )
}

/**
 * 提供証明書発行ボタン表示フラグを返す
 *
 * @param usageHistories GetUsageHistoriesReturnType
 * @param provisionCertificateUserReissueStatus PROVISION_CERTIFICATE_USER_REISSUE_STATUS
 * @param facilityIds string[]
 * @returns boolean
 */
const getShouldShowDownloadCertificate = (
  usageHistories: GetUsageHistoriesReturnType,
  provisionCertificateUserReissueStatus: PROVISION_CERTIFICATE_USER_REISSUE_STATUS,
  facilityIds: string[]
) => {
  const usageHistoriesGroupByFacilityId = groupBy(usageHistories, 'facilityId')
  const shouldShowDownloadCertificateResult: boolean[] = []
  Object.keys(usageHistoriesGroupByFacilityId).forEach((facilityId) => {
    const targetUsageHistories = usageHistoriesGroupByFacilityId[facilityId]

    shouldShowDownloadCertificateResult.push(
      provisionCertificateUserReissueStatus === PROVISION_CERTIFICATE_USER_REISSUE_STATUS.DIFFERENCE_ALLOWED
        ? targetUsageHistories.some((usageHistory) => usageHistory.issueCertificateFlag === yesNo.yes)
        : targetUsageHistories.every((usageHistory) => usageHistory.issueCertificateFlag === yesNo.yes)
    )
  })

  return (
    usageHistories
      .filter(
        (usageHistory) => usageHistory.certificateFlag === yesNo.yes && usageHistory.issueCertificateFlag === yesNo.yes
      )
      .map((usageHistory) => usageHistory.facilityId).length > 0 &&
    shouldShowDownloadCertificateResult.some((result) => result) &&
    facilityIds.length > 0
  )
}
