import React from 'react';
import { Control } from 'react-hook-form';
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto'
import SelectBoxField from "./common/selectBoxField"

export interface EpidemicFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const EpidemicField: React.FC<EpidemicFieldProps> = ({ childNum, required, options, control }) => {
    const name = `children.${childNum}.epidemic`
    const showOtherSelection = ['その他'];
    return (
        <SelectBoxField 
            title={"facilityReservationForm.form.label.epidemic.field"}
            name={name}
            selectLabel={"facilityReservationForm.form.label.epidemic.field"}
            otherLabel={"facilityReservationForm.form.label.epidemic.other"}
            required={required}
            control={control}
            options={options}
            showOtherSelection={showOtherSelection}
            addUnselected
        />
    );
};

export default EpidemicField;