import styled from '@emotion/styled'
import { FC } from 'react'
import { GetDecodeMstByCategoriesReturn } from '../../../containers/common/decodeMst'
import { GetLocationStatusReturn } from '../../../containers/common/locationStatus'
import { useUsageFeeSetting } from '../../../containers/facilityDetail/useUsageFeeSetting'
import { GetFacilityDto } from '../../../dataAccess/webApi/dto/facilitiesDto'
import { PostUsageFeeSettingDto } from '../../../dataAccess/webApi/dto/usageFeeSettingDto'
import { NullPropsToUndefinedType } from '../../../utils/typeUtil'
import { InlineFlexbox } from '../common/flexboxes'
import { GContainer, GItem, TopGItem } from '../common/grids'
import { MoneyIcon } from '../common/icons/moneyIcon'
import { UsageFeeSetting } from './usageFeeSetting'

const ItemIcon = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))

const FeeText = styled('p')({
  whiteSpace: 'pre-wrap',
  margin: 0,
})

type FeeNotionProps = {
  facility: NullPropsToUndefinedType<GetFacilityDto>
  decodeMst: GetDecodeMstByCategoriesReturn
  usageFeeSetting: PostUsageFeeSettingDto | null
  locationStatus: GetLocationStatusReturn
}

export const FeeNotion: FC<FeeNotionProps> = ({ facility, decodeMst, usageFeeSetting, locationStatus }) => {
  const { shouldShowNote, shouldShowSetting, usageFees } = useUsageFeeSetting({
    decodeMst,
    usageFeeSetting,
    locationStatus,
    feeNote: facility.feeNote,
  })

  if (!shouldShowSetting && !shouldShowNote) return <></>

  return (
    <>
      <TopGItem xs={12}>
        <ItemIcon>
          <MoneyIcon fontSize="inherit" />
        </ItemIcon>
        <GContainer direction="column" rowGap={2}>
          {shouldShowSetting && (
            <GItem>
              <UsageFeeSetting usageFees={usageFees} />
            </GItem>
          )}
          {shouldShowNote && (
            <GItem>
              <FeeText>{facility.feeNote}</FeeText>
            </GItem>
          )}
        </GContainer>
      </TopGItem>
    </>
  )
}
