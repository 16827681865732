import { Box, Stack } from '@mui/material'
import { getCancelReasonLabel, getFacilityReservationDeleteSteps } from '../../containers/common/codeMaster'
import { facilityReservationDeleteStep, reservationStatus } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityReservationDeleteConfirmation/facilityReservationDeleteConfirmationService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import ReservationDetailGItems from '../components/common/reservationDetail/reservationDetailGItems'
import { LabelGItem, ValueGItem } from '../components/common/reservationDetail/reservationDetailParts'
import { StepBar } from '../components/common/stepBar'
import { TransitionCheck } from '../components/common/transitionCheck'

export const FacilityReservationDeleteConfirmation = () => {
  const { deleteEntry, reservationDetail, deleteResultCode, decide } = useAction()
  
  if (!deleteEntry ) {
    return <TransitionCheck check={false} />
  }
  if ( !reservationDetail ) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Box>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Stack spacing={2}>
              {deleteEntry.status !== reservationStatus.wait && (
                <StepBar
                  activeStepValue={facilityReservationDeleteStep.step2}
                  stepValueLabels={getFacilityReservationDeleteSteps()}
                />
              )}

              <InfoCard>
                <GContainer>
                  {deleteEntry.status !== reservationStatus.wait && (
                    <>
                      <LabelGItem>{translate('facilityReservationDeleteConfirmation.label.cancelReason')}</LabelGItem>
                      <ValueGItem>{getCancelReasonLabel(deleteEntry.cancelReason)}</ValueGItem>
                      <RowSpacerGItem />
                    </>
                  )}

                  <ReservationDetailGItems reservation={reservationDetail} useReason={deleteEntry.useReason} displayType="delete" />
                </GContainer>
              </InfoCard>

              {!!deleteResultCode && (
                <ErrorMessageCard
                  messages={[
                    translate(`facilityReservationDeleteConfirmation.error.deleteResultCode${deleteResultCode}`),
                  ]}
                />
              )}
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
      </Box>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('facilityReservationDeleteConfirmation.button.decision')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
