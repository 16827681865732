import { useCallback } from 'react'
import { AgeRegionBasedFee } from '../../dataAccess/webApi/dto/usageFeeSettingDto'
import { formatNumberYenString } from '../../utils/stringUtil'
import { AGE_REGION_BASED_FEE_DAY_TYPE, LOCATION_STATUS } from '../common/constant/commonConstant'
import { DecodeMstCategory } from '../common/constant/decodeMst'
import { GetDecodeMstByCategoriesReturn, getDecodeMstName } from '../common/decodeMst'
import { GRID_SIZE } from './enum'
import { Fee, GenerateUsageFeesByAgeRegionBasedFees, UsageFee } from './type'
import { useGenerateUsageFeesCommon } from './useGenerateUsageFeesCommon'

type UseGenerateUsageFeesByAgeRegionBasedFeesReturn = {
  generateUsageFeesByAgeRegionBasedFees: GenerateUsageFeesByAgeRegionBasedFees
}
type UseGenerateUsageFeesByAgeRegionBasedFeesArg = {}
type UseGenerateUsageFeesByAgeRegionBasedFees = (
  arg: UseGenerateUsageFeesByAgeRegionBasedFeesArg
) => UseGenerateUsageFeesByAgeRegionBasedFeesReturn

export const useGenerateUsageFeesByAgeRegionBasedFees: UseGenerateUsageFeesByAgeRegionBasedFees = ({}) => {
  const { getCommonLunchSnackFee, getOtherFeeSettingsForCommonLocation, getFeeTimeSettings, getOtherFeeSettings } =
    useGenerateUsageFeesCommon({})

  /**
   * NOTE: 利用料金設定のタイトルを返す
   */
  const getFeeTitle = useCallback((decodeMst: GetDecodeMstByCategoriesReturn, ageRegionBasedFee: AgeRegionBasedFee) => {
    const ageRegionBasedFeeType = getDecodeMstName(
      decodeMst,
      DecodeMstCategory.AGE_REGION_BASED_FEE_TYPE,
      ageRegionBasedFee.ageRegionBasedFeeType
    )

    const ageRegionBasedFeeDayType =
      ageRegionBasedFee.ageRegionBasedFeeDayType === AGE_REGION_BASED_FEE_DAY_TYPE.DAY
        ? ''
        : `（${getDecodeMstName(
            decodeMst,
            DecodeMstCategory.AGE_REGION_BASED_FEE_DAY_TYPE,
            ageRegionBasedFee.ageRegionBasedFeeDayType
          )}）`

    return ageRegionBasedFeeType + ageRegionBasedFeeDayType
  }, [])

  /**
   * NOTE: 年齢別料金を元に利用料金設定を生成
   */
  const generateUsageFeesByAgeRegionBasedFees = useCallback<GenerateUsageFeesByAgeRegionBasedFees>(
    ({ ageCategories, ageRegionBasedFees, feeTimeSettings, otherFeeSettings, locationStatus, decodeMst }) => {
      const usageFees: UsageFee[] = []
      const commonFees: Fee[] = []
      const hasAgeRegionBasedFees = ageRegionBasedFees.length > 0
      const hasFeeTimeSettings = feeTimeSettings.length > 0
      const hasOtherFeeSettings = otherFeeSettings.length > 0

      /**
       * NOTE: 一時的にステップ3~5の各項目が「表示しない」となっている場合（利用料金案内が先頭にくる場合）のみを前提で実装
       * @link [HOIKU-2077](https://bluedsk.atlassian.net/browse/HOIKU-2077)
       */
      if (!hasAgeRegionBasedFees && !hasFeeTimeSettings && !hasOtherFeeSettings) return usageFees

      // NOTE: 利用料金設定にて平日のみの設定されているか
      const hasOnlyDay = ageRegionBasedFees.every(
        (ageRegionBasedFee) => ageRegionBasedFee.ageRegionBasedFeeDayType === AGE_REGION_BASED_FEE_DAY_TYPE.DAY
      )

      // NOTE: 適用地域が「共通」のみの利用料金設定
      const { existsFeesFilteredCommonLocation, lunchFee, snackFee, isSameLunchFeeAll, isSameSnackFeeAll } =
        getCommonLunchSnackFee(
          ageRegionBasedFees.map((ageRegionBasedFee) => ({
            locationStatus: ageRegionBasedFee.locationStatus,
            lunchFee: ageRegionBasedFee.lunchFee,
            lunchFeeDisplayFlag: ageRegionBasedFee.lunchFeeDisplayFlag,
            snackFee: ageRegionBasedFee.snackFee,
            snackFeeDisplayFlag: ageRegionBasedFee.snackFeeDisplayFlag,
          }))
        )

      // NOTE: 適用地域に「共通」が設定されている利用料金設定ある場合
      if (existsFeesFilteredCommonLocation) {
        // NOTE: 共通の給食代
        if (lunchFee >= 0 && isSameLunchFeeAll)
          commonFees.push({
            text: ['給食代', formatNumberYenString(lunchFee)],
            gridSize: hasOnlyDay ? GRID_SIZE.COMMON_ONLY_DAY : GRID_SIZE.COMMON,
          })

        // NOTE: 共通のおやつ代
        if (snackFee >= 0 && isSameSnackFeeAll)
          commonFees.push({
            text: ['おやつ代', formatNumberYenString(snackFee)],
            gridSize: hasOnlyDay ? GRID_SIZE.COMMON_ONLY_DAY : GRID_SIZE.COMMON,
          })
      }

      // NOTE: 適用地域が「共通」のその他料金
      commonFees.push(...getOtherFeeSettingsForCommonLocation({ otherFeeSettings, hasOnlyDay }))

      // NOTE: 各年齢の料金表示
      ageCategories.forEach((ageCategory) => {
        const ageCategoryId = ageCategory.id

        for (const location of locationStatus) {
          const fees: Fee[] = []
          const title = ageCategory.name + (location.id !== LOCATION_STATUS.COMMON ? `（${location.name}）` : '')

          const filteredAgeRegionBasedFees = ageRegionBasedFees.filter(
            (ageRegionBasedFee) =>
              ageRegionBasedFee.locationStatus === location.id && ageRegionBasedFee.ageCategoryId === ageCategoryId
          )
          // NOTE: 設定されていない適用地域は処理を行わない
          if (filteredAgeRegionBasedFees.length === 0) continue

          // NOTE: 利用料金設定
          filteredAgeRegionBasedFees.forEach((ageRegionBasedFee) =>
            fees.push({
              text: [getFeeTitle(decodeMst, ageRegionBasedFee), formatNumberYenString(ageRegionBasedFee.usageFee)],
              gridSize: hasOnlyDay ? GRID_SIZE.NORMAL_ONLY_DAY : GRID_SIZE.NORMAL,
            })
          )

          /**
           * NOTE: 給食代
           *       適用地域が「共通」の場合、全給食代不一致で表示
           */
          if (
            (location.id === LOCATION_STATUS.COMMON && !isSameLunchFeeAll) ||
            (location.id !== LOCATION_STATUS.COMMON && !!filteredAgeRegionBasedFees[0].lunchFeeDisplayFlag)
          )
            fees.push({
              text: ['給食代', formatNumberYenString(filteredAgeRegionBasedFees[0].lunchFee)],
              gridSize: GRID_SIZE.NORMAL,
            })

          /**
           * NOTE: おやつ代
           *       適用地域が「共通」の場合、全おやつ代不一致時で表示
           */
          if (
            (location.id === LOCATION_STATUS.COMMON && !isSameSnackFeeAll) ||
            (location.id !== LOCATION_STATUS.COMMON && !!filteredAgeRegionBasedFees[0].snackFeeDisplayFlag)
          )
            fees.push({
              text: ['おやつ代', formatNumberYenString(filteredAgeRegionBasedFees[0].snackFee)],
              gridSize: GRID_SIZE.NORMAL,
            })

          // NOTE: 時間外料金
          fees.push(
            ...getFeeTimeSettings({
              feeTimeSettings,
              decodeMst,
              ageCategoryId,
              location: location.id,
            })
          )

          // NOTE: その他料金
          fees.push(...getOtherFeeSettings({ otherFeeSettings, location: location.id }))

          if (fees.length) {
            usageFees.push({
              title,
              fees,
            })
          }
        }
      })

      usageFees.push({
        fees: commonFees,
      })

      return usageFees
    },
    [getCommonLunchSnackFee, getFeeTimeSettings, getFeeTitle, getOtherFeeSettings, getOtherFeeSettingsForCommonLocation]
  )

  return {
    generateUsageFeesByAgeRegionBasedFees,
  }
}
