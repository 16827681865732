import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { translate } from '../../i18n'
import { signIn, verifyTel, verifyEmail,getAuthenticatedUser } from '../../utils/authUtil'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import {
  selectIsLoggedIn,
  selectIsOTPAuthenticated,
  selectIsVerifiedEmail,
  selectIsVerifiedTel,
  setIsOTPAuthenticated,
  setLogin,
} from '../common/store/slices/authority'
import { setLoginForm } from '../common/store/slices/login'
import { setCognitoUser } from '../common/store/slices/cognitoUser'
import { newPasswordUrl, loginUrl } from '../common/constant/appUrl'
import { executeGetSessionSettingMst } from '../../dataAccess/webApi/dao/sessionSettingMstDao'
import { executeGetAccountOtp, executePutAccountAuthentication, executePutAccountOtp } from '../../dataAccess/webApi/dao/accountDao'

interface LocationState {
  from: Location
}
interface Inputs {
  email: string
  password: string
}
interface SessionSettingMst {
  mfaType: string | null
  mfaPeriod: number | null
}

/**
 * ログイン画面フォームアクション
 */
export const useAction = () => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isOTPAuthenticated = useSelector(selectIsOTPAuthenticated)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const { ssoUseFlag, ssoUseName, smsSubscriptionFlag, phoneCertificationFlag } = useSelector(selectSystemControl)
  const [sessionSettingMst, setSessionSettingMst] = useState<SessionSettingMst>()
  
  const formMethods = useForm<Inputs>()
  const history = useHistory()

  const from = location.state?.from

  const initialize = useCallback(async () => {
    const result = await getSessionSettingMst()
    setSessionSettingMst({
      mfaType: result.mfaType,
      mfaPeriod: result.mfaPeriod,
    })
  }, [])

  const idDatePassed = (
    lastOtpLoginDatetimeString: string,
    mfaPeriodString: string
  ) => {
    const lastOtpLoginDatetime = new Date(lastOtpLoginDatetimeString)

    const today = new Date()

    const mfaPeriod = parseInt(mfaPeriodString, 10)

    const pastDate = new Date(today.getTime() - mfaPeriod * 24 * 60 * 60 * 1000)

    return lastOtpLoginDatetime <= pastDate
  }

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      // 入力情報を取得
      const { email, password } = data

      dispatch(
        // ログインリクエスト中にローディングアイコンを表示する
        showLoading(
          (async () => {
            try {
              // ログインリクエスト
              const cognitoUser = await signIn(email, password)

              if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // 初期パスワードでsignIn実行後、新パスワードを設定する
                // セッション情報を持つCognitoUserオブジェクトをインスタンス生成できないため、Reduxストアにそのまま格納する
                dispatch(setCognitoUser(cognitoUser))
                history.push(newPasswordUrl.url(), { from: loginUrl.url() })
              }
            } catch (error) {
              console.log(error)
              // エラーメッセージ表示
              dispatch(setLoginForm({
                error: error instanceof Error ? error.message : String(error)
              }));
            }

            // ログイン後のユーザー情報を取得
            const user = await getAuthenticatedUser(true)

            // メールアドレス・電話番号の検証可否を取得
            const isVerifiedEmail = !!user.attributes.email_verified
            const isVerifiedTel = !!user.attributes.phone_number_verified

            const userId = user.attributes.sub

            // Reduxストアにログインエントリを送信
            dispatch(
              setLogin({
                loginId: email,
                verifications: { tel: isVerifiedTel, email: isVerifiedEmail },
              })
            )
            
            // エラーメッセージを空にする
            dispatch(setLoginForm({}))

            const isVerifiedPending = !isVerifiedEmail || (!isVerifiedTel && smsSubscriptionFlag === '1' && phoneCertificationFlag === '1')

            if (isVerifiedPending) {
              await executePutAccountAuthentication({ userId, loginFlag: '1'})
              dispatch(setIsOTPAuthenticated(true))
            }
            
            if (!isVerifiedEmail) {
              // メールアドレス検証用のワンタイムパスワード発行
              await verifyEmail()
              
              // ワンタイムパスワード送信メッセージを表示
              dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            } else if (!isVerifiedTel && smsSubscriptionFlag === '1' && phoneCertificationFlag === '1') {
              // 電話番号検証用のワンタイムパスワード発行
              await verifyTel()
              
              // ワンタイムパスワード送信メッセージを表示
              dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            } else {
              switch (sessionSettingMst?.mfaType) {
                case '0':
                  console.log('認証しない')
                  await executePutAccountAuthentication({ userId, loginFlag: '1'})
                  dispatch(setIsOTPAuthenticated(true))
                  break
                case '1':
                  if (isVerifiedEmail && isVerifiedTel) {
                    console.log('認証する')
                    await executePutAccountOtp({ userId })
                  }
                  break
                case '2':
                  console.log('セッション期限切れ、認証する')
                  const response = await executeGetAccountOtp()
                  const userInfo = response.result
                  if (userInfo.lastOtpLoginDatetime && sessionSettingMst.mfaPeriod) {
                    if (idDatePassed(userInfo.lastOtpLoginDatetime, sessionSettingMst.mfaPeriod?.toString())) {
                      await executePutAccountOtp({ userId })
                    } else {
                      dispatch(setIsOTPAuthenticated(true))
                    }        
                  }
                  break                  
              }  
            }
          })
        )
      )
    },
    [sessionSettingMst]
  )

  useEffect(() => {
    initialize()
  }, [])

  return {
    isLoggedIn,
    isOTPAuthenticated,
    formMethods,
    onSubmit,
    isVerifiedEmail,
    isVerifiedTel,
    from,
    ssoUseFlag,
    ssoUseName,
    smsSubscriptionFlag,
    phoneCertificationFlag,
  }
}

const getSessionSettingMst = async () => {
  const apiResponse = await executeGetSessionSettingMst()
  return { mfaType: apiResponse.result.mfaType, mfaPeriod: apiResponse.result.mfaPeriod }
}
