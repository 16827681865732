import { Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/accountTelChange/accountTelChangeService'
import { yesNo } from '../../containers/common/constant/classification'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { Form } from '../components/common/inputs/form'
import { TelTextBox } from '../components/common/inputs/telTextBox'
import { CenterTitle } from '../components/common/centerTitle'

const ContentContainer = styled(Grid)({
  marginTop: '8vh',
})

// 説明文
const NoResultMessageCard = styled('div')({
  paddingTop: '3px',
})

export const AccountTelChange = () => {
  const { formMethods, onSubmit, sysCtrl } = useAction()
  console.log(sysCtrl)
  return (
    <ContentContainer>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Grid container>
            <Grid item md={4}></Grid>
            <Grid item xs={12} md={4}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <CenterTitle title={translate('accountTelChange.label.newTelLabel')} />
                </Grid>
                <Grid item xs={12}>
                  <NoResultMessageCard>
                    {translate('accountTelChange.text.newTelSetting')}
                  </NoResultMessageCard>
                  {sysCtrl.smsSubscriptionFlag === yesNo.yes && sysCtrl.phoneCertificationFlag === yesNo.yes &&
                    <NoResultMessageCard>
                      {translate('accountTelChange.text.newTelSend')}
                    </NoResultMessageCard>
                  }
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('accountTelChange.label.newTel')}
                  </Grid>
                  <Grid item xs={12}>
                    <TelTextBox
                      name="newTel"
                      label={translate('accountTelChange.label.newTel')}
                      required
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('accountTelChange.label.newTelConfirmation')}
                  </Grid>
                  <Grid item xs={12}>
                    <TelTextBox
                      name="newTelConfirmation"
                      label={translate('accountTelChange.label.newTelConfirmation')}
                      required
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                {sysCtrl.smsSubscriptionFlag === yesNo.yes && sysCtrl.phoneCertificationFlag === yesNo.yes &&
                  <Grid item xs={12}>
                    {translate('accountTelChange.text.annotation')}
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
          <BottomButtonGroup>
            <ButtonOutlinedWarningL fullWidth type="submit">
              {translate('accountTelChange.button.change')}
            </ButtonOutlinedWarningL>
            <BackButton />
          </BottomButtonGroup>
        </Stack>
      </Form>
    </ContentContainer>
  )
}
