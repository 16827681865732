// エリアなどのコード、区分値の一覧、名称を取得するための関数を定義
// 基本的にタブの名称以外の区分値、名称の取得はここに関数を定義して使用する
//

import { executeGetAreas } from '../../dataAccess/webApi/dao/areasDao'
import { executeGetFacilityCategories } from '../../dataAccess/webApi/dao/facilityCategoriesDao'
import { executeGetProjects } from '../../dataAccess/webApi/dao/projectsDao'
import { executeGetSearchFlags } from '../../dataAccess/webApi/dao/searchFlagsDao'
import { executeGetFacilitiesForAgesSixOver } from '../../dataAccess/webApi/dao/facilitiesDao'
import { getClassificationLabel, getClassificationValueLabels } from '../../i18n'
import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil'
import { fromNumber } from '../../utils/stringUtil'
import {
  accountCreateStep,
  age,
  ageTwoDigits,
  available,
  bloodType,
  cancelReason,
  covered,
  doNotWaitlist,
  facilityReservationDeleteStep,
  facilityReservationStep,
  facilitySearchSortOrder,
  gender,
  reservationStatusSearchCondition,
  residenceCategory,
  interviewReservationStep,
  interviewReservationStep2,
  interviewReservationDeleteStep,
  purposeOfUse,
  usageHistoryStatusSearchCondition,
  useReasonCategory,
  webReservation,
  yesNo,
  availabilityOfApplication,
} from './constant/classification'

// ----- API取得系 -----
// WebAPIの呼び出しが必要なもの。つまり非同期処理となるもの

/**
 * @returns エリア一覧
 */
export const getAreas = async () => {
  const apiResponse = await executeGetAreas()
  return apiResponse.result.map((area) => ({ value: area.areaId, label: area.areaName }))
}

/**
 * @returns 施設種別一覧
 */
export const getFacilityCategories = async () => {
  const apiResponse = await executeGetFacilityCategories()
  return apiResponse.result.map((category) => ({
    value: category.facilityCategoryId,
    label: category.facilityCategoryName,
  }))
}

/**
 * @param targetDate 対象日付
 * @returns 事業一覧
 */
export const getProjects = async (targetDate?: Date | ElapsedMillisecond, projectCategoryId?: string) => {
  const apiResponse = await executeGetProjects({ targetDate: toApiYmd(targetDate) })
  return apiResponse.result
    .filter((project) => project.projectCategoryId === projectCategoryId)
    .map((project) => ({
      value: project.projectId,
      label: project.projectName,
  }))
}

/**
 * @returns 検索フラグ一覧
 */
export const getSearchFlags = async () => {
  const apiResponse = await executeGetSearchFlags()
  return apiResponse.result.map((flag) => ({
    value: fromNumber(flag.searchFlagNo),
    label: flag.searchFlagName,
  }))
}

// ----- ソース組み込み系 -----
// WebAPIの呼び出しが不要なもの。つまり同期処理が可能なもの

/** i18n辞書の該当区分ルートキー */
const ageKey = 'system.classification.age'
/**
 * @returns 年齢一覧
 */
export const getAges = async () => {
  const apiResponse = await executeGetFacilitiesForAgesSixOver()

  const filteredAges = apiResponse.result.length === 0
    ? Object.values(age).filter(value => value !== '6' && value !== '7') // 条件が真の場合、class6とclass7を除外
    : Object.values(age); // 条件が偽の場合、すべての年齢を取得
    
  return getClassificationValueLabels(ageKey, filteredAges);
}
/**
 * @param value 区分値
 * @returns 年齢の表示文字列
 */
export const getAgeLabel = (value?: string | number) => {
  if (value != null) {
    const classificationValue = typeof value === 'number' ? fromNumber(value) : value
    return getClassificationLabel(ageKey, classificationValue)
  }
}

/** i18n辞書の該当区分ルートキー */
const ageTwoDigitsKey = 'system.classification.ageTwoDigits'
/**
 * @returns 年齢一覧（区分値２桁表記）
 */
export const getAgesTwoDigits = () => {
  return getClassificationValueLabels(ageTwoDigitsKey, Object.values(ageTwoDigits))
}
/**
 * @param value 区分値
 * @returns 年齢の表示文字列
 */
export const getAgeTwoDigitsLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(ageTwoDigitsKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const useReasonCategoryKey = 'system.classification.useReasonCategory'
/**
 * @returns 利用目的一覧
 */
export const getUseReasonCategories = () => {
  return getClassificationValueLabels(useReasonCategoryKey, Object.values(useReasonCategory))
}
/**
 * @param value 区分値
 * @returns 利用目的の表示文字列
 */
export const getUseReasonCategoryLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(useReasonCategoryKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const purposeOfUseKey = 'system.classification.purposeOfUse'
/**
 * @returns 利用目的一覧
 */
export const getPurposeOfUses = () => {
  return getClassificationValueLabels(purposeOfUseKey, Object.values(purposeOfUse))
}
/**
 * @param value 区分値
 * @returns 利用目的の表示文字列
 */
export const getPurposeOfUseLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(purposeOfUseKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const facilitySearchSortOrderKey = 'system.classification.facilitySearchSortOrder'
/**
 * @returns 施設検索ソート順一覧
 */
export const getFacilitySearchSortOrders = () => {
  return getClassificationValueLabels(facilitySearchSortOrderKey, Object.values(facilitySearchSortOrder))
}

/** i18n辞書の該当区分ルートキー */
const yesNoKey = 'system.classification.yesNo'
/**
 * @returns 有り無し一覧
 */
export const getYesNos = () => {
  return getClassificationValueLabels(yesNoKey, Object.values(yesNo))
}
/**
 * @param key 区分値
 * @returns 有り無しの表示文字列
 */
export const getYesNoLabel = (key?: boolean | string) => {
  let value
  if (typeof key === 'string') {
    value = key
  } else {
    value = key ? yesNo.yes : yesNo.no
  }
  return getClassificationLabel(yesNoKey, value)
}

/** i18n辞書の該当区分ルートキー */
const doNotWaitlistKey = 'system.classification.doNotWaitlist'
/**
 * @returns する しない一覧
 */
export const getDoNotWaitlist = () => {
  return getClassificationValueLabels(doNotWaitlistKey, Object.values(doNotWaitlist))
}
/**
 * @param key 区分値
 * @returns する しないの表示文字列
 */
export const getDoNotWaitlistLabel = (key?: boolean | string) => {
  let value
  if (typeof key === 'string') {
    value = key
  } else {
    value = key ? doNotWaitlist.do : doNotWaitlist.not
  }
  return getClassificationLabel(doNotWaitlistKey, value)
}

/** i18n辞書の該当区分ルートキー */
const httpStatusCodeKey = 'system.classification.httpStatusCode'
/**
 * @param value 区分値
 * @returns HTTPステータスの表示文字列
 */
export const getHttpStatusCodeLabel = (value?: number) => {
  if (value != null) {
    return getClassificationLabel(httpStatusCodeKey, fromNumber(value))
  }
}

/** i18n辞書の該当区分ルートキー */
const reservationStatusKey = 'system.classification.reservationStatus'
/**
 * @param value 区分値
 * @returns 利用予約情報/ステータスの表示文字列
 */
export const getReservationStatusLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(reservationStatusKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const interviewStatusKey = 'system.classification.interviewStatus'
/**
 * @param value 区分値
 * @returns 面談予約情報/ステータスの表示文字列
 */
export const getInterviewStatusLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(interviewStatusKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const permitFlagKey = 'system.classification.permitFlag'
/**
 * @param value 区分値
 * @returns 面談予約情報/利用判定の表示文字列
 */
export const getPermitFlagLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(permitFlagKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const genderKey = 'system.classification.gender'
/**
 * @returns 性別一覧
 */
export const getGenders = () => {
  return getClassificationValueLabels(genderKey, Object.values(gender))
}
/**
 * @param value 区分値
 * @returns 性別の表示文字列
 */
export const getGenderLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(genderKey, value)
  }
}
/** i18n辞書の該当区分ルートキー */
const residenceCategoryKey = 'system.classification.residenceCategory'
/**
 * @returns 在住種別一覧
 */
export const getResidenceCategorys = () => {
  return getClassificationValueLabels(residenceCategoryKey, Object.values(residenceCategory))
}
/**
 * @param value 区分値
 * @returns 在住種別の表示文字列
 */
export const getResidenceCategoryLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(residenceCategoryKey, value)
  }
}
/** i18n辞書の該当区分ルートキー */
const coveredKey = 'system.classification.covered'
/**
 * @returns 対象・対象外一覧
 */
export const getCovereds = () => {
  return getClassificationValueLabels(coveredKey, Object.values(covered))
}
/**
 * @param value 区分値
 * @returns 対象・対象外の表示文字列
 */
export const getCoveredLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(coveredKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const reductionsStatusKey = 'system.classification.reductionsStatus'
/**
 * @param value 区分値
 * @returns 減免適用状態の表示文字列
 */
export const getReductionsStatusLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(reductionsStatusKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const bloodTypeKey = 'system.classification.bloodType'
/**
 * @returns 血液型一覧
 */
export const getBloodTypes = () => {
  return getClassificationValueLabels(bloodTypeKey, Object.values(bloodType))
}
/**
 * @param value 区分値
 * @returns 血液型の表示文字列
 */
export const getBloodTypeLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(bloodTypeKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const cancelReasonKey = 'system.classification.cancelReason'
/**
 * @returns キャンセル理由一覧
 */
export const getCancelReasons = () => {
  return getClassificationValueLabels(cancelReasonKey, Object.values(cancelReason))
}
/**
 * @param value 区分値
 * @returns キャンセル理由の表示文字列
 */
export const getCancelReasonLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(cancelReasonKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const accountCreateStepKey = 'system.classification.accountCreateStep'
/**
 * @returns アカウント作成ステップ一覧
 */
export const getAccountCreateSteps = () => {
  return getClassificationValueLabels(accountCreateStepKey, Object.values(accountCreateStep))
}

/** i18n辞書の該当区分ルートキー */
const interviewReservationStepKey = 'system.classification.interviewReservationStep'
/**
 * @returns 面談予約ステップ一覧
 */
export const getInterviewReservationSteps = () => {
  return getClassificationValueLabels(interviewReservationStepKey, Object.values(interviewReservationStep))
}

/** i18n辞書の該当区分ルートキー */
const interviewReservationStep2Key = 'system.classification.interviewReservationStep2'
/**
 * @returns 面談予約ステップ一覧
 */
export const getInterviewReservationSteps2 = () => {
  return getClassificationValueLabels(interviewReservationStep2Key, Object.values(interviewReservationStep2))
}

/** i18n辞書の該当区分ルートキー */
const interviewReservationDeleteStepKey = 'system.classification.interviewReservationDeleteStep'
/**
 * @returns 面談予約キャンセルステップ一覧
 */
export const getInterviewReservationDeleteSteps = () => {
  return getClassificationValueLabels(interviewReservationDeleteStepKey, Object.values(interviewReservationDeleteStep))
}

/** i18n辞書の該当区分ルートキー */
const facilityReservationStepKey = 'system.classification.facilityReservationStep'
/**
 * @returns 施設予約ステップ一覧
 */
export const getFacilityReservationSteps = () => {
  return getClassificationValueLabels(facilityReservationStepKey, Object.values(facilityReservationStep))
}

/** i18n辞書の該当区分ルートキー */
const facilityReservationDeleteStepKey = 'system.classification.facilityReservationDeleteStep'
/**
 * @returns 利用予約キャンセルステップ一覧
 */
export const getFacilityReservationDeleteSteps = () => {
  return getClassificationValueLabels(facilityReservationDeleteStepKey, Object.values(facilityReservationDeleteStep))
}

/** i18n辞書の該当区分ルートキー */
const usageRecordApiStatusKey = 'system.classification.usageRecordApiStatus'
/**
 * @param value 区分値
 * @returns 利用実績API独自区分の表示文字列
 */
export const getUsageRecordApiStatusLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(usageRecordApiStatusKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const cancelLimitHolidayOptionKey = 'system.classification.cancelLimitHolidayOption'
/**
 * @param value 区分値
 * @returns キャンセル受付期限休園日オプションの表示文字列
 */
export const getCancelLimitHolidayOptionLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(cancelLimitHolidayOptionKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const webReservationKey = 'system.classification.webReservation'
/**
 * @returns Web予約可否一覧
 */
export const getWebReservations = () => {
  return getClassificationValueLabels(webReservationKey, Object.values(webReservation))
}
/**
 * @param value 区分値
 * @returns Web予約可否の表示文字列
 */
export const getWebReservationLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(webReservationKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const availableKey = 'system.classification.available'
/**
 * @returns 可・不可一覧
 */
export const getAvailables = () => {
  return getClassificationValueLabels(availableKey, Object.values(available))
}
/**
 * @param value 区分値
 * @returns 可・不可の表示文字列
 */
export const getAvailableLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(availableKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const reservationStatusSearchConditionKey = 'system.classification.reservationStatusSearchCondition'
/**
 * @returns 利用予約のステータスの検索条件一覧
 */
export const getReservationStatusSearchConditions = () => {
  return getClassificationValueLabels(reservationStatusSearchConditionKey, Object.values(reservationStatusSearchCondition))
}
/**
 * @param value 区分値
 * @returns 利用予約のステータスの検索条件の表示文字列
 */
export const getReservationStatusSearchConditionLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(reservationStatusSearchConditionKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const usageHistoryStatusSearchConditionKey = 'system.classification.usageHistoryStatusSearchCondition'
/**
 * @returns 利用履歴のステータスの検索条件一覧
 */
export const getUsageHistoryStatusSearchConditions = () => {
  return getClassificationValueLabels(usageHistoryStatusSearchConditionKey, Object.values(usageHistoryStatusSearchCondition))
}
/**
 * @param value 区分値
 * @returns 利用履歴のステータスの検索条件の表示文字列
 */
export const getUsageHistoryStatusSearchConditionLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(usageHistoryStatusSearchConditionKey, value)
  }
}

/** i18n辞書の該当区分ルートキー */
const availabilityOfApplicationKey = 'system.classification.availabilityOfApplication'
/**
 * @returns 利用履歴のステータスの検索条件一覧
 */
export const getAvailabilityOfApplication= () => {
  return getClassificationValueLabels(availabilityOfApplicationKey, Object.values(availabilityOfApplication))
}
/**
 * @param value 区分値
 * @returns 利用履歴のステータスの検索条件の表示文字列
 */
export const getAvailabilityOfApplicationLabel = (value?: string) => {
  if (value != null) {
    return getClassificationLabel(availabilityOfApplicationKey, value)
  }
}