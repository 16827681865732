import styled from '@emotion/styled'
import { FC, useMemo } from 'react'
import { FlexGItem, GItem } from '../common/grids'
import { UsageFee } from '../../../containers/facilityDetail/type'

type FeeItemProps = {
  usageFee: UsageFee
}

const FeeGItem = styled(FlexGItem)({
  width: '100%',
  '& div:last-child': {
    marginLeft: '5px',
  },
})

export const FeeItem: FC<FeeItemProps> = ({ usageFee }) => {
  // NOTE: タイトル表示・インデント表示制御
  const shouldShowTitle = useMemo(() => !!usageFee.title, [usageFee])

  return (
    <>
      {shouldShowTitle && <GItem>{usageFee.title}</GItem>}

      {usageFee.fees.map((fee, feeIndex) => (
        <FeeGItem key={feeIndex}>
          {shouldShowTitle && <GItem xs={1}></GItem>}

          <GItem xs={fee.gridSize[0]}>{fee.text[0]}</GItem>
          <GItem xs={fee.gridSize[1]}>{fee.text[1]}</GItem>
        </FeeGItem>
      ))}
    </>
  )
}
