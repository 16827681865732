import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fromApiHms, toApiYmd } from '../../utils/dateUtil'
import { dateToNumber, nullPropsToUndefined, datePropsToNumber } from '../../utils/objectUtil'
import { facilityReservationDeleteConfirmationUrl, reservationStatusListUrl } from '../common/constant/appUrl'
import { reservationStatus, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getUseReason } from '../common/useReason'
import { useOperationLog } from '../common/operationLog'
import { getReservation } from '../common/reservation'
import { showLoading } from '../common/store/slices/application'
import {
  selectFacilityReserveDeleteEntry,
  setFacilityReserveDeleteEntry,
  setFacilityReserveDeleteUpdateDatetime,
} from '../common/store/slices/facilityReserveDelete'
import { ReservationDelete } from '../../views/components/common/reservationDetail/reservationDetailGItems'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { setStringifyNamesChildInfoList, getReservationDecodeMstValue } from '../common/reservation'
import { getProjectMstDataToRefer } from '../common/projectMst'

interface UrlParams {
  reservationNo: string
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface Inputs {
  cancelReson: string
}

type ReservationDetail = ReservationDelete & {
  useReason: GetUseReasonDto
  reservationNo: string,
  facilityId: string,
  cancelLimit?: number,
  cancelLimitTime?: number,
  cancelNote?: string,
  cancelLimitHolidayOption?: string,
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { reservationNo } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state

  const deleteEntry = useSelector(selectFacilityReserveDeleteEntry)

  const formMethods = useForm<Inputs>({
    ...(locationState?.isKeep && {
      defaultValues: {
        cancelReson: deleteEntry?.cancelReason,
      },
    }),
  })
  const [reservationDetail, setReservationDetail] = useState<ReservationDetail | undefined>()

  useEffect(() => {
    let isMounted = true;
    if ( !reservationDetail ) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            const decodeMstValue = await getReservationDecodeMstValue();
            const { updateDatetime, ...detail } = await getReservationDetailWithRequirementSetting(reservationNo)
            
            const deletingReservationData = {
              ...detail,
              childInfoList: setStringifyNamesChildInfoList(detail.childInfoList, decodeMstValue)
            }
            
            if (isMounted) {
              dispatch(setFacilityReserveDeleteUpdateDatetime(updateDatetime))
              if (detail.status === reservationStatus.wait) {
                // キャンセル待ちの場合、キャンセル理由の入力は不要なので確認画面まで即遷移
                dispatch(
                  setFacilityReserveDeleteEntry(deletingReservationData)
                )
                
                history.replace(facilityReservationDeleteConfirmationUrl.url())
              } else {
                addOperationLog({
                  operationId: OperationId.OP_00000001,
                  
                  accessData: [
                    ...detail.childInfoList.map((child) => ({
                      userIdRegFlag: yesNo.yes,
                      childId: child.childId,
                      usageDate: toApiYmd(detail.usageDate),
                      reservationNo
                    }))
                  ],
                })
                setReservationDetail(deletingReservationData)
              }
            }
          })
        )
      )
    }
    return () => {
      isMounted = false; 
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntry])

  const goList = useCallback(() => history.push(reservationStatusListUrl.url()), [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000036 })
      if (reservationDetail) {
        dispatch(
          setFacilityReserveDeleteEntry({
            ...datePropsToNumber(reservationDetail),
            cancelReason: data.cancelReson,
          })
        )
        // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
        history.replace({ ...history.location, state: { isKeep: true } })
        history.push(facilityReservationDeleteConfirmationUrl.url())
      }
    },
    [reservationDetail, addOperationLog]
  )

  return {
    formMethods,
    reservationDetail,
    onSubmit,
    goList,
    deleteEntry
  }
}

const getReservationDetailWithRequirementSetting = async (reservationNo: string) => {
  const [{ reservationDatetime, reservationChangeDatetime, ...reservation }, useReason] = await Promise.all([
    getReservation(reservationNo),
    getUseReason(),
  ])
  
  const facility = await getFacility(reservation.facilityId, reservation.usageDate)
  const projectMst = await getProjectMstDataToRefer(reservation.facilityId, [reservation.usageDate], facility.projectId)
  const detailSetting = (facility.detailSetting.length === 1 && facility.detailSetting[0].useReasonCode === '0') ? facility.detailSetting[0] : facility.detailSetting.find((v) => v.useReasonCode === reservation.useReasonCategory)
  
  return nullPropsToUndefined({
    ...reservation,
    immediatelyReservationFlag: facility.immediatelyReservationFlag,
    lunchAcceptFlag: facility.lunchAcceptFlag,
    snackAcceptFlag: facility.snackAcceptFlag,
    postponeCancelWaitAcceptFlag: facility.postponeCancelWaitAcceptFlag,
    useReason,   
    cancelLimit: detailSetting?.cancelLimit,
    cancelLimitTime: fromApiHms(detailSetting?.cancelLimitTime)?.getTime(),
    cancelNote: detailSetting?.cancelNote,
    cancelLimitHolidayOption: detailSetting?.cancelLimitHolidayOption,
    reservationDatetime: dateToNumber(reservationDatetime),
    reservationChangeDatetime: dateToNumber(reservationChangeDatetime),
    projectMst
  })
}
