// ワンタイムパスワード入力を行う画面で共通する部分を定義
//

import { Grid, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { translate, translateNode } from '../../../../i18n'
import { ButtonL } from '../buttons/buttonL'
import { CenterGItem, GItem, RowSpacerGItem } from '../grids'
import { TextBox } from '../inputs/textBox'
import { Link } from '../link'
import { faqUrl, accountTelChangeUrl } from '../../../../containers/common/constant/appUrl'
import { CenterTitle } from '../centerTitle'
import { selectAccountChangeEntry } from '../../../../containers/common/store/slices/accountChange'

interface OneTimePasswordVerificationContentProps {
  /**
   * 再送信活性化タイマーの識別子。
   * このプロパティが変化すると再送信リンクが非活性になり、
   * 活性化タイマーが再スタートする。
   * 値は何でもよいが、基本的にはgetNow()の値を設定
   */
  identifier: unknown
  /**
   * 再送信クリック時に呼び出される関数。
   * 未設定であれば、再送信リンクを表示されない。
   */
  resend?: () => void

  /** 案内文 */
  infoMessage?: string
}

const ContentContainer = styled(Grid)({
  marginTop: '8vh',
})

const InfoMessageGItem = styled(GItem)({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
})

interface ResendState {
  identifier?: unknown
  disabled: boolean
  timeoutId?: number
}

// 説明文
const NoResultMessageCard = styled('div')({
  paddingTop: '3px',
  whiteSpace: 'pre-wrap',
})

/** 活性化遅延時間(ミリ秒) */
const delayTime = 90 * 1000

/**
 * ワンタイムパスワード入力を行う画面で共通するUI部分
 */
export const OneTimePasswordVerificationContent = (props: OneTimePasswordVerificationContentProps) => {
  const { identifier, resend, infoMessage } = props
  const [resendState, setResendState] = useState<ResendState>({ disabled: true })
  const changeEntry = useSelector(selectAccountChangeEntry)

  const delayRevitalization = useCallback(
    (identifier: unknown) => {
      if (identifier === resendState.identifier) {
        // 識別子がタイマー開始時と同じなら何もしない
        return
      }
      if (resendState.timeoutId) {
        window.clearTimeout(resendState.timeoutId)
      }
      setResendState({
        identifier,
        disabled: true,
        timeoutId: window.setTimeout(() => setResendState({ identifier, disabled: false }), delayTime),
      })
    },
    [resendState.timeoutId]
  )

  useEffect(() => {
    if (resend) {
      delayRevitalization(identifier)
      return () => window.clearTimeout(resendState.timeoutId)
    }
  }, [identifier, resend, delayRevitalization, resendState.timeoutId])

  return (
    <ContentContainer container>
      <Grid item md={4}></Grid>
      <Grid item xs={12} md={4}>
        <Grid container rowSpacing={2}>
          {infoMessage && (
            <>
              <Grid item xs={12}>
                <CenterTitle title={translate('system.oneTimePasswordVerificationContent.label.infomation')} />
              </Grid>
              <InfoMessageGItem xs={12}>
                {translateNode(
                  'identityVerification.text.infoMessage',
                  <Link to={faqUrl.url()}>
                    {translate('identityVerification.text.faq')}
                  </Link>
                )}
                {translateNode(
                  'identityVerification.text.infoMessage2',
                  <Link to={accountTelChangeUrl.url()}>
                    {translate('identityVerification.text.here')}
                  </Link>
                )}
              </InfoMessageGItem>
              <RowSpacerGItem />
            </>
          )}

          <Grid item xs={12}>
            <Grid container rowSpacing={3}>
              {!infoMessage && 
                <>
                  <Grid item xs={12}>
                    <CenterTitle title={translate('system.oneTimePasswordVerificationContent.label.infomation')} />
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <NoResultMessageCard>
                        {
                          changeEntry?.category === '1' ?
                          <>{translate('system.oneTimePasswordVerificationContent.text.sendOneTimeMessageforEmail')}</> :
                          <>{translate('system.oneTimePasswordVerificationContent.text.sendOneTimeMessageforTel')}</>
                        }
                      </NoResultMessageCard>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  {translate('system.oneTimePasswordVerificationContent.label.oneTimePassword')}
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="oneTimePassword"
                    label={translate('system.oneTimePasswordVerificationContent.label.oneTimePassword')}
                    maxLength={6}
                    textType="oneTimeCode"
                    autoComplete="off"
                    required
                    fullWidth
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <ButtonL fullWidth type="submit">
            {translate('system.oneTimePasswordVerificationContent.button.confirmation')}
          </ButtonL>
        </Grid>
          {resend && (
            <CenterGItem xs={12}>
              <Link onClick={resend} disabled={resendState.disabled}>
                {translate('system.oneTimePasswordVerificationContent.text.resend')}
              </Link>
            </CenterGItem>
          )}
        </Grid>
      </Grid>
      <Grid item md={4}></Grid>
    </ContentContainer>
  )
}
