import React from "react";
import { Control } from 'react-hook-form';
import { Inputs } from '../../../containers/facilityReservationForm/facilityReservationFormService';
import { UsageDatetimeType, GoingTimeType } from "../../../containers/common/reservation"
import { GOING_DATETIME_DISPLAY_METHODS, INPUT_FIELD_USAGE } from '../../../containers/common/constant/projectMst'
import { GetProjectDetailDto } from '../../../dataAccess/webApi/dto/projectsDto';
import LunchField from "./fields/lunchField"
import SnackField from "./fields/snackField"
import CancelWaitField from "./fields/cancelWaitField"
import GoingTimesField from "./fields/goingTimesField"
import ConsentCheckField from "./fields/consentCheckField"

export interface CommonFormUnitPorps {
    lunchAccept: boolean
    snackAccept: boolean
    cancelWaitAccept: boolean
    usageDatetimes: UsageDatetimeType[]
    goingTimes: GoingTimeType[]
    projectMst: GetProjectDetailDto
    control: Control<Inputs>
}

const CommonFormUnit: React.FC<CommonFormUnitPorps> = (props) => {
    const {
        lunchAccept,
        snackAccept,
        cancelWaitAccept,
        usageDatetimes,
        goingTimes,
        projectMst,
        control
    } = props
    return (
        <>
            {
                lunchAccept && (
                    <LunchField />
                )
            }
            {
                snackAccept && (
                    <SnackField />
                )
            }
            {
                cancelWaitAccept && (
                    <CancelWaitField />
                )
            }
            {
                ( projectMst.goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY ) && (
                    <GoingTimesField 
                        usageDatetimes={usageDatetimes}
                        goingTimes={goingTimes}
                    />
                )
            }
            {
                ( projectMst.consentCheckInput !== INPUT_FIELD_USAGE.NO_USE ) && (
                    <ConsentCheckField 
                        checkNote={projectMst.consentCheckNote}
                        control={control}
                    />
                )
            }
        </>
    )
}

export default CommonFormUnit;