import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import SelectBoxField from "./common/selectBoxField"

export interface EatingAbilityFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const EatingAbilityField = (props: EatingAbilityFieldProps) => {
    const { childNum, required, options, control } = props;
    
    return (
        <SelectBoxField 
            title={"facilityReservationForm.form.label.eatingAbility"}
            name={`children.${childNum}.eatingAbility`}
            selectLabel={"facilityReservationForm.form.label.eatingAbility"}
            required={required}
            control={control}
            options={options}
            addUnselected={!required}
        />
    )
}

export default EatingAbilityField;

