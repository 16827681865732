import React from 'react';
import YesNoField from './common/yesNoField'

const SnackField = () => {
    return (
        <YesNoField 
            name={"snackFlag"}
            required
            title={"facilityReservationForm.form.label.snack"}
            label={"facilityReservationForm.form.label.snack"}
        />
    );
}

export default SnackField