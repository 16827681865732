import { PostDecodeMstDto } from "../../../dataAccess/webApi/dto/decodeMstDto";

/** デコードマスタ種別 */
type ReservationSettingDecodeMst<T> = {
  default: T
};

export const DecodeMstCategory = {
  /** 病名 */
  DIAGNOSIS: 'diagonosis',
  // 症状
  SYMPTOM: 'symptom',
  /** 時間外料金の曜日設定 */
  SCHEDULED_DAY_TYPE: 'scheduled_day_type',
  /** 時間外料金の時間 */
  TIME_UNIT_TYPE: 'time_unit_type',
  /** 年齢別料金の時間 */
  AGE_REGION_BASED_FEE_TYPE: 'age_region_based_fee_type',
  /** 年齢別料金の設定日 */
  AGE_REGION_BASED_FEE_DAY_TYPE: 'age_region_based_fee_day_type',
} as const
export type DecodeMstCategory = (typeof DecodeMstCategory)[keyof typeof DecodeMstCategory]

export const DECODE_MST_DEFAULT: ReservationSettingDecodeMst<Pick<PostDecodeMstDto, 'name'>[]> = {
  default: [
    {
      name: '利用しない'
    },
    {
      name: '利用する'
    }
  ]
}
