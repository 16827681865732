import { Stack, styled } from '@mui/material'
import { getAvailableLabel } from '../../containers/common/codeMaster'
import {
  interviewReservationFormUrl,
  interviewReservationSelectionCreateUrl,
  facilityDetailAvailabilityUrl,
} from '../../containers/common/constant/appUrl'
import { yesNo, interviewAcceptMethod } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityDetail/facilityDetailService'
import { translate } from '../../i18n'
import { formatAcceptAge, formatAcceptAgeRange, formatAddress } from '../../utils/itemFormatUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { InlineFlexbox } from '../components/common/flexboxes'
import { GContainer, GItem, TopGItem } from '../components/common/grids'
import { AcceptAgeIcon } from '../components/common/icons/acceptAgeIcon'
import { AcceptWebReservationIcon } from '../components/common/icons/acceptWebReservationIcon'
import { InquiryIcon } from '../components/common/icons/inquiryIcon'
import { LinkIcon } from '../components/common/icons/linkIcon'
import { LocationIcon } from '../components/common/icons/locationIcon'
import { PostalCodeIcon } from '../components/common/icons/postalCodeIcon'
import { ProjectIcon } from '../components/common/icons/projectIcon'
import { TelIcon } from '../components/common/icons/telIcon'
import { TimeIcon } from '../components/common/icons/timeIcon'
import { TrainIcon } from '../components/common/icons/trainIcon'
import { UsageIcon } from '../components/common/icons/usageIcon'
import { WalkIcon } from '../components/common/icons/walkIcon'
import { ImageCarousel } from '../components/common/imageCarousel'
import { Link } from '../components/common/link'
import { Tag } from '../components/common/tag'
import { TitleLabel } from '../components/common/titleLabel'
import { FeeNotion } from '../components/facilityDetail/feeNotion'
import { LocationState } from '../../containers/facilityDetailAvailability/facilityDetailAvailabilityService';
import { DocumentIcon } from '../components/common/icons/documentIcon'

interface UsageListProps {
  usages: string[]
}
const Root = styled('div')(({}) => ({
  '& ul': {
    margin: 0,
    padding: 0,
  },
  '& li': {
    display: 'flex',
  },
}))
const DetailGItem = styled(GItem)(({ theme }) => ({
  color: theme.palette.primary.main,
}))
const AlignStartGItem = styled(GItem)({
  alignItems: 'start',
})
const ItemIcon = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))
const WrapTopGItem = styled(TopGItem)({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
})

const MessageBox = styled('div')({
  whiteSpace: 'pre-wrap',
  marginLeft: '1rem',
})
const SearchFlagGItem = styled(GItem)({
  '& > *': {
    margin: '1px',
  },
})

const UsageList = (props: UsageListProps) => {
  return props.usages.length ? (
    <Root>
      <ul>
        <li>{translate('facilityDetail.label.usage')}：</li>
        {props.usages.map((usage) => (
          <li key={usage}>{usage}</li>
        ))}
      </ul>
    </Root>
  ) : null
}
export const FacilityDetail = () => {
  const {
    facilityId,
    facility,
    useReason,
    availabilityBaseDate,
    sysCtrl,
    decodeMst,
    usageFeeSetting,
    locationStatus,
    onClickLink
  } = useAction()

  if (facility == null) {
    return null
  }
  const isInterviewSlots = sysCtrl.interviewDailyAcceptFlag === yesNo.yes
                            && facility.interviewAcceptMethod === interviewAcceptMethod.interviewSlots
  const isPreinterviewAccept = facility.preinterviewAcceptFlag === yesNo.yes
  const isNotLoginCalendarDisplayFlag = facility.notLoginCalendarDisplayFlag === yesNo.yes
  const address = formatAddress(facility.address1, facility.address2, facility.buildingNameRoomNo)
  const acceptAgeFrom = formatAcceptAge(
    facility.acceptStartAge,
    facility.acceptStartMonth,
    facility.acceptStartDay,
    facility.acceptStartAgeGradeFlag
  )
  const acceptAgeTo = formatAcceptAge(
    facility.acceptEndAge,
    facility.acceptEndMonth,
    facility.acceptEndDay,
    facility.acceptEndAgeGradeFlag
  )

  const usage = [
    facility.irregularFlag === yesNo.no ? [] : useReason ? [useReason.displayNameForCitizenIrregular] : [],
    facility.emergencyFlag === yesNo.no ? [] : useReason ? [useReason.displayNameForCitizenEmg] : [],
    facility.refreshFlag === yesNo.no ? [] : useReason ? [useReason.displayNameForCitizenRefresh] : [],
  ].flat()

  const AvailabilityPageLocationState: LocationState = {
    activeDate: availabilityBaseDate?? null,
    acceptAgeFrom: {
      age: facility.acceptStartAge?? null,
      month: facility.acceptStartMonth?? null,
      day: facility.acceptStartDay?? null,
      classFlag: facility.acceptStartAgeGradeFlag !== yesNo.yes
    },
    acceptAgeTo: {
      age: facility.acceptEndAge?? null,
      month: facility.acceptEndMonth?? null,
      day: facility.acceptEndDay?? null,
      classFlag: facility.acceptEndAgeGradeFlag !== yesNo.yes
    }
  };

  return (
    <Stack spacing={2}>
      <div>
        <GContainer rowSpacing={1} columnSpacing={2}>
          <GItem md={2} sx={{ display: { xs: 'none', md: 'block' } }}></GItem>
          <DetailGItem xs={12} md={4}>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>
                <TitleLabel>{facility.facilityName}</TitleLabel>
              </GItem>
              {facility.homePageUrl && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <LinkIcon fontSize="inherit" />
                  </ItemIcon>
                  <Link href={facility.homePageUrl} target="_blank">
                    {facility.homePageUrl}
                  </Link>
                </TopGItem>
              )}
              {facility.projectName && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <ProjectIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.projectName}
                </TopGItem>
              )}
              {facility.postalCode && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <PostalCodeIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.postalCode}
                </TopGItem>
              )}
              {address && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <LocationIcon fontSize="inherit" />
                  </ItemIcon>
                  {address}
                </TopGItem>
              )}
              {facility.nearestStation && (
                <WrapTopGItem xs={12}>
                  <ItemIcon>
                    <TrainIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.nearestStation}
                </WrapTopGItem>
              )}
              {facility.timeFromStation && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <WalkIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.timeFromStation}
                </TopGItem>
              )}
              <TopGItem xs={12}>
                <ItemIcon>
                  <TelIcon fontSize="inherit" />
                </ItemIcon>
                <Stack>
                  <div>
                    {translate('facilityDetail.label.tel')}：{facility.tel}
                  </div>
                  {facility.fax && (
                    <div>
                      {translate('facilityDetail.label.fax')}：{facility.fax}
                    </div>
                  )}
                </Stack>
              </TopGItem>
              {facility.inquiryTime && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <InquiryIcon fontSize="inherit" />
                  </ItemIcon>
                  {translate('facilityDetail.label.inquiryTime')}：{facility.inquiryTime}
                </TopGItem>
              )}
              {(facility.openTimeWeekday || facility.openTimeSaturday || facility.openTimeHoliday) && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <TimeIcon fontSize="inherit" />
                  </ItemIcon>
                  <div>
                  {translate('facilityDetail.label.acceptanceTime')}
                    <Stack>
                      {facility.openTimeWeekday && (
                        <div>
                          {translate('facilityDetail.label.weekday')}：{facility.openTimeWeekday}
                        </div>
                      )}
                      {facility.openTimeSaturday && (
                        <div>
                          {translate('facilityDetail.label.saturday')}：{facility.openTimeSaturday}
                        </div>
                      )}
                      {facility.openTimeHoliday && (
                        <div>
                          {translate('facilityDetail.label.holiday')}：{facility.openTimeHoliday}
                        </div>
                      )}
                    </Stack>
                  </div>
                </TopGItem>
              )}
              {acceptAgeFrom && acceptAgeTo && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <AcceptAgeIcon fontSize="inherit" />
                  </ItemIcon>
                  <Stack>
                    <div>{formatAcceptAgeRange(acceptAgeFrom, acceptAgeTo)}</div>
                    {facility.ageNote && <div>{`（${facility.ageNote}）`}</div>}
                  </Stack>
                </TopGItem>
              )}
              <TopGItem xs={12}>
                <ItemIcon>
                  <AcceptWebReservationIcon fontSize="inherit" />
                </ItemIcon>
                {translate('system.facilityCard.acceptWebReservation')}：
                {getAvailableLabel(facility.reservationAcceptFlag)}
              </TopGItem>

              <FeeNotion
                facility={facility}
                decodeMst={decodeMst}
                usageFeeSetting={usageFeeSetting}
                locationStatus={locationStatus}
              />

              {!!usage.length && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <UsageIcon fontSize="inherit" />
                  </ItemIcon>
                  <UsageList usages={usage} />
                </TopGItem>
              )}
              {facility.message && (
                <AlignStartGItem xs={12}>
                  <div>～{translate('facilityDetail.label.message')}～</div>
                  <MessageBox>{facility.message}</MessageBox>
                </AlignStartGItem>
              )}
              <SearchFlagGItem xs={12}>
                {facility.searchFlagNames.map((searchFlagName, index) => (
                  <Tag key={index}>{searchFlagName}</Tag>
                ))}
              </SearchFlagGItem>
              {facility.documents
                .filter(
                  (documentInfo) =>
                    !(
                      (documentInfo.documentFileUrl === null ||
                        documentInfo.documentFileUrl === '') &&
                      (documentInfo.documentFileName === null ||
                        documentInfo.documentFileName === '')
                    )
                )
                .map((documentInfo, index) => (
                  <TopGItem xs={12} key={index}>
                    <ItemIcon>
                      <DocumentIcon fontSize="inherit" />
                    </ItemIcon>
                    <Link
                      href={'#'}
                      onClick={(event) => onClickLink(event, documentInfo)}
                    >
                      {documentInfo.documentFileName ||
                        documentInfo.documentFileUrl?.substring(
                          documentInfo.documentFileUrl.lastIndexOf('/') + 1
                        )}
                    </Link>
                  </TopGItem>
                ))}
            </GContainer>
          </DetailGItem>
          <GItem xs={12} md={4}>
            <div>
              <ImageCarousel imageUrls={facility.imageUrls} />
            </div>
          </GItem>
          <GItem md={2} sx={{ display: { xs: 'none', md: 'block' } }}></GItem>
        </GContainer>
      </div>
      <BottomButtonGroup>
        {isNotLoginCalendarDisplayFlag && (
          <ButtonL
            type="submit"
            to={{
              pathname: facilityDetailAvailabilityUrl.url(facilityId),
              state: AvailabilityPageLocationState
            }}
            fullWidth
          >
            {translate('facilityDetail.button.checkAvailability')}
          </ButtonL>
        )}
        {isPreinterviewAccept && !isInterviewSlots && (
          <ButtonOutlinedWarningL fullWidth to={interviewReservationFormUrl.url(facilityId)}>
            {translate('facilityDetail.button.usageApplication')}
          </ButtonOutlinedWarningL>
        )}
        {isPreinterviewAccept && isInterviewSlots && (
          <ButtonOutlinedWarningL fullWidth to={interviewReservationSelectionCreateUrl.url(facilityId)}>
            {translate('facilityDetail.button.usageApplication')}
          </ButtonOutlinedWarningL>
        )}
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
