import React from "react"
import { GItem } from "../../../../components/common/grids"

type PreserveLineTextProps = {
    content: string
}

const PreserveLineText: React.FC<PreserveLineTextProps> = (props: PreserveLineTextProps) => {
    const { content } = props
    return (
        <GItem xs={12}　mt={1} mb={1} sx={{ whiteSpace: 'pre-line'}}>
            {content}
        </GItem>
    )
}

export default PreserveLineText