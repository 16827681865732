import { executeGet } from '../common/apiCaller'
import { GetUsePasswordRulePublicDto } from '../dto/passwordRuleDto'

const apiName = 'public'
const apiPath = '/password-policy'

interface PasswordInput {
    ruleId: string;
}

export const executeGetPasswordRulePublic = (input: PasswordInput) => {
  return executeGet<GetUsePasswordRulePublicDto>(apiName, apiPath, input)
}
