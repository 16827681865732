import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import SelectBoxField from "./common/selectBoxField"

export interface ActionsFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    control: Control<Inputs>
}

const ActionsField = (props: ActionsFieldProps) => {
    const { childNum, required, options, control } = props;
    
    const showOtherSelection = ["睡眠"]
    
    return (
        <SelectBoxField 
            title={"facilityReservationForm.form.label.action.field"}
            name={`children.${childNum}.actions.action`}
            selectLabel={"facilityReservationForm.form.label.action.field"}
            otherNameApendix={"sleepMethod"}
            otherLabel={"facilityReservationForm.form.label.action.sleepMethod"}
            otherTitle={"facilityReservationForm.form.label.action.sleepMethod"}
            required={required}
            control={control}
            options={options}
            addUnselected
            showOtherSelection={showOtherSelection}
        />
    )
}

export default ActionsField;

