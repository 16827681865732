import { styled } from '@mui/material'
import { UsageDatetimeType, GoingTimeType } from "../../../../containers/common/reservation"
import { formatHmtoHmOver, formatYmdWeek } from '../../../../utils/dateUtil'
import { LabelGItemBase, GItem } from "../../../components/common/grids"
import { TitleLabel } from "../../../components/common/titleLabel"
import { AttentionLabel } from "../../../components/common/attentionLabel"
import { TimePicker } from '../../../components/common/inputs/timePicker'
import { translate } from '../../../../i18n'

const TimePickerWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
  textIndent: '1em',
})

export interface GoingTimesFieldProps {
    usageDatetimes: UsageDatetimeType[]
    goingTimes: GoingTimeType[]
}

const GoingTimesField = (props: GoingTimesFieldProps) => {
    const { goingTimes, usageDatetimes } = props

    return (
        <GItem xs={12}>
          <div>
            <TitleLabel>
              <AttentionLabel>*</AttentionLabel>
              {translate("facilityReservationForm.form.label.goingTimes.field")}
            </TitleLabel>
            {goingTimes.map((goingTimeFiled, index) => {
              if (
                !goingTimeFiled.goingToFacilityDate &&
                !goingTimeFiled.goingToFacilityTime &&
                !goingTimeFiled.goingHomeDate &&
                !goingTimeFiled.goingHomeTime
              )
                return null
              return (
                <div key={index}>
                  <GItem xs={12}>
                    <LabelGItemBase>
                      {formatYmdWeek(usageDatetimes[index].usageDate)}
                      {'　'}
                      {formatHmtoHmOver(
                        {
                          from: usageDatetimes[index].useFromDatetime,
                          to: usageDatetimes[index].useToDatetime,
                        },
                        usageDatetimes[index].usageDate
                      )}
                    </LabelGItemBase>
                  </GItem>
                  <GItem xs={12}>
                    <TimePickerWrap>
                      <LabelGItemBase>
                        {translate("facilityReservationForm.form.label.goingTimes.toFacility")}
                      </LabelGItemBase>
                      <TimePicker name={`goingTimes.${index}.goingToFacilityTime`} />
                    </TimePickerWrap>
                  </GItem>
                  <GItem xs={12}>
                    <TimePickerWrap>
                      <LabelGItemBase>
                        {translate("facilityReservationForm.form.label.goingTimes.home")}
                      </LabelGItemBase>
                      <TimePicker name={`goingTimes[${index}].goingHomeTime`} />
                    </TimePickerWrap>
                  </GItem>
                </div>
              )
            })}
          </div>
        </GItem>
    )

}

export default GoingTimesField;
