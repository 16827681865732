import { FC } from 'react'
import { GItemContainer } from '../common/grids'
import { UsageFee } from '../../../containers/facilityDetail/type'
import { FeeItem } from './feeItem'

type UsageFeeSettingProps = {
  usageFees: UsageFee[]
}

export const UsageFeeSetting: FC<UsageFeeSettingProps> = ({ usageFees }) => {
  return (
    <GItemContainer columns={30}>
      {usageFees.map((usageFee, usageFeeKey) => (
        <FeeItem usageFee={usageFee} key={usageFeeKey} />
      ))}
    </GItemContainer>
  )
}
