import { Grid, Stack, styled } from '@mui/material'
import {
  getCancelLimitHolidayOptionLabel,
  getCancelReasons,
  getFacilityReservationDeleteSteps,
} from '../../containers/common/codeMaster'
import { facilityReservationDeleteStep } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityReservationDelete/facilityReservationDeleteService'
import { translate } from '../../i18n'
import { formatCancelLimit } from '../../utils/itemFormatUtil'
import { AlertCard } from '../components/common/alertCard'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BreakpointSwitch } from '../components/common/breakpointSwitch'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedL } from '../components/common/buttons/buttonOutlinedL'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { GContainer, GItem, MiddleGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { Form } from '../components/common/inputs/form'
import { Select } from '../components/common/inputs/select'
import ReservationDetailGItems from '../components/common/reservationDetail/reservationDetailGItems'
import { StepBar } from '../components/common/stepBar'

const AnnotationBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const FacilityReservationDelete = () => {
  const { formMethods, reservationDetail, onSubmit, goList } = useAction()

  if ( reservationDetail===undefined ) {
    return null
  }
  const { cancelLimit, cancelLimitTime, cancelNote, cancelLimitHolidayOption } = reservationDetail
  const alertCardConents = []
  if (cancelLimit != null && cancelLimitTime != null) {
    alertCardConents.push(
      <div key="cancelLimit">
        {translate('facilityReservationDelete.label.cancelLimit')}：{formatCancelLimit(cancelLimit, cancelLimitTime)}
      </div>
    )
    if (cancelLimitHolidayOption) {
      alertCardConents.push(
        <div key="holidayOption">※{getCancelLimitHolidayOptionLabel(cancelLimitHolidayOption)}</div>
      )
    }
  }
  if (cancelNote) {
    if (alertCardConents.length) {
      alertCardConents.push(<div key="cancelNoteSep">&nbsp;</div>)
    }
    alertCardConents.push(<AnnotationBox key="cancelNote">{cancelNote}</AnnotationBox>)
  }
  return (
    <Form onSubmit={onSubmit} formMethods={formMethods}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Stack spacing={2}>
              <StepBar
                activeStepValue={facilityReservationDeleteStep.step1}
                stepValueLabels={getFacilityReservationDeleteSteps()}
              />
              {!!alertCardConents.length && <AlertCard>{alertCardConents.map((v) => v)}</AlertCard>}
              <div>
                <GContainer>
                  <MiddleGItem>
                    <AttentionLabel>*</AttentionLabel>
                    {translate('facilityReservationDelete.label.cancelReason')}　
                  </MiddleGItem>
                  <GItem xs>
                    <Select
                      name="cancelReson"
                      label={translate('facilityReservationDelete.pulldown.cancelReason')}
                      required
                      fullWidth
                      valueLabels={getCancelReasons()}
                    />
                  </GItem>
                </GContainer>
              </div>
              <InfoCard>
                <Grid container>
                  <ReservationDetailGItems reservation={reservationDetail} useReason={reservationDetail.useReason} displayType="delete" />
                </Grid>
              </InfoCard>
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
        <BottomButtonGroup>
          <ButtonL type="submit" fullWidth>
            {translate('facilityReservationDelete.button.cancelContentConfirmation')}
          </ButtonL>
          <BreakpointSwitch
            xs={<ButtonOutlinedS onClick={goList}>{translate('system.button.stop')}</ButtonOutlinedS>}
            md={
              <ButtonOutlinedL fullWidth onClick={goList}>
                {translate('system.button.stop')}
              </ButtonOutlinedL>
            }
          />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
