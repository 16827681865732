import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, FieldPath } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useErrorHandle } from '../../containers/common/error/errorHandler'
import { FacilityReserveEntry, selectFacilityReserveEntry, setFacilityReserve } from '../../containers/common/store/slices/facilityReserve'
import { GoingTimeType } from '../common/reservation'
import { executeGetChild } from '../../dataAccess/webApi/dao/childrenDao'
import { GetChildDto } from '../../dataAccess/webApi/dto/childrenDto'
import { User } from '../common/user'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { toApiYmd, toApiHms } from '../../utils/dateUtil'
import { dateToNumber, undefinedPropsToOptional } from '../../utils/objectUtil'
import { ReactBatchUpdater } from '../../utils/reactUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { facilityReservationConfirmationUrl } from '../common/constant/appUrl'
import { availabilityStatus, yesNo, Flag, mailType } from '../common/constant/classification'
import { getFacility } from '../common/facility'
import { getUseReason } from '../common/useReason'
import { showLoading } from '../common/store/slices/application'
import { getUser } from '../common/user'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { isAfter } from 'date-fns'
import { getSendingFlagMailSetting } from '../common/getFacilityMailSetting'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { GOING_DATETIME_DISPLAY_METHODS, INPUT_FIELD_USAGE, MEDICAL_DOC_USAGE } from '../common/constant/projectMst'
import {  ReservationSettingDecodeMstKey } from '../common/reservation'
import { setStringifyNamesChildInfoList, getReservationDecodeMstValue, ChildReservationInfoType, ShowMessage } from '../common/reservation'
import { getProjectMstDataToRefer } from '../common/projectMst'
import { GetProjectDetailDto } from '../../dataAccess/webApi/dto/projectsDto'
import { translate } from '../../i18n'

type ReservationSettingDecodeMst = ReservationSettingDecodeMstKey<PostDecodeMstDto[]>

export interface Inputs {
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  goingToFacilityTime: Date
  goingTimes: GoingTimeType[]
  note: string
  showMessages: ShowMessage[]
  children: ChildReservationInfoType[]
  consentCheck: string
}

export type facilityReservationInputKey = FieldPath<Inputs>

interface PageState {
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  /** 利用目的マスタ情報 */
  useReason?: NullPropsToUndefinedType<GetUseReasonDto>
  /** 送信フラグデータ */
  cancelSendingFlag?: boolean
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const entryInput = reserveEntry?.input
  const { addOperationLog } = useOperationLog()

  const [state, setState] = useState<PageState>()

  const formMethods = useForm<Inputs>()

  const history = useHistory()

  const [showMessages, setShowMessages] = useState<ShowMessage[]>()
  const [decodemst, setDecodeMst] = useState<ReservationSettingDecodeMst>()
  const [projectMst, setProjectmst] = useState<GetProjectDetailDto>()
  
  const { fields: goingTimeFileds } = useFieldArray({
    control: formMethods.control,
    name: 'goingTimes',
  })
  
  const { fields: childrenWatch } = useFieldArray({
    control: formMethods.control,
    name: 'children',
  })

  
  const reservationReference = useMemo(() => {
    if (projectMst && reserveEntry?.facility && reserveEntry?.parentInfo && reserveEntry?.useReason && reserveEntry?.childInfoList) {
      const { facility, parentInfo, useReasonCategory, useReasonDetailCategory, useReasonDetailDescription, useReason, childInfoList } = reserveEntry
      return {
        facilityName: facility.facilityName,
        useReasonCategory: useReasonCategory,
        useReasonDetailCategory: useReasonDetailCategory,
        useReasonDetailDescription: useReasonDetailDescription,
        childInfoList,
        parentInfo,
        projectMst,
        useReason: {...useReason},
      }
    }
  }, [reserveEntry, projectMst])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000025 })
      let isError = false

      // TODO: readonlyを回避するため
      const goingTimes = data.goingTimes.map((goingTime) => ({
        ...goingTime,
      }))

      goingTimes.forEach((goingTime, index) => {
        const goingToFaclityDatetime = new Date(`${goingTime.goingToFacilityDate} ${goingTime.goingToFacilityTime}`)
        const goingHomeDatetime = new Date(`${goingTime.goingHomeDate} ${goingTime.goingHomeTime}`)
        if (isAfter(goingToFaclityDatetime, goingHomeDatetime)) {
          formMethods.setError(`goingTimes.${index}.goingToFacilityTime`, {
            message: translate("facilityReservationForm.form.error.goingTimes"),
          })
          isError = true
        }
      })

      const children:ChildReservationInfoType[] = JSON.parse(JSON.stringify(data.children));

      if (isError) return
      dispatch(
        reserveEntry &&
        setFacilityReserve({
          ...reserveEntry,
          goingTimes,
          childInfoList: (decodemst) ? setStringifyNamesChildInfoList(children, decodemst) : children,
          input: {
            ...reserveEntry.input,
            lunchFlag: data.lunchFlag,
            snackFlag: data.snackFlag,
            postponeCancelWaitFlag: data.postponeCancelWaitFlag,
            citizenNote: data.note,
          },
          consentCheck: data.consentCheck
        })
      )
      history.push(facilityReservationConfirmationUrl.url()) //値の送り先を設定
    },
    [reserveEntry, addOperationLog, formMethods]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    dispatch(
      showLoading(
        errorHandle(async () => {
          if ( !reserveEntry ) {
            // 前画面入力情報が無い場合はエラーにするので何もしない
            return
          }
          const { facility, user, children, useReason } = await getUserWithSelectionChild(
            reserveEntry.facilityId,
            reserveEntry.childInfoList.map((childInfo) => childInfo.childId),
            new Date(reserveEntry.usageDatetimes[0].usageDate)
          )
          
          const usageDates = reserveEntry.usageDatetimes.map(( usageDatetime ) => usageDatetime.usageDate )
          const projectMstData = await getProjectMstDataToRefer(reserveEntry.facilityId, usageDates);
          
          // 給食注文
          let lunchFlag: string = yesNo.no
          if (facility.lunchAcceptFlag === yesNo.yes) {
            lunchFlag = entryInput?.lunchFlag ?? facility.lunchInitialSelect ?? yesNo.yes
          }
          // おやつ注文
          let snackFlag: string = yesNo.no
          if (facility.snackAcceptFlag === yesNo.yes) {
            snackFlag = entryInput?.snackFlag ?? facility.snackInitialSelect ?? yesNo.yes
          }

          // 登降園予定時間
          const goingTimes: GoingTimeType[] = []
          for (let index = 0; index < reserveEntry.usageDatetimes.length; index++) {
            const usageDatetime = reserveEntry.usageDatetimes[index];

            if ( projectMstData.goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY ) {
              const prevGoingTime = reserveEntry.goingTimes[index];
              const goingToFaclityDatetime = new Date(prevGoingTime ? `${prevGoingTime.goingToFacilityDate} ${prevGoingTime.goingToFacilityTime}` : usageDatetime.useFromDatetime)
              const goingHomeDatetime = new Date(prevGoingTime ? `${prevGoingTime.goingHomeDate} ${prevGoingTime.goingHomeTime}` : usageDatetime.useToDatetime)
              const goingToFacilityDate = toApiYmd(new Date(goingToFaclityDatetime))
              const goingToFacilityTime = toApiHms(new Date(goingToFaclityDatetime))
              const goingHomeDate = toApiYmd(new Date(goingHomeDatetime))
              const goingHomeTime = toApiHms(new Date(goingHomeDatetime))
              goingTimes.push({
                goingToFacilityDate,
                goingToFacilityTime,
                goingHomeDate,
                goingHomeTime,
              })
            } else {
              goingTimes.push({
                goingToFacilityDate: '',
                goingToFacilityTime: '',
                goingHomeDate: '',
                goingHomeTime: '',
              })
            }
          }

          // デコードマスタ取得
          setDecodeMst(await getReservationDecodeMstValue())

          // メール設定（送信フラグ）情報を取得し、送信フラグ："0"(無)の場合、FALSEとする
          let cancelSendingFlag = true
          if (reserveEntry?.usageDatetimes[0].status === availabilityStatus.wait) {
            const sendingFlagInfo = await getSendingFlagMailSetting({mailId: mailType.immediatelyCancelVacant, facilityId: reserveEntry.facilityId})
            if (sendingFlagInfo && sendingFlagInfo.sendingFlag === '0') {
              cancelSendingFlag = false
            }
          }

          // 予約が取れなかった場合項目
          let postponeCancelWaitFlag: string = yesNo.no
          if (isShownPostponeCancelWaitItem(facility)) {
            if (entryInput) {
              postponeCancelWaitFlag = entryInput.postponeCancelWaitFlag
            } else if (
              reserveEntry.reservationNo == null &&
              reserveEntry.usageDatetimes[0].status === availabilityStatus.wait
            ) {
              // 入力項目が表示されていて 新規キャンセル待ち予約なら
              // 「キャンセル待ちする」を初期選択
              postponeCancelWaitFlag = yesNo.yes
            }
          }
          if (reserveEntry.showMessages.length) {
            setShowMessages(reserveEntry.showMessages)
          }
          
          // お子様情報のセット
          const childInfoList = setChildInfo(children, reserveEntry, projectMstData);
          
          const batchUpdater = new ReactBatchUpdater()
          batchUpdater.add(() =>
            formMethods.reset({
              ...formMethods.getValues(),
              ...undefinedPropsToOptional({
                purposeOfUse: reserveEntry?.useReasonCategory,
                lunchFlag,
                snackFlag,
                goingTimes,
                postponeCancelWaitFlag,
                note: entryInput?.citizenNote,
                showMessages: showMessages,
                children: childInfoList,
                consentCheck: reserveEntry.consentCheck
              }),
            })
          )
          batchUpdater.add(() =>
            dispatch(
              setFacilityReserve({
                ...reserveEntry,
                // 施設情報
                facility: {
                  ... facility
                },
                // 保護者情報
                parentInfo: {
                  name: user.name,
                  kana: user.kana,
                  tel: user.tel,
                  email: user.email,
                  residenceCategory: user.residenceCategory,
                },
                // お子さま情報
                childInfoList: childInfoList,
                useReason,
              })
            )
          )
          batchUpdater.addSetter(setState, { facility: facility, useReason, cancelSendingFlag })
          batchUpdater.addSetter(setProjectmst, projectMstData)
          batchUpdater.execute()
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowMessages])

  return {
    reserveEntry,
    reservationReference,
    facility: state?.facility,
    useReason: state?.useReason,
    isShownPostponeCancelWaitItem: isShownPostponeCancelWaitItem(state?.facility),
    formMethods,
    onSubmit,
    showMessages,
    goingTimeFileds,
    decodemst,
    projectMst,
    childrenWatch,
    cancelSendingFlag: state?.cancelSendingFlag
  }
}

//------------------------------------------------------------------------------------------
const isShownPostponeCancelWaitItem = (facility: NullPropsToUndefinedType<GetFacilityDto> | undefined) => {
  if (facility) {
    return facility.immediatelyReservationFlag === yesNo.no && facility.postponeCancelWaitAcceptFlag === yesNo.yes
  } else {
    return false
  }
}

async function getUserWithSelectionChild(
  facilityId: string,
  childIds: string[],
  targetDate: Date
): Promise<{
  facility: NullPropsToUndefinedType<GetFacilityDto>
  user: User
  children: GetChildDto[]
  useReason: NullPropsToUndefinedType<GetUseReasonDto>
}> {
  const [facility, user, useReason] = await Promise.all([
    getFacility(facilityId, targetDate),
    getUser(),
    getUseReason()
  ])

  const childResponse = await Promise.all(
    childIds.map(async (childId) => {
      const childData = await executeGetChild(childId);
      return { childId, ...childData };
    })
  )

  // 各 childResponse の result を取得
  const convertedChildren = childResponse.map(response => {
    const childWithUndefinedProps = response.result
    return { ...childWithUndefinedProps, childId: response.childId }
  })

  return {
    facility,
    user,
    children: convertedChildren,
    useReason,
  }
}

const setChildInfo = (childrenData: GetChildDto[], reserveEntry: FacilityReserveEntry, projectMst: GetProjectDetailDto): ChildReservationInfoType[] => {
  return childrenData.map((child) => {
    const prevChildInfo = reserveEntry.childInfoList.find((childInfo) => childInfo.childId === child.childId);
    
    const returnChildInfo: ChildReservationInfoType = {
      childId: child.childId,
      childInfo: {
        name: child.name,
        kana: child.kana,
        birthday: dateToNumber(child.birthday),
        gender: child.gender,
      }
    }
    
    // 医師連絡票
    if ( projectMst.medicalDocUsage !== MEDICAL_DOC_USAGE.NO_USE ) {
      returnChildInfo.medicalDoc = {
        docs: [],
        hasSubmitted: Flag.OFF,
        ... prevChildInfo?.medicalDoc
      }
    }
    // 症状
    if ( projectMst.symptomsInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.symptom = {
        symptoms: [],
        other: '',
        ... prevChildInfo?.symptom
      }
    }
    // 病名
    if ( projectMst.diagonosisInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.diagonosis = {
        first: {
          select: null,
          other: ''
        },
        second: {
          select: null,
          other: ''
        },
        ... prevChildInfo?.diagonosis
      }
    }
    // 流行り病
    if ( projectMst.epidemicInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.epidemic = {
        select: '',
        other: '',
        ... prevChildInfo?.epidemic
      }
    }
    // 服用薬
    if ( projectMst.medicineInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.medicine = {
        rx: Flag.OFF,
        otc: Flag.OFF,
        ... prevChildInfo?.medicine
      }
    }
    // 体温
    if ( projectMst.bodyTemperatureInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.temperature = {
        integer: undefined,
        fractional: undefined,
        ... prevChildInfo?.temperature
      }
    }
    // 熱けいれん
    if ( projectMst.febrileSeizuresInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.febrileSeizures = prevChildInfo?.febrileSeizures?? Flag.OFF
    }
    // 容態変化の対応
    if ( projectMst.emergencyActionInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.emergencyAction = {
        action: {
          select: null
        },
        note: '',
        ... prevChildInfo?.emergencyAction
      }
    }
    // 身長
    if ( projectMst.heightInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.height = {
        integer: undefined,
        fractional: undefined,
        ... prevChildInfo?.height
      }
    }
    // 体重
    if ( projectMst.weightInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.weight = {
        integer: undefined,
        fractional: undefined,
        ... prevChildInfo?.weight
      }
    }
    // 栄養方法
    if ( projectMst.eatingMethodInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.eatingMethod = {
        method: {
          select: null
        },
        detail: {
          select: null
        },
        milkAmount: {
          integer: undefined,
          fractional: undefined,
        },
        milkFrequency: undefined,
        ... prevChildInfo?.eatingMethod
      }
    }
    // アレルギー
    if ( projectMst.allergyInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.allergy = {
        flag: Flag.OFF,
        foodFlag: Flag.OFF,
        ... prevChildInfo?.allergy
      }
    }
    // 食事状況
    if ( projectMst.eatingAbilityInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.eatingAbility = {
        select: prevChildInfo?.eatingAbility?.select?? null
      }
    }
    // 行動
    if ( projectMst.actionInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.actions = {
        action: {
          select: null
        },
        sleepMethod: '',
        ... prevChildInfo?.actions
      }
    }
    // 排便
    if ( projectMst.toiletAbilityInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.toiletAbility = {
        select: prevChildInfo?.toiletAbility?.select?? null
      }
    }
    // 減免
    if ( projectMst.reductionInput !== INPUT_FIELD_USAGE.NO_USE ) {
      returnChildInfo.reductionType = {
        select: prevChildInfo?.reductionType?.select?? null
      }
    }
    
    return returnChildInfo;
  })
}
