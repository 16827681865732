import { Control } from 'react-hook-form';
import { Inputs } from '../../../../containers/facilityReservationForm/facilityReservationFormService';
import { PostDecodeMstDto } from '../../../../dataAccess/webApi/dto/decodeMstDto';
import SelectBoxField from "./common/selectBoxField"
import { GItem } from "../../../components/common/grids"

export interface ReductionTypeFieldProps {
    childNum: number
    required: boolean
    options?: PostDecodeMstDto[]
    note: string | null
    control: Control<Inputs>
}

const ReductionTypeField = (props: ReductionTypeFieldProps) => {
    const { childNum, required, options, note, control } = props;
    
    const name = `children.${childNum}.reductionType`
    
    return (
        <SelectBoxField 
            title={"facilityReservationForm.form.label.reduction"}
            name={name}
            selectLabel={"facilityReservationForm.form.label.reduction"}
            required={required}
            control={control}
            options={options}
            addUnselected
        >
            <GItem xs={12}　sx={{ whiteSpace: 'pre-line' }}>
                {note}
            </GItem>
        </SelectBoxField>
    )
}

export default ReductionTypeField;

