import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { initializeI18n } from '../../i18n'
import { errorUrls, loginUrl, receiptViewUrl } from '../common/constant/appUrl'
import { httpStatusCode } from '../common/constant/classification'
import { useOperationLog } from '../common/operationLog'
import { backupWindowId, restoreWindowId } from '../common/sessionStore'
import { setPxPerVh, updateHistoryStackLength } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'
import { Hub } from 'aws-amplify'
import { useControl } from '../common/useControl'

const getPxPerVh = () => window.innerHeight / 100

export const useAction = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isInitializedLoggedIn, setIsInitializedLoggedIn] = useState(false)
  const loginRouteMatch = useRouteMatch({
    path: loginUrl.routerPath,
    exact: true,
  })
  const underMaintenanceRouteMatch = useRouteMatch({
    path: errorUrls[httpStatusCode.serviceUnavailable].routerPath,
    exact: true,
  })
  const receiptViewRouteMatch = useRouteMatch({
    path: receiptViewUrl.routerPath,
    exact: true,
  })

  const { flushOperationLog } = useOperationLog()
  const { getControl } = useControl({
    shouldSetTitle: true,
  })

  useEffect(() => {
    return Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'cognitoHostedUI') {
        await dispatch(detectLoginStatus())
      }
    })
  }, [])
  useEffect(() => {
    return history.listen((location, action) => {
      dispatch(updateHistoryStackLength(action))
    })
  }, [])
  useEffect(() => {
    const onResize = () => dispatch(setPxPerVh(getPxPerVh()))
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  useEffect(() => {
    const initializeLoggedIn = async () => {
      await Promise.all([
        (async () => {
          await initializeI18n()
        })(),
        dispatch(detectLoginStatus()),
        getControl(),
      ])

      setIsInitializedLoggedIn(true)
    }
    initializeLoggedIn()
  }, [dispatch, getControl])

  useEffect(() => {
    const onBeforeunload = () => {
      flushOperationLog()
    }
    window.addEventListener('beforeunload', onBeforeunload)
    return () => window.removeEventListener('beforeunload', onBeforeunload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flushOperationLog])

  useEffect(() => {
    const onUnload = () => backupWindowId()
    window.addEventListener('unload', onUnload)
    return () => window.removeEventListener('unload', onUnload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onDOMContentLoaded = () => restoreWindowId()
    window.addEventListener('DOMContentLoaded', onDOMContentLoaded)
    return () => window.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isInitializedLoggedIn,
    loginRouteMatch,
    receiptViewRouteMatch,
    underMaintenanceRouteMatch,
  }
}
