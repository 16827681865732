import { executePost } from '../common/apiCaller'
import { PostUsageFeeSettingDto, PostUsageFeeSettingInputDto } from '../dto/usageFeeSettingDto'

const apiName = 'public'
const apiPath = '/usage-fee-setting'

/**
 * 施設詳細画面での利用料金設定取得
 */
export const executePostUsageFeeSetting = (input: PostUsageFeeSettingInputDto) => {
  return executePost<PostUsageFeeSettingDto>(apiName, apiPath, input)
}
