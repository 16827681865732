import { Stack, styled } from '@mui/material'
import { getCancelLimitHolidayOptionLabel } from '../../containers/common/codeMaster'
import { reservationStatus } from '../../containers/common/constant/classification'
import { couponMaxUsageMinuteDefault } from '../../containers/common/constant/couponSetting'
import { useAction } from '../../containers/facilityReservationDetail/facilityReservationDetailService'
import { translate } from '../../i18n'
import { formatCancelLimit } from '../../utils/itemFormatUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { AlertCard } from '../components/common/alertCard'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { FlexboxCenter } from '../components/common/flexboxes'
import { GContainer, GItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import ReservationDetailGItems from '../components/common/reservationDetail/reservationDetailGItems'
import { SubTitle } from '../components/common/subTitle'
import { TitleLabel } from '../components/common/titleLabel'
import { CouponUsageDialog } from '../components/facilityReservationDetail/couponUsageDialog'

const AnnotationBox = styled('div')({
  whiteSpace: 'pre-wrap',
})
const CouponBox = styled('div')(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.error.main,
}))
const UsageTimeBox = styled(FlexboxCenter)({
  marginTop: 8 * 2,
})

export const FacilityReservationDetail = () => {
  const {
    reservationDetail,
    reservationUsageMinute,
    isCanUseCoupon,
    isUsedCoupon,
    goCancel,
    goChange,
    couponTotalBalanceMinute,
    isOpenCouponUsageDialog,
    openCouponUsageDialog,
    onCloseCouponUsageDialog,
    decisionCouponUsage,
  } = useAction()

  if (reservationDetail == null) {
    return null
  }
  const { cancelLimit, cancelLimitTime, cancelNote, cancelLimitHolidayOption } = reservationDetail
  const alertCardConents = []
  if (cancelLimit != null && cancelLimitTime != null) {
    alertCardConents.push(
      <div key="cancelLimit">
        {translate('facilityReservationDetail.card.cancelLimit')}：{formatCancelLimit(cancelLimit, cancelLimitTime)}
      </div>
    )
    if (cancelLimitHolidayOption) {
      alertCardConents.push(
        <div key="holidayOption">※{getCancelLimitHolidayOptionLabel(cancelLimitHolidayOption)}</div>
      )
    }
  }
  if (cancelNote) {
    if (alertCardConents.length) {
      alertCardConents.push(<div key="cancelNoteSep">&nbsp;</div>)
    }
    alertCardConents.push(<AnnotationBox key="cancelNote">{cancelNote}</AnnotationBox>)
  }

  return (
    <Stack spacing={2}>
      <GContainer rowSpacing={2}>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Stack spacing={1}>
            {(isCanUseCoupon || isUsedCoupon) && (
              <SubTitle title={translate('facilityReservationDetail.title.couponUse')} />
            )}

            {reservationDetail.status !== reservationStatus.rejected &&
              reservationDetail.status !== reservationStatus.canceledWait &&
              !!alertCardConents.length && <AlertCard>{alertCardConents.map((v) => v)}</AlertCard>}

            <InfoCard>
              <GContainer>
                <ReservationDetailGItems reservation={reservationDetail} useReason={reservationDetail.useReason} displayType="reference" />
              </GContainer>
            </InfoCard>

            {(isCanUseCoupon || isUsedCoupon) && (
              <CouponBox>
                {isCanUseCoupon && (
                  <FlexboxCenter>
                    <span>{'※'}</span>
                    <span>{translate('facilityReservationDetail.text.couponPresent')}</span>
                  </FlexboxCenter>
                )}
                {reservationDetail.couponUsageMinute != null && (
                  <>
                    <FlexboxCenter>
                      <TitleLabel>{translate('facilityReservationDetail.text.couponUsed')}</TitleLabel>
                    </FlexboxCenter>
                    <FlexboxCenter>
                      <TitleLabel>{translate('facilityReservationDetail.text.couponWait')}</TitleLabel>
                    </FlexboxCenter>
                    <UsageTimeBox>
                      <TitleLabel>
                        {translate(
                          'facilityReservationDetail.text.couponUsageTime',
                          formatLocaleTimeAmountHm(reservationDetail.couponUsageMinute)
                        )}
                      </TitleLabel>
                    </UsageTimeBox>
                  </>
                )}
              </CouponBox>
            )}
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        {isCanUseCoupon && (
          <ButtonOutlinedWarningL fullWidth onClick={openCouponUsageDialog}>
            {translate('facilityReservationDetail.button.use')}
          </ButtonOutlinedWarningL>
        )}

        {
          reservationDetail.canCancel
          ? reservationDetail.status === reservationStatus.wait
            ? <ButtonOutlinedWarningL fullWidth onClick={() => goCancel(reservationDetail.status)}>
                {translate('facilityReservationDetail.button.waitCancel')}
              </ButtonOutlinedWarningL>
            : <ButtonOutlinedWarningL fullWidth onClick={() => goCancel(reservationDetail.status)}>
                {translate('facilityReservationDetail.button.reservationCancel')}
              </ButtonOutlinedWarningL>
          : <></>
        }
        {reservationDetail.canUpdate && (
          <ButtonL fullWidth onClick={goChange}>
            {translate('system.button.change')}
          </ButtonL>
        )}
        <BackButton />
      </BottomButtonGroup>
      {!!couponTotalBalanceMinute && !!reservationUsageMinute && (
        <CouponUsageDialog
          isOpen={isOpenCouponUsageDialog}
          onClose={onCloseCouponUsageDialog}
          maxAmount={Math.min(
            couponTotalBalanceMinute,
            reservationUsageMinute,
            // クーポン利用入力上限時間
            reservationDetail.couponUsageInputLimitMinute ?? couponMaxUsageMinuteDefault
          )}
          decision={decisionCouponUsage}
        />
      )}
    </Stack>
  )
}
