import React from 'react'
import { Stack } from '@mui/material'
import { getReservationStatusLabel } from '../../../../containers/common/codeMaster'
import { GOING_DATETIME_DISPLAY_METHODS } from '../../../../containers/common/constant/projectMst'
import { purposeOfUse as purposeOfUseValue } from '../../../../containers/common/constant/classification'
import { formatHmtoHmOver, formatYmdWeek, formatHmOver } from '../../../../utils/dateUtil'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import { ReservationDetailGItemsProps } from "./reservationDetailGItems"
import ParentContent from "./parentContent"
import { RowSpacerGItem } from '../grids'
import { translate } from '../../../../i18n'

const BasicDetailContent = (props: ReservationDetailGItemsProps) => {
  const isShownStatusItem = props.displayType === 'delete' || props.displayType === 'reference';

  return (
    <>
      {/* 施設名 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.facilityName')}</LabelGItem>
      <ValueGItem>{props.reservation.facilityName}</ValueGItem>
      
      {isShownStatusItem && (
        <>
          {/** 利用予定日時 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.usageDate)}</div>
              <div>
                {formatHmtoHmOver(
                  { from: props.reservation.useFromDatetime, to: props.reservation.useToDatetime },
                  props.reservation.usageDate
                )}
              </div>
            </Stack>
          </ValueGItem>
          {
            props.reservation.projectMst.goingDatetimeDisplayMethods === GOING_DATETIME_DISPLAY_METHODS.USED_ALL_FACILITY && (
            <>
              {/** 登園予定時間 */}
              <LabelGItem>{translate('system.reservationDetailGItems.label.goingDatetime')}</LabelGItem>
              <ValueGItem>
                {
                  formatHmOver(
                    new Date(`${props.reservation.goingTime.goingToFacilityDate} ${props.reservation.goingTime.goingToFacilityTime}`),
                    props.reservation.usageDate
                  )
                }
              </ValueGItem>
              {/** 降園予定時間 */}
              <LabelGItem>{translate('system.reservationDetailGItems.label.goingHomeDatetime')}</LabelGItem>
              <ValueGItem>
                {
                  formatHmOver(
                    new Date(`${props.reservation.goingTime.goingHomeDate} ${props.reservation.goingTime.goingHomeTime}`),
                    props.reservation.usageDate
                  )
                }
              </ValueGItem>
            </>
              
            )
          }
          {/** 予約状態 */}
          <LabelGItem>{translate('system.reservationDetailGItems.label.status')}</LabelGItem>
          <ValueGItem>{getReservationStatusLabel(props.reservation.status)}</ValueGItem>
        </>
      )}
      <RowSpacerGItem />
      
      {/* ご利用目的 */}
      {props.reservation.useReasonCategory && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonCategory')}</LabelGItem>
          <ValueGItem>
            {
              props.reservation.useReasonCategory === purposeOfUseValue.irregular && props.useReason?.displayNameForCitizenIrregular ||
              props.reservation.useReasonCategory === purposeOfUseValue.emergency && props.useReason?.displayNameForCitizenEmg ||
              props.reservation.useReasonCategory === purposeOfUseValue.refresh && props.useReason?.displayNameForCitizenRefresh
            }
          </ValueGItem>
        </>
      )}

      {/* ご利用目的詳細：以下の三つのコンポーネントのうちいずれかが表示される（もしくは一つも表示されない） */}
      {props.reservation.useReasonDetailCategory && (
        props.reservation.useReasonCategory === purposeOfUseValue.irregular && props.useReason?.detailsIrregular && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsIrregular[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        ) || 
        props.reservation.useReasonCategory === purposeOfUseValue.emergency && props.useReason?.detailsEmg && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsEmg[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        ) || 
        props.reservation.useReasonCategory === purposeOfUseValue.refresh && props.useReason?.detailsRefresh && (
          <>
            <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonDetail')}</LabelGItem>
            <ValueGItem>
              {props.useReason?.detailsRefresh[Number(props.reservation.useReasonDetailCategory) - 1].name}
            </ValueGItem>
          </>
        )
      )}
      {/* 詳細事由の入力欄の内容を表示 */}
      {props.reservation.useReasonDetailDescription && (
        <>
          <LabelGItem/>
          <ValueGItem hideColon>
            {props.reservation.useReasonDetailDescription}
          </ValueGItem>
        </>
      )}
      {
        props.reservation.useReasonDetailCategory && <RowSpacerGItem />
      }
      
      <ParentContent { ...props.reservation.parentInfo }/>
    </>
  )
}

export default BasicDetailContent