import { useState, useCallback, useEffect } from 'react'
import { executeGetPasswordRulePublic } from '../../dataAccess/webApi/dao/passwordRulePublicDao'

interface PasswordRule {
  minimumLength: number
  requireNumbers: boolean
  requireUppercase: boolean
  requireLowercase: boolean
  requireSymbols: boolean
  temporaryPasswordValidityDays: number
}

const DEFAULT_PASSWORD_RULE = {
  minimumLength: 8,
  requireNumbers: true,
  requireUppercase: true,
  requireLowercase: true,
  requireSymbols: true,
  temporaryPasswordValidityDays: 8,
}

const getPasswordRuleMessage = (passwordRule: PasswordRule) => {
  let message = 'パスワードは'

  if (passwordRule.requireUppercase) {
    message += '大文字英字'
  }
  if (passwordRule.requireLowercase) {
    message += '、小文字英字'
  }
  if (passwordRule.requireNumbers) {
    message += '、数字'
  }
  if (passwordRule.requireSymbols) {
    message += '、記号'
  }

  // 必須条件
  const passwordRequires = [
    passwordRule.requireUppercase,
    passwordRule.requireLowercase,
    passwordRule.requireNumbers,
    passwordRule.requireSymbols,
  ]

  if (passwordRequires.every(e => !e)) {
    // 必須条件がすべて false の場合
    message += `${passwordRule.minimumLength}文字以上を入力してください。`
  } else if (passwordRequires.filter(e => e).length === 1) {
    message += `を含む${passwordRule.minimumLength}文字以上を入力してください。`
  } else {
    message += `をそれぞれ含む${passwordRule.minimumLength}文字以上を入力してください。`
  }

  return message
}

export const usePasswordValidationMessage = () => {
  const [passwordRule, setPasswordRule] = useState<PasswordRule>(DEFAULT_PASSWORD_RULE)
  
  const getPasswordRule = useCallback(async () => {
    const passwordRuleRes = await executeGetPasswordRulePublic({"ruleId": "2"})

    const _passwordRule = {
        minimumLength: passwordRuleRes.result.minimumLength,
        requireNumbers: passwordRuleRes.result.requireNumbers,
        requireUppercase: passwordRuleRes.result.requireUppercase,
        requireLowercase: passwordRuleRes.result.requireLowercase,
        requireSymbols: passwordRuleRes.result.requireSymbols,
        temporaryPasswordValidityDays: passwordRuleRes.result.temporaryPasswordValidityDays,
    };

    setPasswordRule(_passwordRule)
  }, [passwordRule])
  
  useEffect(() => {
    getPasswordRule()
  }, [])

  const validationMessage = getPasswordRuleMessage(passwordRule)
  return {
    validationMessage,
    passwordRule,
  }
}
