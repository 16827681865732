import { executePostDecodeMstByCategories } from '../../dataAccess/webApi/dao/decodeMstDao'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { DecodeMstCategory } from './constant/decodeMst'

export type GetDecodeMstByCategoriesReturn = Partial<{ [key in DecodeMstCategory]: PostDecodeMstDto[] }>
type GetDecodeMstByCategories = (categories: DecodeMstCategory[]) => Promise<GetDecodeMstByCategoriesReturn>

/**
 * 1リクエストで複数の異なるcategoryを持つdecodeMstを取得
 *
 * @param categories DecodeMstCategory[]
 * @returns GetDecodeMstByCategoriesReturn
 */
export const getDecodeMstByCategories: GetDecodeMstByCategories = async (categories) => {
  const returnDecodeMst: GetDecodeMstByCategoriesReturn = {}
  const res = await executePostDecodeMstByCategories({ categories })

  categories.forEach((category) => {
    returnDecodeMst[category] = res.result.filter((decodeMst) => decodeMst.category === category)
  })

  return returnDecodeMst
}

/**
 * 取得したdecodeMstからnameを取得
 *
 * @param {GetDecodeMstByCategoriesReturn} decodeMst
 * @param {number|string} code
 * @returns {string}
 */
export const getDecodeMstName = (
  decodeMst: GetDecodeMstByCategoriesReturn,
  category: DecodeMstCategory,
  code: number | string
): string => {
  const decode = decodeMst[category]
  if (!decode) return ''

  const findDecode = decode.find((decode) => decode.code === (typeof code === 'string' ? code : String(code)))

  if (!findDecode) return ''
  return findDecode.name
}
