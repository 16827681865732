import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, styled, Tooltip, IconButton } from '@mui/material'
import {
  accountCreateFormUrl,
  ssoUrl,
  loginUrl,
} from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/newPassword/newPasswordService'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { Link } from '../components/common/link'
import { translate } from '../../i18n'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { ButtonM } from '../components/common/buttons/buttonM'
import { ButtonOutlinedBase } from '../components/common/buttons/buttonOutlinedBase'
import { ElevatedCard } from '../components/common/elevatedCard'
import { AttentionLabel } from '../components/common/attentionLabel'
import { yesNo } from '../../containers/common/constant/classification'
import { setLoginForm, selectLoginError } from '../../containers/common/store/slices/login'
import { HelpIcon } from '../components/common/icons/helpIcon'

// メッセージコンテナ
const NoticeGContainer = styled(GItemContainer)({
  justifyContent: 'center',
  paddingBottom: 20,
})

// メインコンテンツコンテナ
const CustomDivContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// メインコンテンツのサブコンテナ
const CustomDivSubContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// パスワードヘルプアイコン
const HelpIconButton = styled(IconButton)({ fontSize: '1.8rem', padding: 0, marginLeft: '8px' })

// ログイン画面に戻るボタン
const BackToLoginButton = styled(ButtonOutlinedBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: 'solid',
}))

// SSOボタンコンテナ
const SsoButtonContainer = styled(GContainer)({
  justifyContent: 'center',
  margin: '20px 0px',
})

// SSOボタン
const SsoLoginButton = styled(ButtonM)(({ theme }) => ({
  borderRadius: '3px',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  backgroundColor: '#c8e3f3',
  color: 'black',
  "&:hover": {
    backgroundColor: '#8ecef5',
  },
}))

/**
 * ログイン画面UIコンポーネント
 */
export const NewPassword = () => {
  const { formMethods, onSubmit, ssoUseFlag, ssoUseName, validationMessage, passwordRule } = useAction()
  const error = useSelector(selectLoginError)
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <Grid>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <GContainer >
          {/* 左余白 */}
          <GItem md={3}></GItem>

          {/* メインコンテンツ */}
          <GItem xs={12} md={6}>
            <CustomDivContainer>

              {/* タイトル */}
              <ElevatedCard
                title={translate('newPassword.title.newPassword')}
                textAlign='center'
              >
                <GContainer rowSpacing={2}>
    
                  {/* エラーメッセージ表示 */}
                  {error &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <AttentionLabel>
                          {translate(error)}
                        </AttentionLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
  
                  {/* 新パスワード */}
                  <CustomDivSubContainer>
                    {translate('resetPassword.label.password')}
                    <Tooltip title={validationMessage} enterTouchDelay={0}>
                      <HelpIconButton>
                        <HelpIcon fontSize="inherit" />
                      </HelpIconButton>
                    </Tooltip>
                    <TextBox
                      name="password"
                      label={translate('resetPassword.label.password')}
                      maxLength={320}
                      textType="password"
                      placeholder={translate('resetPassword.placeholder.password')}
                      required
                      passwordValidationMsg={validationMessage}
                      passwordRule={passwordRule}
                    />
                  </CustomDivSubContainer>

                  {/* パスワード再入力 */}
                  <CustomDivSubContainer>
                    {translate('resetPassword.label.passwordConfirmation')}
                    <TextBox
                      name="passwordConfirmation"
                      label={translate('resetPassword.label.passwordConfirmation')}
                      maxLength={320}
                      textType="password"
                      placeholder={translate('resetPassword.placeholder.passwordConfirmation')}
                      required
                      passwordValidationMsg={validationMessage}
                      passwordRule={passwordRule}
                    />
                  </CustomDivSubContainer>
  
                  {/* 新パスワード送信ボタン */}
                  <CustomDivSubContainer>
                    <ButtonM fullWidth type="submit">
                      {translate('newPassword.button.submit')}
                    </ButtonM>
                  </CustomDivSubContainer>
                  
                  {/* ログインに戻るボタン */}
                  <CustomDivSubContainer>
                    <BackToLoginButton
                      fullWidth
                      onClick={() => {
                        history.push(loginUrl.url())
                      }}
                    >
                      {translate('forgotPassword.button.backToLogin')}
                    </BackToLoginButton>
                  </CustomDivSubContainer>
                </GContainer>
              </ElevatedCard>
  
              {/* SSOボタン */}
              <SsoButtonContainer>
                { ssoUseFlag === yesNo.yes && (
                  <SsoLoginButton to={`${ssoUrl.url()}?loginRedirectTo=${loginUrl.url()}`} >
                    {translate('login.button.ssoLink', ssoUseName ?? '')}
                  </SsoLoginButton>
                )}
              </SsoButtonContainer>
  
              {/* アカウント作成画面に遷移 */}
                <CustomDivSubContainer style={{textAlign: 'center'}}>
                  <Link
                    to={accountCreateFormUrl.url()}
                    onClick={() => dispatch(setLoginForm({}))}
                  >
                    {translate('login.link.accountCreate')}
                  </Link>
                </CustomDivSubContainer>
            </CustomDivContainer>
          </GItem>

          {/* 右余白 */}
          <GItem md={3}></GItem>
        </GContainer>
      </Form>
    </Grid>
  )
}
