import { useCallback, useMemo, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { translate } from '../../i18n'
import { showLoading } from '../../containers/common/store/slices/application'
import { getResidenceCategoriesAndMstData } from '../common/residenceCategoryMst'
import { GetResidenceCategoryMstDto } from '../../dataAccess/webApi/dto/residenceCategoryMstDto'
import { dateToNumber, numberPropsToDate } from '../../utils/objectUtil'
import { setFormErrors, setFormValueClearError } from '../../utils/reactUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { accountCreateConfirmationUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getAddressStringByPostalCode } from '../common/location'
import { selectAccountCreateEntry, setAccountCreate } from '../common/store/slices/accountCreate'
import { selectIsLoggedIn } from '../common/store/slices/authority'
import { getNow } from '../../utils/dateUtil'
import { usePasswordValidationMessage } from '../../containers/common/passwordRule'

interface ChildInputs {
  name: string
  kana: string
  gender: string
  birthday: Date | null
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
}
interface Inputs {
  name: string
  kana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  relationship: string
  tel: string
  email: string
  emailConfirmation: string
  password: string
  passwordConfirmation: string
  isAgreeTermsOfService: string
  isAgreePersonalInformation: string

  children: ChildInputs[]
}
type InputTypePropName = keyof Inputs

interface LocationState {
  /** 入力済み情報から復元を試みる場合true */
  isKeep: boolean
  /** sso連携時にidp側から連携された利用者情報 */ 
  ssoLinkedParams? : {
    id: string
    name: string
    kana: string
    street: string
    houseNumber: string
    city: string
    state: string
    postalCode: string
    email: string
    phone: string
  }
}

export const useAction = () => {
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const errorHandle = useErrorHandle()
  const { validationMessage, passwordRule } = usePasswordValidationMessage()
  
  const isKeep = !!history.location.state?.isKeep
  const ssoLinkedParams = history.location.state?.ssoLinkedParams

  const accountEntry = useSelector(selectAccountCreateEntry)

  const isLoggedIn = useSelector(selectIsLoggedIn)

  const childBirthdayLimitDate = getNow() // 誕生日の入力上限値
  
  const [residenceCategoryMst, setResidenceCategoryMst] = useState<GetResidenceCategoryMstDto>()
  const [residenceCategories, setResidenceCategories] = useState<{ value: string; label: string }[]>([])
  const [isDisabledAddress1, setIsDisabledAddress1] = useState(true)
  const defaultValues = useMemo(() => {
    if (isKeep && accountEntry) {
      const { children, ...others } = accountEntry
      return {
        ...others,
        children: children.map((child) => numberPropsToDate(child, ['birthday'])),
      }
    } else {
      return {
        ...(ssoLinkedParams && {
          name: ssoLinkedParams.name,
          kana: ssoLinkedParams.kana,
          address1: ssoLinkedParams.state + ssoLinkedParams.city,
          address2: ssoLinkedParams.street,
          buildingNameRoomNumber: ssoLinkedParams.houseNumber,
          email: ssoLinkedParams.email,
          ...(ssoLinkedParams.postalCode && { postalCode: convertPostalCode(ssoLinkedParams.postalCode) }),
          ...(ssoLinkedParams.phone && { tel: convertTel(ssoLinkedParams.phone) }),
        }),
        children: [{}],
      }
    }
  }, [isKeep, accountEntry, ssoLinkedParams])

  const formMethods = useForm<Inputs>({
    defaultValues,
  })
  const fieldArrayMethods = useFieldArray({
    name: 'children',
    control: formMethods.control,
  })

  const appendChild = useCallback(() => {
    fieldArrayMethods.append({})
  }, [fieldArrayMethods])

  const removeChild = useCallback(
    (index: number) => {
      fieldArrayMethods.remove(index)
    },
    [fieldArrayMethods]
  )

  const setResidenceCategory = useCallback((address1: string, residenceCategoryMst: GetResidenceCategoryMstDto | undefined) => {
    if (
      residenceCategoryMst && residenceCategoryMst?.triggerKeywordSetInitialValue
      && address1.includes(residenceCategoryMst.triggerKeywordSetInitialValue)
    ) {
      setFormValueClearError(formMethods, 'residenceCategory', residenceCategoryMst.initialValue)
    }
  }, [formMethods])
  
  const autoCompleteAddress = useCallback(async () => {
    const { postalCode } = formMethods.getValues()
    try {
      const address = await getAddressStringByPostalCode(postalCode)
      if (address) {
        formMethods.setValue('address1', address)
        setResidenceCategory(address, residenceCategoryMst)
        setIsDisabledAddress1(true)
      } else {
        setIsDisabledAddress1(false)
      }
    } catch {
      // 取得できなかった場合は何もしない
    }
  }, [formMethods, residenceCategoryMst])
  
  useEffect(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          const residenceCategoriesAndMstData = await getResidenceCategoriesAndMstData()
          setResidenceCategoryMst(residenceCategoriesAndMstData.mstData)
          setResidenceCategories(residenceCategoriesAndMstData.categories)
          
          if (!isKeep && ssoLinkedParams) {
            const { address1 } = formMethods.getValues()
            if (address1) {
              setResidenceCategory(address1, residenceCategoriesAndMstData.mstData)
            }
          }
          
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const subscription = formMethods.watch((values, { name, type }) => {
      if (name === 'address1' && type === 'change') {
        if (values.address1) {
          setResidenceCategory(values.address1, residenceCategoryMst)
        }
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residenceCategoryMst])

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []

      if (data.email !== data.emailConfirmation) {
        errors.push({
          name: 'emailConfirmation',
          message: translate('system.error.notSameEmail'),
        })
      }

      if (data.password !== data.passwordConfirmation) {
        errors.push({
          name: 'passwordConfirmation',
          message: translate('system.error.notSamePassword'),
        })
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  const onSubmit = useCallback(
    (data: Inputs) => {
      if (validateItems(data)) {
        return
      }

      const { children, ...others } = data

      const matchValueLabels = residenceCategories.filter((element) => {
        return data.residenceCategory === element.value
      })
      const residenceCategoryName = matchValueLabels[0].label

      dispatch(
        setAccountCreate({
          ...others,
          residenceCategoryName,
          ...(ssoLinkedParams && {
            ssoLinkedParams : {
              id: ssoLinkedParams.id,
              email: ssoLinkedParams.email,
            }
          }),
          children: children.map((child) => {
            const { birthday, ...childOthers } = child
            return {
              ...childOthers,
              birthday: dateToNumber(castNonNullable(birthday)),
            }
          }),
        })
      )
      
      // 戻るで表示した際に入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true, ssoLinkedParams } })
      history.push(accountCreateConfirmationUrl.url())
    },
    [validateItems, dispatch, history, ssoLinkedParams, residenceCategories]
  )
  
  return {
    isLoggedIn,
    formMethods,
    fieldArrayMethods,
    appendChild,
    removeChild,
    autoCompleteAddress,
    onSubmit,
    childBirthdayLimitDate,
    residenceCategories,
    isDisabledAddress1,
    residenceCategoryMst,
    validationMessage,
    passwordRule,
  }
}

const convertPostalCode = (data: string) => {
  return data.substr(0, 3) + '-' + data.substr(3)
}

const convertTel = (data: string) => {
  return data.substr(0, 3) + '-' + data.substr(3, 4) + '-' + data.substr(7)
}