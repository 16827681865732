import { Control  } from "react-hook-form"
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import DecimalTypeField from "./common/decimalTypeField"

export interface HeightFieldProps {
    childNum: number
    required: boolean
    control: Control<Inputs>
}

const HeightField = (props: HeightFieldProps) => {
    const { childNum, required, control } = props

    return (
        <DecimalTypeField 
            name={`children.${childNum}.height`}
            title={"facilityReservationForm.form.label.height.field"}
            intLabel={"facilityReservationForm.form.label.height.int"}
            fracLabel={"facilityReservationForm.form.label.height.frac"}
            unit={"cm"}
            required={required}
            control={control}
            intMax={200}
            intMin={1}
            fracMac={99}
            fracMin={0}
        />
    )

}

export default HeightField;
