import { Control  } from "react-hook-form"
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import DecimalTypeField from "./common/decimalTypeField"

export interface WeightFieldProps {
    childNum: number
    required: boolean
    control: Control<Inputs>
}

const WeightField = (props: WeightFieldProps) => {
    const { childNum, required, control } = props

    return (
        <DecimalTypeField 
            name={`children.${childNum}.weight`}
            title={"facilityReservationForm.form.label.weight.field"}
            intLabel={"facilityReservationForm.form.label.weight.int"}
            fracLabel={"facilityReservationForm.form.label.weight.frac"}
            unit={"kg"}
            required={required}
            control={control}
            intMax={200}
            intMin={1}
            fracMac={99}
            fracMin={0}
        />
    )

}

export default WeightField;
