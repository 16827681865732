import { useCallback } from 'react'
import { Fee, GenerateUsageFeesByTimeBasedFees, UsageFee } from './type'
import { LOCATION_STATUS } from '../common/constant/commonConstant'
import { formatNumberYenString } from '../../utils/stringUtil'
import { GRID_SIZE } from './enum'
import { TimeBasedFee } from '../../dataAccess/webApi/dto/usageFeeSettingDto'
import { useGenerateUsageFeesCommon } from './useGenerateUsageFeesCommon'

type UseGenerateUsageFeesByTimeBasedFeesReturn = {
  generateUsageFeesByTimeBasedFees: GenerateUsageFeesByTimeBasedFees
}
type UseGenerateUsageFeesByTimeBasedFeesArg = {}
type UseGenerateUsageFeesByTimeBasedFees = (
  arg: UseGenerateUsageFeesByTimeBasedFeesArg
) => UseGenerateUsageFeesByTimeBasedFeesReturn

export const useGenerateUsageFeesByTimeBasedFees: UseGenerateUsageFeesByTimeBasedFees = ({}) => {
  const { getCommonLunchSnackFee, getOtherFeeSettingsForCommonLocation, getFeeTimeSettings, getOtherFeeSettings } =
    useGenerateUsageFeesCommon({})

  // NOTE: 時間範囲のメッセージ群を返す
  const formatRanges = useCallback((timeBasedFees: TimeBasedFee[]) => {
    const result: Record<number, string> = {}

    timeBasedFees.forEach((timeBasedFee, index) => {
      const { id, applicableFeeStartTime, applicableFeeEndTime } = timeBasedFee

      if (index === 0) {
        result[id] = `${applicableFeeEndTime}時間以内`
      } else if (index === timeBasedFees.length - 1) {
        result[id] = `${applicableFeeStartTime}時間を超える`
      } else {
        result[id] = `${applicableFeeStartTime}～${applicableFeeEndTime}時間`
      }
    })

    return result
  }, [])

  /**
   * NOTE: 時間別料金を元に利用料金設定を生成
   */
  const generateUsageFeesByTimeBasedFees = useCallback<GenerateUsageFeesByTimeBasedFees>(
    ({ ageCategories, timeBasedFees, feeTimeSettings, otherFeeSettings, locationStatus, decodeMst }) => {
      const usageFees: UsageFee[] = []
      const commonFees: Fee[] = []
      const hasTimeBasedFees = timeBasedFees.length > 0
      const hasFeeTimeSettings = feeTimeSettings.length > 0
      const hasOtherFeeSettings = otherFeeSettings.length > 0

      /**
       * NOTE: 一時的にステップ3~5の各項目が「表示しない」となっている場合（利用料金案内が先頭にくる場合）のみを前提で実装
       * @link [HOIKU-2077](https://bluedsk.atlassian.net/browse/HOIKU-2077)
       */
      if (!hasTimeBasedFees && !hasFeeTimeSettings && !hasOtherFeeSettings) return usageFees

      // NOTE: 適用地域が「共通」のみの利用料金設定
      const { existsFeesFilteredCommonLocation, lunchFee, snackFee, isSameLunchFeeAll, isSameSnackFeeAll } =
        getCommonLunchSnackFee(
          timeBasedFees.map((timeBasedFee) => ({
            locationStatus: timeBasedFee.locationStatus,
            lunchFee: timeBasedFee.lunchFee,
            lunchFeeDisplayFlag: timeBasedFee.lunchFeeDisplayFlag,
            snackFee: timeBasedFee.snackFee,
            snackFeeDisplayFlag: timeBasedFee.snackFeeDisplayFlag,
          }))
        )

      // NOTE: 適用地域に「共通」が設定されている料金設定がある場合
      if (existsFeesFilteredCommonLocation) {
        // NOTE: 共通の給食代
        if (lunchFee >= 0 && isSameLunchFeeAll)
          commonFees.push({
            text: ['給食代', formatNumberYenString(lunchFee)],
            gridSize: GRID_SIZE.COMMON,
          })

        // NOTE: 共通のおやつ代
        if (snackFee >= 0 && isSameSnackFeeAll)
          commonFees.push({
            text: ['おやつ代', formatNumberYenString(snackFee)],
            gridSize: GRID_SIZE.COMMON,
          })
      }

      commonFees.push(...getOtherFeeSettingsForCommonLocation({ otherFeeSettings }))

      // NOTE: 各年齢の料金表示
      ageCategories.forEach((ageCategory) => {
        const ageCategoryId = ageCategory.id

        for (const location of locationStatus) {
          const fees: Fee[] = []
          const title = ageCategory.name + (location.id !== LOCATION_STATUS.COMMON ? `（${location.name}）` : '')

          const filteredTimeBasedFees = timeBasedFees
            .filter(
              (timeBasedFee) =>
                timeBasedFee.locationStatus === location.id && timeBasedFee.ageCategoryId === ageCategoryId
            )
            .sort((a, b) => a.applicableFeeStartTime - b.applicableFeeStartTime)
          // NOTE: 設定されていない適用地域は処理を行わない
          if (filteredTimeBasedFees.length === 0) continue

          // NOTE: 利用料金設定
          // const thresholds = generateThresholds(filteredTimeBasedFees)

          const ranges = formatRanges(filteredTimeBasedFees)
          filteredTimeBasedFees.forEach((timeBasedFee) =>
            fees.push({
              text: [ranges[timeBasedFee.id], formatNumberYenString(timeBasedFee.applicableFee)],
              gridSize: GRID_SIZE.NORMAL,
            })
          )

          /**
           * NOTE: 給食代
           *       適用地域が「共通」の場合、全給食代不一致で表示
           */
          if (
            (location.id === LOCATION_STATUS.COMMON && !isSameLunchFeeAll) ||
            (location.id !== LOCATION_STATUS.COMMON && !!filteredTimeBasedFees[0].lunchFeeDisplayFlag)
          )
            fees.push({
              text: ['給食代', formatNumberYenString(filteredTimeBasedFees[0].lunchFee)],
              gridSize: GRID_SIZE.NORMAL,
            })

          /**
           * NOTE: おやつ代
           *       適用地域が「共通」の場合、全おやつ代不一致で表示
           */
          if (
            (location.id === LOCATION_STATUS.COMMON && !isSameSnackFeeAll) ||
            (location.id !== LOCATION_STATUS.COMMON && !!filteredTimeBasedFees[0].snackFeeDisplayFlag)
          )
            fees.push({
              text: ['おやつ代', formatNumberYenString(filteredTimeBasedFees[0].snackFee)],
              gridSize: GRID_SIZE.NORMAL,
            })

          // NOTE: 時間外料金
          fees.push(
            ...getFeeTimeSettings({
              feeTimeSettings,
              decodeMst,
              ageCategoryId,
              location: location.id,
            })
          )

          // NOTE: その他料金
          fees.push(...getOtherFeeSettings({ otherFeeSettings, location: location.id }))

          if (fees.length) {
            usageFees.push({
              title,
              fees,
            })
          }
        }
      })

      usageFees.push({
        fees: commonFees,
      })

      return usageFees
    },
    [
      formatRanges,
      getCommonLunchSnackFee,
      getFeeTimeSettings,
      getOtherFeeSettings,
      getOtherFeeSettingsForCommonLocation,
    ]
  )

  return { generateUsageFeesByTimeBasedFees }
}
