import React from 'react';
import YesNoField from './common/yesNoField'

const LunchField = () => {
    return (
        <YesNoField 
            name={"lunchFlag"}
            required
            title={"facilityReservationForm.form.label.lunch"}
            label={"facilityReservationForm.form.label.lunch"}
        />
    );
}

export default LunchField;