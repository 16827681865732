import { differenceInMinutes } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  interviewStatus,
  reservableUnitPatterMinutes,
  yesNo,
  permitFlag,
  interviewCrossManagementFlag
} from '../../containers/common/constant/classification'
import {
  InterviewReserveEntry,
  selectInterviewReserveEntry,
  setInterviewReserve,
} from '../../containers/common/store/slices/interviewReserve'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { executeGetInterviewReservationsDays } from '../../dataAccess/webApi/dao/interviewReservationsDaysDao'
import { executeGetInterviewReservationsWeekCitizen } from '../../dataAccess/webApi/dao/interviewReservationsWeekCitizenDao'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetInterviewReservationsDaysDto } from '../../dataAccess/webApi/dto/interviewReservationsDaysDto'
import { GetInterviewReservationsWeekCitizenDto } from '../../dataAccess/webApi/dto/interviewReservationsWeekCitizenDto'
import { translate } from '../../i18n'
import { DateRange, ElapsedMillisecond, getNowTrimedTime, isValidDate, toApiYmd } from '../../utils/dateUtil'
import { promiseAllConcurrency } from '../../utils/promiseUtil'
import { fromNumber } from '../../utils/stringUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { interviewReservationFormUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getInterview, Interview } from '../common/interview'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { useOperationLog } from '../common/operationLog'
import {
  ChildrenChoice,
  Child,
  getChild,
  getChildrenChoices,
  getInterviewedChildrenChoices,
  InterviewedChildrenChoice,
} from '../common/child'
import { getInterviewStatusLabel } from '../common/codeMaster'
import { OperationId } from '../common/constant/operationLog'

interface InsertUrlParams {
  facilityId: string
}
interface UpdateUrlParams {
  interviewNo: string
}
type UrlParams = InsertUrlParams | UpdateUrlParams

interface Inputs {
  /** 面談希望日時 */
  interviewDesiredDatetimes: {
    value: {
      /** 時間の基準となる日付 */
      baseDateOfTime: Date
      /** 日時範囲 */
      range: DateRange
      /** 空き状況ステータス */
      status: string
    }
  }[]
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep?: boolean
  activeDate: ElapsedMillisecond
  childIds: string[]
  childrenName: string
}

interface PageState {
  activeChildId?: string
  activeDate: Date
  facilityId?: string
  uninterviewedChilds: ChildrenChoice[]
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]

  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  /** 変更前予約情報 */
  interview?: Interview
  /** 空き状況（１週間分） */
  availabilities?: GetInterviewReservationsWeekCitizenDto[]
  /** 空き状況（選択した利用日分） */
  initialValidationAvailabilities?: GetInterviewReservationsDaysDto[]
}

interface ChildrenState {
  childIds: string[]
  childrenName: string
}

/** 最大並列実行数 */
const MAX_WORKER_COUNT = 4

const isInsertParams = (urlParams: UrlParams): urlParams is InsertUrlParams => {
  return 'facilityId' in urlParams
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const urlParams = useParams<UrlParams>()
  const sysCtrl = useSelector(selectSystemControl)
  const { addOperationLog, attachAccessData } = useOperationLog()

  const locationState = history.location.state
  const reserveEntry = useSelector(selectInterviewReserveEntry)

  const [isOpenInterviewChildSelect, setIsOpenInterviewChildSelect] = useState(false)
  const [childrenInfo, setChildrenInfo] = useState<ChildrenState>({
    ...(locationState?.isKeep
      ? {
          childIds: locationState.childIds,
          childrenName: locationState.childrenName,
        }
      : {
          childIds: [],
          childrenName: '',
        }),
  })
  const [state, setState] = useState<PageState>({
    ...(locationState?.isKeep
      ? {
          activeDate: new Date(locationState.activeDate),
          uninterviewedChilds: [], childs: [], interviewedChildsAndStatus: []
        }
      : {
          activeDate: locationState?.activeDate ? new Date(locationState.activeDate) : getNowTrimedTime(),
          uninterviewedChilds: [], childs: [], interviewedChildsAndStatus: []
        }),
  })

  /** 変更前の日時 */
  const previousRange = useMemo(() => {
    if (state.interview
          && state.interview.interviewDate
          && state.interview.interviewStartDatetime
          && state.interview.interviewEndDatetime) {
      return {
        range: {
          from: state.interview.interviewStartDatetime,
          to: state.interview.interviewEndDatetime,
        },
        baseDateOfTime: state.interview.interviewDate,
        isWaiting: state.interview.status === interviewStatus.wait,
      }
    }
  }, [state.interview])

  const notifyDateFetchErrorMessage = useCallback(
    () => dispatch(notifyMessage(translate('facilityReservationSelection.error.noReservationReceptionSetting'))),
    []
  )

  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(locationState?.isKeep &&
        reserveEntry && reserveEntry.interviewDatetimes && {
          interviewDesiredDatetimes: reserveEntry.interviewDatetimes.map((v) => ({
            value: {
              /** 時間の基準となる日付 */
              baseDateOfTime: new Date(v.interviewDate),
              /** 日時範囲 */
              range: { from: new Date(v.interviewFromDatetime), to: new Date(v.interviewToDatetime) },
              /** 空き状況ステータス */
              status: v.status,
            },
          })),
        }),
    },
  })

  /**
   * 初期表示の情報取得
   */
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          if (isInsertParams(urlParams)) {
            // 新規予約時
            // TODO：動作確認用
            const searchChildId = '01'
            //const searchChildId = state.activeChildId ?? urlParams.childId
            attachAccessData({
              accessData: [{ userIdRegFlag: yesNo.yes, childId: searchChildId }],
            })

            const initialSelectedUsageApiYmds = getInitialSelectedUsageApiYmds(locationState?.isKeep, reserveEntry)
            const [interviewAvailabilities, initialValidationAvailabilities] = await Promise.all([
              getInterviewAvailabilities(urlParams.facilityId, state.activeDate, null),
              getAvailabilitiesByDays(urlParams.facilityId, initialSelectedUsageApiYmds, null),
            ])
            
            if (interviewAvailabilities) {
              /** 正常 */
              if (!(locationState?.isKeep && reserveEntry)) {
                // 初回：お子さま選択ダイアログの情報を取得＆表示
                const facilityWithChilds = await getFacilityWithChilds(urlParams.facilityId)
                setState({ ...interviewAvailabilities, initialValidationAvailabilities, ...facilityWithChilds })
                setIsOpenInterviewChildSelect(true)
              } else {
                // 次画面からの戻り
                setState({
                  ...interviewAvailabilities,
                  initialValidationAvailabilities,
                  uninterviewedChilds: [],
                  childs: [],
                  interviewedChildsAndStatus: [],
                })
              }
              if (initialValidationAvailabilities?.length) {
                // 初期表示時バリデーション実行
                formMethods.trigger(undefined, { shouldFocus: true })
              }
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          } else {
            // 予約変更時
            const interview = await getInterview(urlParams.interviewNo)
            attachAccessData({
              accessData: [
                {
                  userIdRegFlag: yesNo.yes,
                  usageDate: toApiYmd(interview.interviewDate),
                  interviewNo: interview.interviewNo,
                },
              ],
            })

            const searchDate = locationState?.isKeep ? state.activeDate : interview.interviewDate
            if (!searchDate) {
              /** 異常 */
              notifyDateFetchErrorMessage()
              return
            }

            const initialSelectedUsageApiYmds = getInitialSelectedUsageApiYmds(locationState?.isKeep, reserveEntry)
            const availabilitiesInterviewNo = getAvailabilitiesInterviewNo(interview)
            
            const [interviewAvailabilities, initialValidationAvailabilities, child] = await Promise.all([
              getInterviewAvailabilities(
                interview.facilityId,
                searchDate,
                availabilitiesInterviewNo
              ),
              getAvailabilitiesByDays(
                interview.facilityId,
                initialSelectedUsageApiYmds,
                availabilitiesInterviewNo
              ),
              interview.childId && getChild(interview.childId)
            ])

            if (interviewAvailabilities) {
              /** 正常 */
              if (!locationState?.isKeep) {
                // 戻る以外の初期表示時のみ変更前範囲を初期選択
                formMethods.reset({
                  interviewDesiredDatetimes: [
                    {
                      value: {
                        /** 時間の基準となる日付 */
                        baseDateOfTime: interview.interviewDate,
                        /** 日時範囲 */
                        range: {
                          from: interview.interviewStartDatetime,
                          to: interview.interviewEndDatetime,
                        },
                        // 空き状況ステータスは、空き状況カレンダーコントロールの初期設定に任せる
                      },
                    },
                  ],
                })
              }

              if (child) {
                setChildrenInfo({
                  childIds: [child.childId],
                  childrenName: child.name
                })
              }
              setState({
                ...interviewAvailabilities,
                initialValidationAvailabilities,
                interview: interview,
                uninterviewedChilds: [],
                childs: [],
                interviewedChildsAndStatus: []
              })
              if (initialValidationAvailabilities?.length) {
                // 初期表示時バリデーション実行
                formMethods.trigger(undefined, { shouldFocus: true })
              }
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * お子さま選択ダイアログ決定処理
   */
  const getChildrenInfo = useCallback(
    (childIds: string[]) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const filteredChildIds = childIds.filter(childId => childId !== '')
            const childs = await promiseAllConcurrency(
              filteredChildIds.map((childId) => async () => {
                const child = await getChild(childId)
                return child
              }),
              MAX_WORKER_COUNT
            )

            let childrenName = ''
            childs.forEach((child: Child) => {
              childrenName = childrenName ? childrenName + '、' + child.name : child.name
            })
            setChildrenInfo({
              childIds: filteredChildIds,
              childrenName: childrenName
            })
          }),
          isHiddenMain: false,
        })
      )
  },[])

  // お子さま選択ダイアログのボタン押下
  const onCloseInterviewChildSelect = useCallback(
    (isCancel: boolean, childIds?: string[]) => {
      if (isCancel || !childIds) {
        // 選択をキャンセルした場合
        setIsOpenInterviewChildSelect(false)
        history.goBack()
      } else {
        // お子さまを選択した場合
        const filteredChildIds = childIds.filter(childId => childId !== '')
        if (filteredChildIds.length <= 0) {
          return
        }
        setIsOpenInterviewChildSelect(false)
        // お子さま情報設定
        getChildrenInfo(childIds)
      }
    },
    [history, getChildrenInfo]
  )

  const onNextPrevExec = useCallback(
    (baseDate: Date) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const facilityInterviewAvailabilities = await getFacilityInterviewAvailabilities(
              castNonNullable(state.facilityId),
              baseDate,
              getAvailabilitiesInterviewNo(state.interview)
            )
            if (facilityInterviewAvailabilities) {
              /** 正常 */
              setState((old) => ({ ...old, ...facilityInterviewAvailabilities }))
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [state.facilityId, state.activeChildId, state.interview]
  )

  const changeDate = useCallback(
    (date: Date | null) => {
      addOperationLog({ operationId: OperationId.OP_00000029 })

      if (isValidDate(date)) {
        onNextPrevExec(date)
      }
    },
    [onNextPrevExec, addOperationLog]
  )

  /**
   * 妥当性検査
   * @param data 入力値
   * @param facilityId 施設ID
   * @returns エラーが存在する場合true
   */
  const validate = useCallback(async (data: Inputs, facilityId: string) => {
    const facilityMap = await getFacilityMapByInterviewDateElapsed(
      facilityId,
      new Set(data.interviewDesiredDatetimes.map((v) => v.value.baseDateOfTime.getTime()))
    )

    let isError = false
    data.interviewDesiredDatetimes.forEach(({ value: { baseDateOfTime, range } }, idx) => {
      const facility = facilityMap.get(baseDateOfTime.getTime())

      // 面談予約設定には最低予約時間の設定がないのでコメントアウトしておく
      // const minReservationMinute = facility?.minReservationMinute
      // if (minReservationMinute != null) {
      //   // 最低予約時間が設定されていれる場合
      //   const minute = differenceInMinutes(range.to, range.from)
      //   if (minute < minReservationMinute) {
      //     formMethods.setError(`interviewDesiredDatetimes.${idx}.value`, {
      //       message: translate(
      //         'facilityReservationSelection.error.minReservationMinute',
      //         fromNumber(minReservationMinute)
      //       ),
      //     })
      //     isError = true
      //   }
      // }

      const reservableUnitPatternMinute = facility && reservableUnitPatterMinutes[facility?.interviewReservableUnitPattern]
      if (reservableUnitPatternMinute != null) {
        // 予約可能単位が設定されていれる場合
        const minute = differenceInMinutes(range.to, range.from)
        if (minute % reservableUnitPatternMinute !== 0) {
          formMethods.setError(`interviewDesiredDatetimes.${idx}.value`, {
            message: translate(
              'interviewReservationSelection.error.reservableUnitPattern',
              fromNumber(reservableUnitPatternMinute)
            ),
          })
          isError = true
        }
      }
    })

    return isError
  }, [])

  /**
   * 申し込みボタン押下
   */
  const onSubmit = useCallback(
    async (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000030 })

      const facilityId = castNonNullable(state.facilityId)

      let isError = false
      await dispatch(
        showLoading({
          process: 
          errorHandle(async () => {
            // 妥当性検査
            const isValidateError = await validate(data, facilityId)
            if (isValidateError) {
              isError = true
              return
            }

            dispatch(
              setInterviewReserve({
                facilityId: castNonNullable(state.facilityId),
                childId: castNonNullable(state.activeChildId),
                childIds: childrenInfo.childIds,
                interviewNo: state.interview?.interviewNo,

                // 確認しやすくするために利用開始日でソートしておく
                interviewDatetimes: 
                  !data.interviewDesiredDatetimes ? [] : data.interviewDesiredDatetimes.map((v) => ({
                    interviewDate: v.value.baseDateOfTime.getTime(),
                    interviewFromDatetime: v.value.range.from.getTime(),
                    interviewToDatetime: v.value.range.to.getTime(),
                    status: v.value.status,
                  }))
                  .sort((a, b) => a.interviewFromDatetime - b.interviewFromDatetime),
              })
            )

            /** 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管 */
            history.replace({
              ...history.location,
              state: {
                isKeep: true,
                activeDate: state.activeDate.getTime(),
                childIds: childrenInfo.childIds,
                childrenName: childrenInfo.childrenName,
              },
            })
          }),
          isHiddenMain: false,
        })
        
      )
      if (!isError) {
        history.push(interviewReservationFormUrl.url(facilityId))
      }
    },
    [
      reserveEntry,
      state.facilityId,
      state.activeChildId,
      state.activeDate,
      state.interview,
      validate,
      addOperationLog,
      childrenInfo,
    ]
  )

  return {
    facilityId: state.facilityId,
    facility: state.facility,
    childrenName: childrenInfo.childrenName,
    activeChildId: state.activeChildId,
    activeDate: state.activeDate,
    availabilities: state.availabilities,
    initialValidationAvailabilities: state.initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    onNextPrevExec,
    changeDate,
    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    uninterviewedChilds: state.uninterviewedChilds,
    interviewedChildsAndStatus: state.interviewedChildsAndStatus,
    sysCtrl,
  }
}

const getFacilityMapByInterviewDateElapsed = async (facilityId: string, interviewDateElapsedSet: Set<ElapsedMillisecond>) => {
  const facilities = await promiseAllConcurrency(
    Array.from(interviewDateElapsedSet).map((interviewDateElapsed) => async () => {
      const facility = await getFacility(facilityId, new Date(interviewDateElapsed))
      return [interviewDateElapsed, facility] as const
    }),
    MAX_WORKER_COUNT
  )
  return new Map(facilities)
}

/**
 * 空き状況取得用の受付No取得
 * キャンセル待ち予約の変更時は受付Noを指定して空き状況を取得する為
 * @param reservation
 * @returns
 */
const getAvailabilitiesInterviewNo = (interivew: Interview | undefined) =>
  interivew?.status === interviewStatus.wait ? interivew.interviewNo : null

/**
 * 空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 空き情報
 */
const getAvailabilities = async (
  facilityId: string,
  targetDate: Date,
  interviewNo: string | null
) => {
  const availabilitiesResponse = await executeGetInterviewReservationsWeekCitizen(facilityId, {
    targetDate: toApiYmd(targetDate),
    interviewNo,
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 施設情報、空き情報
 */
const getFacilityInterviewAvailabilities = async (
  facilityId: string,
  targetDate: Date,
  interviewNo: string | null
) => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilities(facilityId, targetDate, interviewNo),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報を取得
 * @param facilityId 施設ID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 施設情報、空き情報
 */
const getInterviewAvailabilities = async (
  facilityId: string,
  targetDate: Date,
  interviewNo: string | null
) => {
  const [facilityAvailabilities] = await Promise.all([
    getFacilityInterviewAvailabilities(facilityId, targetDate, interviewNo),
  ])
  if (facilityAvailabilities) {
    return {
      ...facilityAvailabilities,
    }
  } else {
    return null
  }
}

/**
 * 初期選択の年月日を取得する
 *
 * @param isKeep 戻るで初期表示してreduxから状態を復元する際はtrue
 * @param reserveEntry 保存された状態
 * @returns 初期選択の利用日時から取得した年月日(yyyy-MM-dd)配列。※重複除去
 */
const getInitialSelectedUsageApiYmds = (isKeep?: boolean, reserveEntry?: InterviewReserveEntry) => {
  return isKeep && reserveEntry && reserveEntry.interviewDatetimes
    ? Array.from(new Set(reserveEntry.interviewDatetimes.map((v) => toApiYmd(new Date(v.interviewDate)))))
    : []
}

/**
 * 指定日分の空き状況を取得する
 *
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetApiYmds 取得対象年月日(yyyy-MM-ddの配列)
 * @param reservationNo キャンセル待ち予約変更時は対象予約No。それ以外はnull
 * @returns 空き状況
 */
const getAvailabilitiesByDays = async (
  facilityId: string,
  targetApiYmds: string[],
  interviewNo: string | null
) => {
  let result
  if (targetApiYmds.length) {
    const resp = await executeGetInterviewReservationsDays(facilityId, {
      targetDates: targetApiYmds,
      interviewNo,
    })
    result = resp.result
  }
  return result
}

/**
 * お子さま一覧取得（お子さま選択ダイアログで使用）
 */
async function getFacilityWithChilds(facilityId: string) {
  const [facility, allChilds, interviewedChilds] = await Promise.all([
    getFacility(facilityId),
    getChildrenChoices(),
    getInterviewedChildrenChoices(facilityId, yesNo.yes),
  ])

  //面談許可時の利用許可範囲
  const pmInterviewCrossManagementFlag = facility?.interviewCrossManagementFlag ?? interviewCrossManagementFlag.byFacility

  //A761にて、キャンセル済お子様にラジオボタンを表示して再登録を可能にする対応を行いました。
  //本来SQLで修正をするところですが、お子様選択一覧画面に表示されるキャンセル済お子様のラベルに
  //「（キャンセル済）」のステータスを表示する対応が今後発生する可能性があり、その場合SQLの修正は不要になってしまうため画面での対応としました。

  //interviewedChilds（面談済みお子様）から状態：キャンセル済を除外する
  let exceptForChildsWithCanceledInterview = interviewedChilds.filter((v) => v.status !== interviewStatus.canceled)
  const interviewedChildIdSet = new Set(exceptForChildsWithCanceledInterview.map(({ value }) => value))
  const uninterviewedChilds = allChilds.filter(({ value }) => !interviewedChildIdSet.has(value))
  const childs = interviewedChilds.filter((v) => v.permitFlag === permitFlag.permitted)

  //1つでも利用許可したら同一事業内の施設で利用可能とする場合の重複対応
  if (pmInterviewCrossManagementFlag === interviewCrossManagementFlag.sameBusiness) {
    //重複情報
    const valueCounts: Record<string, any> = {}
    exceptForChildsWithCanceledInterview.forEach((item: any) => {
      //カウント
      valueCounts[item.value] = (valueCounts[item.value] || 0) + 1
      //自施設が含まれるか
      if (item.facilityId === facilityId) {
        valueCounts['myFacility' + item.value] = true
      }
    })

    //重複するvalue（お子さまID）は自施設の情報を優先する
    const result: InterviewedChildrenChoice[] = []
    const doDuplicatelist: string[] = []
    exceptForChildsWithCanceledInterview.forEach((item: any) => {
      if (valueCounts[item.value] > 1) {
        //重複あり
        if (!doDuplicatelist.includes(item.value)) {
          //重複未処理
          if (valueCounts['myFacility' + item.value] && item.facilityId === facilityId) {
            //自施設が含まれる場合は自施設情報を設定
            //（例）自：確定済、他：確定済 ⇒ 自
            result.push(item)
            doDuplicatelist.push(item.value)
          } else if (!valueCounts['myFacility' + item.value]) {
            //自施設が含まれない場合は他施設情報を設定
            //（例）他A：確定済、他B：確定済 ⇒ 他（A or B どちらかでOK）
            result.push(item)
            doDuplicatelist.push(item.value)
          }
        }
      } else {
        //重複なしはそのまま
        result.push(item)
      }
    })

    //重複を除いた情報に置き換える
    exceptForChildsWithCanceledInterview = result
  }

  const interviewedChildsAndStatus = exceptForChildsWithCanceledInterview.map((v) => {
    let label = v.label
    if (v.status) {
      if (pmInterviewCrossManagementFlag === interviewCrossManagementFlag.sameBusiness && v.facilityId !== facilityId) {
        //1つでも利用許可したら同一事業内の施設で利用可能とする
        label = v.label + '（' + getInterviewStatusLabel(v.status)
                + '：' + translate('facilityReservationSelection.label.sameBusiness') + '）'
      } else {
        //上記以外（施設毎に利用許可を行う）
        label = v.label + '（' + getInterviewStatusLabel(v.status) + '）'
      }
    }
    return { value: v.value, label: label }
  })

  return {
    facility,
    uninterviewedChilds,
    childs,
    interviewedChildsAndStatus,
  }
}