import { useHistory } from 'react-router-dom'
import { useAction } from '../../containers/termsOfUse/termsOfUseService'
import { Stack, styled } from '@mui/material'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { WindowCloseButton } from '../components/common/buttons/windowCloseButton'
import { SubTitle } from '../components/common/subTitle'

const Content = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const TermsOfService = () => {
  const { termsOfService } = useAction();
  const history = useHistory()
  
  const isOpenedNewTab = history.length === 1;

  return (
    <Stack spacing={1}>
      <SubTitle title="利用規約" />
      <Content>{termsOfService}</Content>
      <BottomButtonGroup>
        {isOpenedNewTab ? <WindowCloseButton /> : <BackButton />}
      </BottomButtonGroup>
    </Stack>
  );
}