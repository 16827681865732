import React from 'react';
import { facilityReservationInputKey } from '../../../../../containers/facilityReservationForm/facilityReservationFormService';
import { getYesNos } from '../../../../../containers/common/codeMaster';
import { RadioButtonGroup } from '../../../../components/common/inputs/radioButtonGroup';
import { GItem } from '../../../../components/common/grids'
import { TitleLabel } from "../../../../components/common/titleLabel"
import { AttentionLabel } from "../../../../components/common/attentionLabel"
import { translate } from '../../../../../i18n'

interface YesNoFieldProps {
    name: facilityReservationInputKey
    required: boolean
    title: string
    label: string
    validation?: (value: any) => true | string
}

const YesNoField = (props: YesNoFieldProps) => {
    const { name, required, title, label, validation } = props;

    return (
        <GItem xs={12}>
            <div>
                <TitleLabel>
                    {
                        required && (
                            <AttentionLabel>*</AttentionLabel>
                        )
                    }
                    {translate(title)}
                </TitleLabel>
            </div>
            <RadioButtonGroup
                name={name}
                label={label}
                row
                required={required}
                buttonValueLabels={getYesNos().reverse()}
                validation={validation?? undefined}
            />
        </GItem>
    );
}

export default YesNoField;
