import React, { ReactNode, useState, useEffect } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { Inputs, facilityReservationInputKey } from "../../../../../containers/facilityReservationForm/facilityReservationFormService"
import { PostDecodeMstDto } from '../../../../../dataAccess/webApi/dto/decodeMstDto'
import { GItem, LabelGItemBase } from "../../../../components/common/grids"
import { Select } from '../../../../components/common/inputs/select'
import { TextBox } from '../../../../components/common/inputs/textBox'
import { TitleLabel } from "../../../../components/common/titleLabel"
import { AttentionLabel } from "../../../../components/common/attentionLabel"
import { translate } from '../../../../../i18n'

interface SelectBoxFieldProps {
    title: string
    name: string
    selectLabel: string
    selectNameApendix?: string
    otherLabel?: string
    otherNameApendix?: string
    otherTitle?: string
    required: boolean
    control: Control<Inputs>
    options?: PostDecodeMstDto[]
    addUnselected?: boolean
    showOtherSelection?: string[]
    isSubField?: boolean
    children?: ReactNode
}

const SelectBoxField: React.FC<SelectBoxFieldProps> = (props) => {
    const {
        title,
        name,
        selectLabel,
        selectNameApendix,
        otherLabel,
        otherNameApendix,
        otherTitle,
        required,
        control,
        options,
        addUnselected,
        showOtherSelection,
        isSubField,
        children
    } = props
    
    const selectName = selectNameApendix? `${name}.${otherNameApendix}`: `${name}.select`;
    const otherName = otherNameApendix? `${name}.${otherNameApendix}`: `${name}.other`;
    
    const inputWatch = useWatch({ name: selectName as facilityReservationInputKey, control: control })
    const [ currentInput, setCurrentInput ] = useState<string>();
    
    useEffect(() => {
        if ( inputWatch === currentInput ) return;
        if ( typeof inputWatch !== 'string' ) return;
        setCurrentInput(inputWatch)
    }, [ inputWatch ])
    
    const optionsConvertedToLabelValue = options?.map(( option ) => ({ label: option.name, value: option.code }));
    if (addUnselected) {
        optionsConvertedToLabelValue?.unshift({
            label: translate('facilityReservationForm.form.common.unselected'),
            value: '',
        })
    }
    
    const shouldShowAdditional = () => {
        if ( !otherLabel || !optionsConvertedToLabelValue || !showOtherSelection ) return false
        const label = optionsConvertedToLabelValue.find(( option ) => option.value === currentInput)?.label
        if ( !label ) return false
        for ( const select of showOtherSelection ) {
            if (label.includes(select)) return true
            continue
        }
    }

    return (
        <GItem xs={12} mt={isSubField? 1: undefined}>
            {
                isSubField
                ?   <LabelGItemBase>{translate(title)}</LabelGItemBase>
                :   <div>
                        <TitleLabel>
                            {
                                required && (
                                    <AttentionLabel>*</AttentionLabel>
                                )
                            }
                            {translate(title)}
                        </TitleLabel>
                    </div>
            }
            
            <GItem xs={12}>
                <Select
                  fullWidth
                  name={selectName}
                  label={translate(selectLabel)}
                  valueLabels={optionsConvertedToLabelValue??[]}
                  required={addUnselected? false: required}
                />
            </GItem>
            
            { 
                ( otherLabel && currentInput && shouldShowAdditional() ) && (
                    <GItem xs={12} sx={{ mt: 1 }}>
                        <LabelGItemBase>{translate(otherTitle?? '')}</LabelGItemBase>
                        <TextBox 
                            name={otherName}
                            label={translate(otherLabel)}
                            maxLength={100} 
                            rows={2}
                            multiline 
                            required
                        />
                    </GItem>
                )
            }
            {children && (
                <>{children}</>
            )}
        </GItem>
    );
};

export default SelectBoxField;