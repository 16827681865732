import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { availabilityStatus, yesNo, Flag } from '../../containers/common/constant/classification'
import {
  clearFacilityReserve,
  FacilityReserveEntry,
  selectFacilityReserveEntry,
  selectReserveModifyUpdateDatetime,
} from '../../containers/common/store/slices/facilityReserve'
import { executePostReservation, executePutReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { toApiYmd, toApiYmdHms } from '../../utils/dateUtil'
import { undefinedPropsToNull, combineDecimalParts } from '../../utils/objectUtil'
import { blankToNull } from '../../utils/stringUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { facilityReservationCompletionUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { dispMode } from '../facilityReservationCompletion/facilityReservationCompletionService'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { ChildReservationInfoType, GoingTimeType } from '../common/reservation'
import { getProjectMstDataToRefer } from '../common/projectMst'
import { ReservationConfirmation } from '../../views/components/common/reservationDetail/reservationDetailGItems'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { GetProjectDetailDto } from '../../dataAccess/webApi/dto/projectsDto';

type ReservationReference = ReservationConfirmation & {
  useReason: GetUseReasonDto,
}

export const useAction = () => {
  const errorHandler = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const updateDatetime = useSelector(selectReserveModifyUpdateDatetime)
  

  const [projectMst, setProjectMst] = useState< GetProjectDetailDto >();

  const reservationReference: ReservationReference | undefined = useMemo(() => {
    if (projectMst && reserveEntry?.facility && reserveEntry?.parentInfo && reserveEntry?.input && reserveEntry?.useReason) {
      const { facility, parentInfo, useReasonCategory, useReasonDetailCategory, useReasonDetailDescription, input, useReason, childInfoList } = reserveEntry
      
      return {
        ...input,
        ...facility,
        facilityName: facility.facilityName,
        useReasonCategory: useReasonCategory,
        useReasonDetailCategory: useReasonDetailCategory,
        useReasonDetailDescription: useReasonDetailDescription,
        childInfoList,
        parentInfo,
        projectMst,
        useReason: {...useReason},
      }
    }
  }, [projectMst])

  const [reservationResultCode, setReservationResultCode] = useState<number>()

  // 初期表示
  useEffect(() => {
    const fetchData = async () => {
      addOperationLog({ operationId: OperationId.OP_00000001 });
      
      if (!reserveEntry || !reserveEntry.facility || !reserveEntry.usageDatetimes) {
        return;
      }
  
      const { facilityId, usageDatetimes } = reserveEntry;
      const toDates = usageDatetimes.map((usageDatetime) => usageDatetime.usageDate);
  
      try {
        const projectMstData = await getProjectMstDataToRefer(facilityId, toDates);
        setProjectMst(projectMstData);
      } catch (error) {
        console.error('Error fetching project master data:', error);
      }
    };
  
    fetchData(); // 非同期関数を呼び出し
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveEntry]); 


  // 上記の内容で申し込み
  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000026 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime());
    
    if (reserveEntry == null) {
      // 前画面情報無しの場合はエラーなので何もしない
      return
    }
    
    dispatch(
      showLoading({
        process: errorHandler(async () => {
          if (reserveEntry.reservationNo == null) {
            // 予約新規登録時
            // childInfoList の長さが 0 のときには何もせずに処理を終える
            // ※アプリケーションの仕様上この画面に到達するときには childInfoList の長さは 1 以上のはず
            if (reserveEntry.childInfoList.length > 0) {
              let clearFacilityReserveFlg: boolean = false

              const response = await postReservation(reserveEntry, operationLogDatetime)
              if (response.resultCode) {
                // 登録失敗時
                setReservationResultCode(response.resultCode)
              } else {
                // 登録成功時
                const displayMode = getDisplayMode(reserveEntry)
                history.push(
                  facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childInfoList[0].childId, displayMode)
                )
                clearFacilityReserveFlg = true
              }

              if (clearFacilityReserveFlg) dispatch(clearFacilityReserve())
            }
          } else {
            // 予約更新時
            if (updateDatetime) {
              const response = await putReservation(reserveEntry, updateDatetime, operationLogDatetime)
              if (response.resultCode) {
                // 登録失敗時
                setReservationResultCode(response.resultCode)
              } else {
                // 登録成功時
                const displayMode = getDisplayMode(reserveEntry)
                history.push(
                  facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childInfoList[0].childId, displayMode)
                )
                dispatch(clearFacilityReserve())
              }
            }
          }
        }, reserveEntry),
        isHiddenMain: false,
      })
    )
  }, [reserveEntry, updateDatetime, addOperationLog, dispatch, errorHandler, history])

  return {
    reserveEntry,
    reservationReference,
    reservationResultCode,
    decide,
  }
}

const getRegistDataCommonForPut = (facilityReserveEntry: FacilityReserveEntry) => {
  const entryInput = castNonNullable(facilityReserveEntry.input)

  const childInfo = convertChildInfoList(facilityReserveEntry.childInfoList[0])
  
  return {
    /* 施設ID */
    facilityId: facilityReserveEntry.facilityId,
    /* キャンセル待ち予約フラグ 
     *   複数日時の同時申込ではキャンセル待ちを選択できないため usageDatetimes の最初の要素だけを見れば十分
     */
    cancelWaitFlag: facilityReserveEntry.usageDatetimes[0].status === availabilityStatus.wait ? yesNo.yes : yesNo.no,
    /* 利用理由 */
    useReasonCategory: blankToNull(facilityReserveEntry.useReasonCategory),
    /* 利用詳細事由 */
    useReasonDetailCategory: blankToNull(facilityReserveEntry.useReasonDetailCategory),
    /* 利用詳細事由テキスト入力 */
    useReasonDetailDescription: blankToNull(facilityReserveEntry.useReasonDetailDescription),
    /* 昼食有無 */
    lunchFlag: entryInput.lunchFlag,
    /* おやつ有無 */
    snackFlag: entryInput.snackFlag,
    /* 見送り時のキャンセル待ち可否 */
    postponeCancelWaitFlag: entryInput.postponeCancelWaitFlag,
    /* 備考 */
    citizenNote: blankToNull(entryInput.citizenNote),
    /* 在住種別 */
    residenceCategory: castNonNullable(facilityReserveEntry.parentInfo?.residenceCategory),
    /** お子様ID & お子様生年月日 & 予約時利用項目 */
    ... childInfo,
    uploadDate: Date.now().toString(),
  }
}

const getRegistDataCommonForPost = (facilityReserveEntry: FacilityReserveEntry) => {
  const entryInput = castNonNullable(facilityReserveEntry.input)
  const childInfoList = facilityReserveEntry.childInfoList.map((childInfo) => convertChildInfoList(childInfo))
  
  return {
    /* 施設ID */
    facilityId: facilityReserveEntry.facilityId,
    /* キャンセル待ち予約フラグ 
     *   複数日時の同時申込ではキャンセル待ちを選択できないため usageDatetimes の最初の要素だけを見れば十分
     */
    cancelWaitFlag: facilityReserveEntry.usageDatetimes[0].status === availabilityStatus.wait ? yesNo.yes : yesNo.no,
    /* 利用理由 */
    useReasonCategory: blankToNull(facilityReserveEntry.useReasonCategory),
    /* 利用詳細事由 */
    useReasonDetailCategory: blankToNull(facilityReserveEntry.useReasonDetailCategory),
    /* 利用詳細事由テキスト入力 */
    useReasonDetailDescription: blankToNull(facilityReserveEntry.useReasonDetailDescription),
    /* 昼食有無 */
    lunchFlag: entryInput.lunchFlag,
    /* おやつ有無 */
    snackFlag: entryInput.snackFlag,
    /* 見送り時のキャンセル待ち可否 */
    postponeCancelWaitFlag: entryInput.postponeCancelWaitFlag,
    /* 備考 */
    citizenNote: blankToNull(entryInput.citizenNote),
    /* 在住種別 */
    residenceCategory: castNonNullable(facilityReserveEntry.parentInfo?.residenceCategory),
    /** 予約時利用項目含むデータ */
    childInfoList,
    uploadDate: Date.now().toString(),
  }
}

const postReservation = async (facilityReserveEntry: FacilityReserveEntry, operationLogDatetime: string) => {
  const goingDatetimes = convertGoingDatetimes(facilityReserveEntry.goingTimes)
  
  return executePostReservation(
    {
      ...getRegistDataCommonForPost(facilityReserveEntry),
      usageDatetimes: facilityReserveEntry.usageDatetimes.map((v) => ({
        /* 利用日 */
        usageDate: toApiYmd(v.usageDate),
        /* 利用開始日時 */
        useFromDatetime: toApiYmdHms(v.useFromDatetime),
        /* 利用終了日時 */
        useToDatetime: toApiYmdHms(v.useToDatetime),
        /** fileアップロード用日*/
        usageDateForUpload: v.useFromDatetime
      })),
      /** 登園・降園予定時間 */
      goingDatetimes,
      operationLogDatetime,
    }
  )
}

const putReservation = async (
  facilityReserveEntry: FacilityReserveEntry,
  updateDatetime: string,
  operationLogDatetime: string,
) => {
  const goingDatetimes = convertGoingDatetimes(facilityReserveEntry.goingTimes)

  return executePutReservation(
    castNonNullable(facilityReserveEntry.reservationNo),
    undefinedPropsToNull({
      ...getRegistDataCommonForPut(facilityReserveEntry),
      /* 利用日 */
      usageDate: toApiYmd(facilityReserveEntry.usageDatetimes[0].usageDate),
      /* 利用開始日時 */
      useFromDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useFromDatetime),
      /* 利用終了日時 */
      useToDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useToDatetime),
      /* 更新日時 */
      updateDatetime: updateDatetime,
      /** fileアップロード用日*/
      usageDateForUpload: facilityReserveEntry.usageDatetimes[0].useFromDatetime,
      operationLogDatetime,
      /** 登園・降園予定時間 */
      goingDatetimes,
    })
  )
}

const getDisplayMode = (reserveEntry: FacilityReserveEntry) => {
  let mode

  const facility = castNonNullable(reserveEntry.facility)

  // 新規登録
  if (reserveEntry.reservationNo == null) {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        // 即時予約
        mode = dispMode.insertRealtimeCanselWait
        // 承認予約
      } else {
        mode = dispMode.insertApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.insertRealtime
        // 承認予約
      } else {
        mode = dispMode.insertApproval
      }
    }
    // 変更
  } else {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtimeCanselWait
      } else {
        mode = dispMode.updateApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtime
        // 承認予約
      } else {
        mode = dispMode.updateApproval
      }
    }
  }

  return mode
}

const convertChildInfoList = (childReservationInfo: ChildReservationInfoType) => {
    const bodyTemperature = childReservationInfo.temperature? combineDecimalParts(childReservationInfo.temperature): null
    const height = childReservationInfo.height? combineDecimalParts(childReservationInfo.height): null
    const weight = childReservationInfo.weight? combineDecimalParts(childReservationInfo.weight): null
    
    return {
      /* お子様ID */
      childId: childReservationInfo.childId,
      /* お子様生年月日 = putの場合は削除*/
      childBirth: childReservationInfo.childInfo?.birthday ?? '',  // このページでは childInfo が undefined であることはありえない
      /** 医師連絡票 */
      ... (
        childReservationInfo.medicalDoc
        ? {
            hasMedicalDocSubmitted: childReservationInfo.medicalDoc.hasSubmitted?? Flag.OFF, 
            medicalDocs: childReservationInfo.medicalDoc.docs?? [],
          }
        : {
            hasMedicalDocSubmitted: Flag.OFF,
            medicalDocs: []
          }
      ),
      /** 症状 */
      ... (
        childReservationInfo.symptom
        ? {
            symptoms: (childReservationInfo.symptom.symptoms !== [] ? Object.values(childReservationInfo.symptom.symptoms).filter(s => s !== "").join(",") : null),
            symptomOther: (childReservationInfo.symptom.other !== '' ? childReservationInfo.symptom.other : null)
          } 
        : {
            symptoms: null,
            symptomOther: null
          }
      ),
      /** 病名 */
      ... (
        childReservationInfo.diagonosis
        ? {
            diagonosis: (childReservationInfo.diagonosis.first.select !== '' ? childReservationInfo.diagonosis.first.select : null),
            diagonosisOther: (childReservationInfo.diagonosis.first.other !== '' ? childReservationInfo.diagonosis.first.other : null),
            diagonosis2: (childReservationInfo.diagonosis.second.select !== '' ? childReservationInfo.diagonosis.second.select : null),
            diagonosisOther2: (childReservationInfo.diagonosis.second.other !== '' ? childReservationInfo.diagonosis.second.other : null),
          }
        : {
            diagonosis: null,
            diagonosisOther: null,
            diagonosis2: null,
            diagonosisOther2: null
          }
      ),
      /** 流行り病 */
      ... (
        childReservationInfo.epidemic
        ? {
          epidemic: childReservationInfo.epidemic.select,
          epidemicOther: childReservationInfo.epidemic.other
        }
        : {
          epidemic: null,
          epidemicOther: null
        }
      ),
      /** 服用薬 */
      ... (
        childReservationInfo.medicine
        ? {
            rxMedicineFlag: childReservationInfo.medicine.rx,
            otcMedicineFlag: childReservationInfo.medicine.otc
          }
        : {
            rxMedicineFlag: null,
            otcMedicineFlag: null
          }
      ),
      /** 体温 */
      bodyTemperature: (bodyTemperature? bodyTemperature.toString(): null),
      /** 熱性けいれん */
      febrileSeizuresFlag: childReservationInfo.febrileSeizures?? Flag.OFF,
      /** 容態変化時の対応 */
      ... (
        childReservationInfo.emergencyAction
        ? {
            emergencyAction: childReservationInfo.emergencyAction.action.select,
            emergencyActionNote: childReservationInfo.emergencyAction.note
          }
        : {
            emergencyAction: null,
            emergencyActionNote: null
          }
      ),
      /** 身長 */
      height: height,
      /** 体重 */
      weight: weight,
      /** 栄養方法 */
      ... (
        childReservationInfo.eatingMethod
        ? {
            eatingMethod: childReservationInfo.eatingMethod.method.select,
            eatingMethodDetail: childReservationInfo.eatingMethod.detail.select,
            milkAmount: combineDecimalParts(childReservationInfo.eatingMethod.milkAmount),
            milkFrequency: Number(childReservationInfo.eatingMethod.milkFrequency),
          }
        : {
            eatingMethod: null,
            eatingMethodDetail: null,
            milkAmount: null,
            milkFrequency: null
          }
      ),
      /** 食物アレルギー */
      foodAllergyFlag: childReservationInfo.allergy?.foodFlag?? Flag.OFF,
      /** 食事状況 */
      eatingAbility: childReservationInfo.eatingAbility?.select?? null,
      /** 行動 */
      ... (
        childReservationInfo.actions
        ? {
            actions: childReservationInfo.actions.action.select,
            sleepMethod: childReservationInfo.actions.sleepMethod,
          }
        : {
            actions: null,
            sleepMethod: null
          }
      ),
      /** 排泄 */
      toiletAbility: childReservationInfo.toiletAbility?.select?? null,
      /** 減免 */
      reductionType: childReservationInfo.reductionType?.select?? null
    }
}

const convertGoingDatetimes = (goingTimes: GoingTimeType[]) => {
  return goingTimes.map((goingTime) => ({
    goingToFacilityDatetime:
      goingTime.goingToFacilityDate && goingTime.goingToFacilityTime
        ? toApiYmdHms(new Date(`${goingTime.goingToFacilityDate} ${goingTime.goingToFacilityTime}`))
        : null,
    goingHomeDatetime:
      goingTime.goingHomeDate && goingTime.goingHomeTime
        ? toApiYmdHms(new Date(`${goingTime.goingHomeDate} ${goingTime.goingHomeTime}`))
        : null,
  }))
}