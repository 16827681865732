import React from 'react';
import YesNoField from './common/yesNoField'

export interface FebrileSeizuresFieldProps {
    childNum: number
    required: boolean
}

const FebrileSeizuresField = (props: FebrileSeizuresFieldProps) => {
    const { childNum, required } = props;

    return (
        <YesNoField 
            name={`children.${childNum}.febrileSeizures`}
            required={required}
            title={"facilityReservationForm.form.label.febrileSeizures"}
            label={"facilityReservationForm.form.label.febrileSeizures"}
        />
    );
}

export default FebrileSeizuresField;
