import React from 'react';
import YesNoField from './common/yesNoField'

export interface MedicineFieldProps {
    childNum: number
    required: boolean
}

const MedicineField = (props: MedicineFieldProps) => {
    const { childNum, required } = props;

    return (
        <>
            <YesNoField 
                name={`children.${childNum}.medicine.rx`}
                required={required}
                title={"facilityReservationForm.form.label.medicine.rx"}
                label={"facilityReservationForm.form.label.medicine.rx"}
            />
            <YesNoField 
                name={`children.${childNum}.medicine.otc`}
                required={required}
                title={"facilityReservationForm.form.label.medicine.otc"}
                label={"facilityReservationForm.form.label.medicine.otc"}
            />
        </>
        
    );
}

export default MedicineField;
