import { useCallback } from 'react'
import { LOCATION_STATUS } from '../common/constant/commonConstant'
import { FeeTimeSetting, OtherFeeSetting } from '../../dataAccess/webApi/dto/usageFeeSettingDto'
import { Fee } from './type'
import { formatNumberYenString } from '../../utils/stringUtil'
import { GRID_SIZE } from './enum'
import { GetDecodeMstByCategoriesReturn, getDecodeMstName } from '../common/decodeMst'
import { DecodeMstCategory } from '../common/constant/decodeMst'

type GetCommonLunchSnackFee = (
  arg: {
    locationStatus: number
    lunchFee: number
    lunchFeeDisplayFlag: number
    snackFee: number
    snackFeeDisplayFlag: number
  }[]
) => {
  lunchFee: number
  snackFee: number
  isSameLunchFeeAll: boolean
  isSameSnackFeeAll: boolean
  existsFeesFilteredCommonLocation: boolean
}

type GetOtherFeeSettings = (arg: { otherFeeSettings: OtherFeeSetting[]; location?: LOCATION_STATUS, hasOnlyDay?: boolean }) => Fee[]
type GetOtherFeeSettingsForCommonLocation = GetOtherFeeSettings

type GetFeeTimeSettings = (arg: {
  feeTimeSettings: FeeTimeSetting[]
  decodeMst: GetDecodeMstByCategoriesReturn
  ageCategoryId: number
  location: LOCATION_STATUS
}) => Fee[]

type UseGenerateUsageFeesCommonReturn = {
  getCommonLunchSnackFee: GetCommonLunchSnackFee
  getOtherFeeSettingsForCommonLocation: GetOtherFeeSettingsForCommonLocation
  getFeeTimeSettings: GetFeeTimeSettings
  getOtherFeeSettings: GetOtherFeeSettings
}
type UseGenerateUsageFeesCommonArg = {}
type UseGenerateUsageFeesCommon = (arg: UseGenerateUsageFeesCommonArg) => UseGenerateUsageFeesCommonReturn

export const useGenerateUsageFeesCommon: UseGenerateUsageFeesCommon = ({}) => {
  // NOTE: 適用地域が「共通」のみの利用料金設定
  const getCommonLunchSnackFee = useCallback<GetCommonLunchSnackFee>((fees) => {
    const feesFilteredCommonLocation = fees.filter((fee) => fee.locationStatus === LOCATION_STATUS.COMMON)
    const existsFeesFilteredCommonLocation = feesFilteredCommonLocation.length > 0
    const allLunchFees = feesFilteredCommonLocation
      .filter((timeBasedFee) => !!timeBasedFee.lunchFeeDisplayFlag)
      .map((timeBasedFee) => timeBasedFee.lunchFee)
    const lunchFee = allLunchFees[0]
    const allSnackFees = feesFilteredCommonLocation
      .filter((timeBasedFee) => !!timeBasedFee.snackFeeDisplayFlag)
      .map((timeBasedFee) => timeBasedFee.snackFee)
    const snackFee = allSnackFees[0]

    // NOTE: 適用地域「共通」の全給食代が同じか
    const isSameLunchFeeAll = allLunchFees.every((lunchFee) => lunchFee === allLunchFees[0])

    // NOTE: 適用地域「共通」の全おやつ代が同じか
    const isSameSnackFeeAll = allSnackFees.every((snackFee) => snackFee === allSnackFees[0])

    return {
      lunchFee,
      snackFee,
      isSameLunchFeeAll,
      isSameSnackFeeAll,
      existsFeesFilteredCommonLocation,
    }
  }, [])

  // NOTE: 適用地域が「共通」に設定されているその他料金を返す
  const getOtherFeeSettingsForCommonLocation = useCallback<GetOtherFeeSettingsForCommonLocation>(
    ({ otherFeeSettings, hasOnlyDay = false }) => {
      const fees: Fee[] = []

      otherFeeSettings
        .filter((otherFeeSetting) => otherFeeSetting.locationStatus === LOCATION_STATUS.COMMON)
        .forEach((otherFeeSetting) =>
          fees.push({
            text: [otherFeeSetting.otherFeeDisplayName, formatNumberYenString(otherFeeSetting.defaultFee)],
            gridSize: hasOnlyDay ? GRID_SIZE.COMMON_ONLY_DAY : GRID_SIZE.COMMON,
          })
        )

      return fees
    },
    []
  )

  // NOTE: 適用地域関係なく時間外料金を返す
  const getFeeTimeSettings = useCallback<GetFeeTimeSettings>(
    ({ feeTimeSettings, decodeMst, ageCategoryId, location }) => {
      const fees: Fee[] = []

      feeTimeSettings
        .filter(
          (feeTimeSetting) =>
            feeTimeSetting.ageCategoryId === ageCategoryId && feeTimeSetting.locationStatus === location
        )
        .forEach((feeTimeSetting) =>
          fees.push({
            text: [
              `時間外料金（${getDecodeMstName(
                decodeMst,
                DecodeMstCategory.SCHEDULED_DAY_TYPE,
                feeTimeSetting.scheduledDayType
              )}）`,
              `${formatNumberYenString(feeTimeSetting.overtimeFee)} / ${getDecodeMstName(
                decodeMst,
                DecodeMstCategory.TIME_UNIT_TYPE,
                feeTimeSetting.timeUnitType
              )}`,
            ],
            gridSize: GRID_SIZE.LONG,
          })
        )

      return fees
    },
    []
  )

  // NOTE: 適用地域「共通」以外のその他料金
  const getOtherFeeSettings = useCallback<GetOtherFeeSettings>(({ otherFeeSettings, location }) => {
    const fees: Fee[] = []

    otherFeeSettings
      .filter(
        (otherFeeSetting) =>
          otherFeeSetting.locationStatus !== LOCATION_STATUS.COMMON && otherFeeSetting.locationStatus === location
      )
      .forEach((otherFeeSetting) =>
        fees.push({
          text: [otherFeeSetting.otherFeeDisplayName, formatNumberYenString(otherFeeSetting.defaultFee)],
          gridSize: GRID_SIZE.NORMAL,
        })
      )

    return fees
  }, [])

  return {
    getCommonLunchSnackFee,
    getOtherFeeSettingsForCommonLocation,
    getFeeTimeSettings,
    getOtherFeeSettings,
  }
}
