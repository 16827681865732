import React from "react"
import {
  getGenderLabel,
  getYesNoLabel,
} from '../../../../containers/common/codeMaster'
import { ChildReservationInfoType } from '../../../../containers/common/reservation'
import { GetProjectDetailDto } from '../../../../dataAccess/webApi/dto/projectsDto'
import { formatYmd } from '../../../../utils/dateUtil'
import { LabelGItem, ValueGItem } from './reservationDetailParts'
import ByChildDetailContent from "./byChildDetailContent"
import { translate } from '../../../../i18n'

export type ChildContentProps = {
  childInfo:  ChildReservationInfoType,
  projectMst?: GetProjectDetailDto
}

const ChildContent: React.FC<ChildContentProps> = (props: ChildContentProps) => {
  const { childInfo, projectMst } = props;
  
  return (
    <>
      {/* お子さま */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.childName')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.name}</ValueGItem>
      {/* お子さまフリガナ */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.childNameKana')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.kana}</ValueGItem>
      {/* お子さま誕生日 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.birthday')}</LabelGItem>
      <ValueGItem>{childInfo.childInfo?.birthday? formatYmd(new Date(childInfo.childInfo?.birthday)): ''}</ValueGItem>
      {/* お子さま性別 */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.gender')}</LabelGItem>
      <ValueGItem>{getGenderLabel(childInfo.childInfo?.gender)}</ValueGItem>
      {/* お子さまアレルギー */}
      <LabelGItem>{translate('system.reservationDetailGItems.label.allergy')}</LabelGItem>
      <ValueGItem>{childInfo.allergy?.flag?getYesNoLabel(childInfo.allergy?.flag): ''}</ValueGItem>   
      {
        projectMst && (
          <ByChildDetailContent 
            childInfo={childInfo}
            projectMst={projectMst}
          />
        )
      }
    </>
  )
}

export default ChildContent;