import { Control  } from "react-hook-form"
import { Inputs } from "../../../../containers/facilityReservationForm/facilityReservationFormService"
import DecimalTypeField from "./common/decimalTypeField"

export interface BodyTempatureFieldProps {
    childNum: number
    required: boolean
    control: Control<Inputs>
}

const BodyTempatureField = (props: BodyTempatureFieldProps) => {
    const { childNum, required, control } = props

    return (
        <DecimalTypeField 
            name={`children.${childNum}.temperature`}
            title={"facilityReservationForm.form.label.bodyTemp.field"}
            intLabel={"facilityReservationForm.form.label.bodyTemp.int"}
            fracLabel={"facilityReservationForm.form.label.bodyTemp.frac"}
            unit={"℃"}
            required={required}
            control={control}
            intMax={43}
            intMin={35}
            intPlaceholder={37}
            fracMac={9}
            fracMin={0}
            fracPlaceholder={0}
        />
    )

}

export default BodyTempatureField;
