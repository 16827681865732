import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'
import { GetUseReasonDto } from '../../../../dataAccess/webApi/dto/useReasonDto'
import { ParentInfoType, ChildReservationInfoType, GoingTimeType } from '../../reservation'
import { GetProjectDetailDto } from '../../../../dataAccess/webApi/dto/projectsDto'
  
export interface FacilityReserveDeleteEntry {
  reservationNo: string
  facilityId: string
  
  status: string  
  
  useReasonCategory?: string
  
  usageDate: ElapsedMillisecond
  useFromDatetime: ElapsedMillisecond
  useToDatetime: ElapsedMillisecond
  
  goingTime: GoingTimeType
  
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  citizenNote?: string  
  
  facilityName: string //
  immediatelyReservationFlag: string
  lunchAcceptFlag: string
  snackAcceptFlag: string
  postponeCancelWaitAcceptFlag: string  
  
  /** 保護者情報 */
  parentInfo: Omit<ParentInfoType, 'residenceCategory'>

  /** お子様さま情報 */
  childInfoList: ChildReservationInfoType[]

  /** 利用目的マスタ情報 */
  useReason: GetUseReasonDto

  cancelLimit?: number
  cancelLimitTime?: ElapsedMillisecond
  cancelNote?: string
  cancelLimitHolidayOption?: string

  cancelReason?: string
  
  /** 事業マスタ */
  projectMst: GetProjectDetailDto
}

interface FacilityReserveDeleteState {
  entry?: FacilityReserveDeleteEntry
  /** 排他制御用更新日時 */
  updateDatetime?: string
}
const initialState: FacilityReserveDeleteState = {}

export const facilityReserveDeleteSlice = createSlice({
  name: 'facilityReserveDelete',
  initialState,
  reducers: {
    setFacilityReserveDeleteEntry: (state, action: PayloadAction<FacilityReserveDeleteEntry>) => {
      state.entry = action.payload
    },
    setFacilityReserveDeleteUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearFacilityReserveDelete: (state) => {
      delete state.entry
      delete state.updateDatetime
    },
  },
})

export const { setFacilityReserveDeleteEntry, setFacilityReserveDeleteUpdateDatetime, clearFacilityReserveDelete } =
  facilityReserveDeleteSlice.actions
export const selectFacilityReserveDeleteEntry = (state: RootState) => state.facilityReserveDelete.entry
export const selectFacilityReserveDeleteUpdateDatetime = (state: RootState) =>
  state.facilityReserveDelete.updateDatetime
export default facilityReserveDeleteSlice.reducer
