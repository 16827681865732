import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CALCULATION_PATTERN, USAGE_FEE_DISPLAY_STATUS } from '../common/constant/commonConstant'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { UseUsageFeeSetting, UsageFee, GenerateUsageFees } from './type'
import { useGenerateUsageFeesByAgeRegionBasedFees } from './useGenerateUsageFeesByAgeRegionBasedFees'
import { useGenerateUsageFeesByTimeBasedFees } from './useGenerateUsageFeesByTImeBasedFees'

export const useUsageFeeSetting: UseUsageFeeSetting = ({ decodeMst, usageFeeSetting, locationStatus, feeNote }) => {
  const sysCtrl = useSelector(selectSystemControl)

  const { generateUsageFeesByAgeRegionBasedFees } = useGenerateUsageFeesByAgeRegionBasedFees({})
  const { generateUsageFeesByTimeBasedFees } = useGenerateUsageFeesByTimeBasedFees({})

  const generateUsageFees = useCallback<GenerateUsageFees>(
    ({
      ageCategories,
      ageRegionBasedFees,
      timeBasedFees,
      feeTimeSettings,
      otherFeeSettings,
      locationStatus,
      decodeMst,
    }) => {
      return {
        [CALCULATION_PATTERN.NOTE]: [],
        [CALCULATION_PATTERN.PATTERN1]: generateUsageFeesByAgeRegionBasedFees({
          ageCategories,
          ageRegionBasedFees,
          feeTimeSettings,
          otherFeeSettings,
          locationStatus,
          decodeMst,
        }),
        [CALCULATION_PATTERN.PATTERN2]: generateUsageFeesByTimeBasedFees({
          ageCategories,
          timeBasedFees,
          feeTimeSettings,
          otherFeeSettings,
          locationStatus,
          decodeMst,
        }),
      }
    },
    [generateUsageFeesByAgeRegionBasedFees, generateUsageFeesByTimeBasedFees]
  )

  /**
   * NOTE: JSXに渡す利用料金設定を返す
   */
  const usageFees = useMemo<UsageFee[]>(() => {
    if (Object.keys(decodeMst).length === 0 || !usageFeeSetting || locationStatus.length === 0) return []

    const calculationPattern = usageFeeSetting.calculationPattern
    const ageCategories = usageFeeSetting.ageCategories
    const ageRegionBasedFees = usageFeeSetting.ageRegionBasedFees
    const timeBasedFees = usageFeeSetting.timeBasedFees
    const feeTimeSettings = usageFeeSetting.feeTimeSettings
    const otherFeeSettings = usageFeeSetting.otherFeeSettings

    return generateUsageFees({
      ageCategories,
      ageRegionBasedFees,
      timeBasedFees,
      feeTimeSettings,
      otherFeeSettings,
      locationStatus,
      decodeMst,
    })[calculationPattern]
  }, [decodeMst, usageFeeSetting, locationStatus, generateUsageFees])

  // NOTE: 利用料金案内を表示表示するか
  const shouldShowNote = useMemo(() => {
    const usageFeeDisplayStatus = sysCtrl.usageFeeDisplayStatus
    return (
      (usageFeeDisplayStatus === USAGE_FEE_DISPLAY_STATUS.NOTE ||
        usageFeeDisplayStatus === USAGE_FEE_DISPLAY_STATUS.ALL) &&
      !!feeNote
    )
  }, [sysCtrl, feeNote])

  // NOTE: 利用料金設定を表示するか
  const shouldShowSetting = useMemo(() => {
    const usageFeeDisplayStatus = sysCtrl.usageFeeDisplayStatus
    return (
      (usageFeeDisplayStatus === USAGE_FEE_DISPLAY_STATUS.SETTING ||
        usageFeeDisplayStatus === USAGE_FEE_DISPLAY_STATUS.ALL) &&
      usageFees.length > 0
    )
  }, [sysCtrl, usageFees])

  return {
    shouldShowSetting,
    shouldShowNote,
    usageFees,
  }
}
