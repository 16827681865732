import React, { useCallback } from 'react';
import { Control, useFieldArray, useWatch } from "react-hook-form"
import { Flag } from '../../../../containers/common/constant/classification'
import { MEDICAL_DOC_USAGE } from '../../../../containers/common/constant/projectMst'
import { Inputs, facilityReservationInputKey } from '../../../../containers/facilityReservationForm/facilityReservationFormService'
import { GItem } from "../../../components/common/grids"
import { ButtonS } from '../../../components/common/buttons/buttonS'
import { MedicalDocUploadField } from './medicalDocUploadField'
import YesNoField from './common/yesNoField'
import PreserveLineText from "./common/preserveLineText"
import { translate } from '../../../../i18n'

export interface MedicalDocFieldProps {
    childNum: number
    note: string
    medicalDocUsage: string
    control: Control<Inputs>
}

const MedicalDocField = (props: MedicalDocFieldProps) => {
    const { childNum, note, medicalDocUsage, control } = props;
    
    const submittionName = `children.${childNum}.medicalDoc.hasSubmitted` as facilityReservationInputKey
    const uploadName = `children.${childNum}.medicalDoc.docs` as  `children.${number}.medicalDoc.docs`
    const { fields, append } = useFieldArray({
        name: uploadName,
        control,
    })
    const watchUploadFields =useWatch({
        name: uploadName,
        control
    })
    
    const isOptional = medicalDocUsage === MEDICAL_DOC_USAGE.OPTIONAL
    const validateMedicalDoc = useCallback((value: string) => {
        if ( isOptional ) return true
        
        if ( value === Flag.OFF ) {
            return  translate("facilityReservationForm.form.error.medicalDoc.get")
        }
        
        if ( medicalDocUsage === MEDICAL_DOC_USAGE.TILL_UPLOAD ) {
            const hasValidDocs = watchUploadFields.some((field) => field.tempUploadKey);
            if (!hasValidDocs) {
              return translate("facilityReservationForm.form.error.medicalDoc.upload")
            }
        }
    
        return true
    }, [ watchUploadFields ])

    return (
        <GItem xs={12}>
            <YesNoField 
                name={submittionName}
                required={!isOptional}
                title={"facilityReservationForm.form.label.medicalDoc.field"}
                label={"facilityReservationForm.form.label.medicalDoc.field"}
                validation={validateMedicalDoc}
            />
            {/** 施設備考 */}
            <PreserveLineText content={note} />
            <div>
                {fields.map((item, docIndex) => (
                    <MedicalDocUploadField
                        key={item.id}
                        name={`${uploadName}.${docIndex}`}
                        registeredFileKey={item.tempUploadKey}
                    />
                ))}
                {fields?.length < 5 && (
                    <GItem
                        xs={12}
                        mt={1}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <ButtonS
                            sx={{ width: 'fit-content' }}
                            onClick={() => append({
                                fileName: null,
                                delFlg: '0',
                                tempUploadKey: null,
                            })}
                        >
                            {translate('facilityReservationForm.form.common.add')}
                        </ButtonS>
                    </GItem>
                )}
            </div>
        </GItem>
    );
}

export default MedicalDocField;
