import {
    useSelector,
} from 'react-redux'
import { Grid, styled  } from '@mui/material'
import { Redirect } from 'react-router'
import {
  // identityEmailVerificationUrl,
  // identityVerificationUrl,
  rootUrl,
} from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/otp/otpService'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { translate } from '../../i18n'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
// import { IdentityRedirect } from '../components/common/identityRedirect'
import { ButtonM } from '../components/common/buttons/buttonM'
import { ElevatedCard } from '../components/common/elevatedCard'
import { AttentionLabel } from '../components/common/attentionLabel'
import { SuccessStatusLabel } from '../components/common/successStatusLabel'
// import { yesNo } from '../../containers/common/constant/classification'
import {
    selectLoginError,
} from '../../containers/common/store/slices/login'
import { selectIsSuccessResetPassword } from '../../containers/common/store/slices/resetPassword'

// メッセージコンテナ
const NoticeGContainer = styled(GItemContainer)({
  justifyContent: 'center',
  paddingBottom: 20,
})

// 説明文コンテナ
const DescriptionContainer = styled('p')({
  whiteSpace: 'pre-wrap',
})

// メインコンテンツコンテナ
const CustomDivContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

// メインコンテンツのサブコンテナ
const CustomDivSubContainer = styled('div')(({ theme }) => ({
  margin: '10px auto',
  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

/**
 * ログイン画面UIコンポーネント
 */
export const OTP = () => {
  const {
    // isLoggedIn,
    formMethods,
    onSubmit,
    // isVerifiedEmail,
    // isVerifiedTel,
    from,
    // smsSubscriptionFlag,
    // phoneCertificationFlag,
    isOTPAuthenticated,
} = useAction()
  const error = useSelector(selectLoginError)
  const isSuccessResetPassword = useSelector(selectIsSuccessResetPassword)
//   const dispatch = useDispatch()

  if (isOTPAuthenticated) {
    return <Redirect to={from ? from : rootUrl.url()} />
  }

  return (
    <Grid>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <GContainer >
          {/* 左余白 */}
          <GItem md={3}></GItem>

          {/* メインコンテンツ */}
          <GItem xs={12} md={6}>
            <CustomDivContainer>

              {/* タイトル */}
              <ElevatedCard
                title={translate('otp.title.otp')}
                textAlign='center'
              >
                <GContainer rowSpacing={2}>
  
                  {/* パスワード変更完了メッセージ */}
                  {isSuccessResetPassword &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <SuccessStatusLabel>
                          {translate('resetPassword.text.success')}
                        </SuccessStatusLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }

                  {/* 説明 */}
                  <CustomDivSubContainer>
                    <DescriptionContainer>
                      {translate('forgotPassword.text.description')}
                    </DescriptionContainer>
                  </CustomDivSubContainer>
    
                  {/* エラーメッセージ表示 */}
                  {error &&
                    <CustomDivSubContainer>
                      <NoticeGContainer>
                        <AttentionLabel>
                          {translate(error)}
                        </AttentionLabel>
                      </NoticeGContainer>
                    </CustomDivSubContainer>
                  }
    
                  {/* ワンタイムパスワード */}
                  <CustomDivSubContainer>
                    {translate('otp.label.otp')}
                    <TextBox
                      name="otp"
                      label={translate('otp.label.otp')}
                      maxLength={320}
                      textType="oneTimeCode"
                      placeholder={translate('otp.placeholder.otp')}
                      required
                      requiredOnly={true}
                    />
                  </CustomDivSubContainer>
        
                  {/* ログインボタン */}
                  <CustomDivSubContainer>
                    <ButtonM fullWidth type="submit">
                      {translate('login.button.login')}
                    </ButtonM>
                  </CustomDivSubContainer>
                </GContainer>
              </ElevatedCard>
            </CustomDivContainer>
          </GItem>

          {/* 右余白 */}
          <GItem md={3}></GItem>
        </GContainer>
      </Form>
    </Grid>
  )
}
