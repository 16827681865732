import { useEffect, useState } from "react"
import { Control, useFormState, useWatch } from "react-hook-form"
import { FormHelperText } from "@mui/material";
import { Inputs, facilityReservationInputKey } from '../../../../../containers/facilityReservationForm/facilityReservationFormService';
import { getValueByNameStr } from "../../../../../utils/objectUtil";
import { GContainer, GItem, LabelGItemBase } from "../../../../components/common/grids"
import { NumberBox } from '../../../../components/common/inputs/numberBox'
import { TitleLabel } from "../../../../components/common/titleLabel"
import { AttentionLabel } from "../../../../components/common/attentionLabel"
import { translate } from '../../../../../i18n'

const PADDING = '0 8px'

interface DecimalTypeFieldProps {
    name: string
    title: string
    intLabel: string
    fracLabel: string
    unit: string
    required: boolean
    control: Control<Inputs>
    intMax?: number
    intMin?: number
    intPlaceholder?: number
    fracMac?: number
    fracMin?: number
    fracPlaceholder?: number
    isSubField?: boolean
    mt?: number
}

const DecimalTypeField = (props: DecimalTypeFieldProps) => {
    const { 
        name, 
        title,
        intLabel,
        fracLabel,
        unit,
        required, 
        control,
        intMax,
        intMin,
        intPlaceholder,
        fracMac,
        fracMin,
        fracPlaceholder,
        isSubField,
    } = props
    const [ isIntegerRequired, setIsIntegerRequired ] = useState(false)
    
    const inteName = `${name}.integer`
    const fracName = `${name}.fractional`

    const { errors } = useFormState({ control });
    const watchFractional = useWatch({
        name: fracName as facilityReservationInputKey,
        control
    })
    
    const integerError = getValueByNameStr(errors, inteName as facilityReservationInputKey)
    const fractionalError = getValueByNameStr(errors, fracName as facilityReservationInputKey)
    
    useEffect(() => {
        if ( watchFractional ) {
            setIsIntegerRequired(true)
            return
        }
        setIsIntegerRequired(false)
    }, [watchFractional])

    return (
        <GItem xs={12} mt={isSubField? 1: undefined}>
            {
                isSubField
                ?   <LabelGItemBase>{translate(title)}</LabelGItemBase>
                :   <div>
                        <TitleLabel>
                            {
                                required && (
                                    <AttentionLabel>*</AttentionLabel>
                                )
                            }
                            {translate(title)}
                        </TitleLabel>
                    </div>
            }
            <GContainer alignItems="flex-end">
                <GItem xs={(intMax && intMax >= 100)? 1.5: 1}>
                    <NumberBox
                        name={inteName}
                        label={translate(intLabel)}
                        max={intMax}
                        min={intMin}
                        placeholder={intPlaceholder?.toString()}
                        control={control}
                        required={isIntegerRequired?? required}
                    />
                </GItem>
                <GItem xs="auto" style={{ display: 'flex', alignItems: 'center', padding: PADDING }}>
                    .
                </GItem>
                <GItem xs={(fracMac && fracMac >= 100)? 1.5: 1}>
                    <NumberBox
                        name={fracName}
                        label={translate(fracLabel)}
                        max={fracMac}
                        min={fracMin}
                        placeholder={fracPlaceholder?.toString()}
                        control={control}
                    />
                </GItem>
                <GItem xs="auto" style={{ display: 'flex', alignItems: 'center', padding: PADDING }}>
                    {unit}
                </GItem>
            </GContainer>
            {integerError && <FormHelperText error>{integerError.message}</FormHelperText>}
            {fractionalError && <FormHelperText error>少数部分は{fractionalError.message}</FormHelperText>}
        </GItem>
    )

}

export default DecimalTypeField;
