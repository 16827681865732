import { executePost } from '../common/apiCaller'
import { PostDecodeMstByCategoriesInputDto, PostDecodeMstDto, PostDecodeMstInputDto } from '../dto/decodeMstDto'

const apiName = 'public'
const apiPath = '/decode-mst'

export const executePostDecodeMst = (input: PostDecodeMstInputDto) => {
  return executePost<PostDecodeMstDto[]>(apiName, apiPath, input)
}

export const executePostDecodeMstByCategories = (input: PostDecodeMstByCategoriesInputDto) => {
  return executePost<PostDecodeMstDto[]>(apiName, `${apiPath}/categories`, input)
}
