import React from 'react';
import { getDoNotWaitlist } from '../../../../containers/common/codeMaster'
import { RadioButtonGroup } from '../../../components/common/inputs/radioButtonGroup';
import { GItem } from '../../../components/common/grids'
import { TitleLabel } from "../../../components/common/titleLabel"
import { AttentionLabel } from "../../../components/common/attentionLabel"
import { translate } from '../../../../i18n'


const CancelWaitField = () => {
    return (
        <GItem xs={12}>
            <div>
                <TitleLabel>
                    <AttentionLabel>*</AttentionLabel>
                    {translate("facilityReservationForm.form.label.cancelWait")}
                </TitleLabel>
            </div>
            <RadioButtonGroup
                name={"postponeCancelWaitFlag"}
                label={"facilityReservationForm.form.label.cancelWait"}
                row
                required
                buttonValueLabels={getDoNotWaitlist().reverse()}
            />
        </GItem>        
    );
}



export default CancelWaitField
