import { SelectChangeEvent } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { reservationStatus, reservableUnitPatterMinutes, yesNo, purposeOfUse as purposeOfUseValue, useReasonCode as useReasonCodeValue, Flag } from '../../containers/common/constant/classification'
import {
  FacilityReserveEntry,
  selectFacilityReserveEntry,
  setFacilityReserve,
  setReserveModifyUpdateDatetime,
  IsCertificateUseReasonViewFlags,
} from '../../containers/common/store/slices/facilityReserve'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { executeGetChildren } from '../../dataAccess/webApi/dao/childrenDao'
import { executeGetFacilityReservationsDays } from '../../dataAccess/webApi/dao/facilityReservationsDaysDao'
import { executeGetFacilityReservationsWeekCitizen } from '../../dataAccess/webApi/dao/facilityReservationsWeekCitizenDao'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetFacilityReservationsDaysDto } from '../../dataAccess/webApi/dto/facilityReservationsDaysDto'
import { GetFacilityReservationsWeekCitizenDto } from '../../dataAccess/webApi/dto/facilityReservationsWeekCitizenDto'
import { translate } from '../../i18n'
import { DateRange, ElapsedMillisecond, getNowTrimedTime, isValidDate, toApiYmd, formatHyphenYmd, formatYmdHm } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToOptional } from '../../utils/objectUtil'
import { promiseAllConcurrency } from '../../utils/promiseUtil'
import { fromNumber } from '../../utils/stringUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { facilityReservationFormUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getReservation, Reservation, ChildReservationInfoType} from '../common/reservation'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { executeGetReservations } from '../../dataAccess/webApi/dao/reservationsDao'
import { getUseReason } from '../common/useReason'
import { ReactBatchUpdater } from '../../utils/reactUtil'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { executePostDecodeMst } from '../../dataAccess/webApi/dao/decodeMstDao'
import { PostDecodeMstDto } from '../../dataAccess/webApi/dto/decodeMstDto'
import { DecodeMstCategory } from '../common/constant/decodeMst'

interface InsertUrlParams {
  facilityId: string
  childId: string
}
interface UpdateUrlParams {
  reservationNo: string
}
type UrlParams = InsertUrlParams | UpdateUrlParams

export interface Inputs {
  /** 利用希望日時 */
  usageDesiredDatetimes: {
    value: {
      /** 時間の基準となる日付 */
      baseDateOfTime: Date
      /** 日時範囲 */
      range: DateRange
      /** 空き状況ステータス */
      status: string
    }
  }[]
  purposeOfUse: string
  useReasonDetailIrregular: string
  useReasonDetailEmg: string
  useReasonDetailRefresh: string
  useReasonDetailDescriptionsIrregular: {
    value: string
  }[]
  useReasonDetailDescriptionsEmg: {
    value: string
  }[]
  useReasonDetailDescriptionsRefresh: {
    value: string
  }[]
  selectedChildrenIds: string[]
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep?: boolean
  facilityId?: string
  activeDate: ElapsedMillisecond
  purposeOfUse?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
  selectedChildrenIds?: string[]
  childs: { value: string; label: string }[]
}

interface PageState {
  activeDate: Date
  facilityId?: string
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  /** 変更前予約情報 */
  reservation?: Reservation
  purposeOfUse?: string
  useReasonDetailCategory?: string
  useReasonDetailDescription?: string
  /** 選択対象お子さまリスト */
  childs: { value: string; label: string }[]
  /** 空き状況（１週間分） */
  availabilities?: GetFacilityReservationsWeekCitizenDto[]
  /** 空き状況（選択した利用日分） */
  initialValidationAvailabilities?: GetFacilityReservationsDaysDto[]
  /** 利用目的マスタ情報 */
  useReason?: NullPropsToUndefinedType<GetUseReasonDto>
  /** 複数お子さま選択情報 */
  selectedChildrenIds: string[]
}

interface ShowMessage {
  Date: Date;
  message: string;
}

/** 最大並列実行数 */
const MAX_WORKER_COUNT = 4

/** facilityIdがパラメータにある場合＝未予約、ない場合＝予約変更 */
const isInsertParams = (urlParams: UrlParams): urlParams is InsertUrlParams => {
  return 'facilityId' in urlParams
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const urlParams = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const locationState = history.location.state
  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const sysCtrl = useSelector(selectSystemControl)
  const [isCheckUseReason, setIsCheckUseReason] = useState(() => (locationState?.purposeOfUse !== '' && locationState?.purposeOfUse !== null && locationState?.purposeOfUse !== undefined))
  const [symptomDecodeMaster, setSymptomDecodeMaster] = useState<PostDecodeMstDto[]>([])
  // 証明書管理による利用目的のだし分けフラグ
  const [isCertificateUseReasonViewFlags, setIsCertificateUserReasonViewFlags] = useState<IsCertificateUseReasonViewFlags>({irregularFlag: false, emgFlag: false, refreshFlag: false})
  const [state, setState] = useState<PageState>({
    ...(locationState?.isKeep
      ? {
          facilityId: locationState.facilityId,
          activeDate: new Date(locationState.activeDate),
          purposeOfUse: locationState.purposeOfUse,
          childs: (locationState.childs) ? locationState.childs : [],
          selectedChildrenIds: (locationState.selectedChildrenIds) ? locationState.selectedChildrenIds : [],
        }
      : {
        activeDate: locationState?.activeDate ? new Date(locationState.activeDate) : getNowTrimedTime(),
        selectedChildrenIds:[],
        childs: []
      }),
  })
  
  /** 変更前の日時 */
  const previousRange = useMemo(() => {
    if (state.reservation) {
      return {
        range: {
          from: new Date(state.reservation.useFromDatetime),
          to: new Date(state.reservation.useToDatetime),
        },
        baseDateOfTime: new Date(state.reservation.usageDate),
        isWaiting: state.reservation.status === reservationStatus.wait,
      }
    }
  }, [state.reservation])

  // 予約期間外の文言表示
  const notifyDateFetchErrorMessage = useCallback(
    () => dispatch(notifyMessage(translate('facilityReservationSelection.error.noReservationReceptionSetting')))
  ,[])

  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(locationState?.isKeep &&
        reserveEntry && {
          usageDesiredDatetimes: reserveEntry.usageDatetimes.map((v) => ({
            value: {
              /** 時間の基準となる日付 */
              baseDateOfTime: new Date(v.usageDate),
              /** 日時範囲 */
              range: { from: new Date(v.useFromDatetime), to: new Date(v.useToDatetime) },
              /** 空き状況ステータス */
              status: v.status,
            },
          })),
          selectedChildrenIds: reserveEntry.childInfoList.map(childInfo => childInfo.childId),
        }),
    },
  })
  
  const purposeOfUse = useWatch({ name: 'purposeOfUse', control: formMethods.control})
  const useReasonDetailIrregular = useWatch({ name: 'useReasonDetailIrregular', control: formMethods.control })
  const useReasonDetailEmg = useWatch({ name: 'useReasonDetailEmg', control: formMethods.control })
  const useReasonDetailRefresh = useWatch({ name: 'useReasonDetailRefresh', control: formMethods.control })
  
  const { append: appendUseReasonDetailDescriptionsIrregular } = useFieldArray({
      name: 'useReasonDetailDescriptionsIrregular',
      control: formMethods.control,
  })
  const { append: appendUseReasonDetailDescriptionsEmg } = useFieldArray({
    name: 'useReasonDetailDescriptionsEmg',
    control: formMethods.control,
  })
  const { append: appendUseReasonDetailDescriptionsRefresh } = useFieldArray({
    name: 'useReasonDetailDescriptionsRefresh',
    control: formMethods.control,
  })

  /** 施設側が予約受付設定を共通にしているかどうかの判定 */
  const commonFlag: typeof yesNo[keyof typeof yesNo] | null = useMemo(() => {
    // 共通の場合配列データが必ず1つとなるため
    if (!state.facility) return null
    if (state.facility.detailSetting.length !== 1) return yesNo.no
    if (state.facility.detailSetting[0].useReasonCode === useReasonCodeValue.common) return yesNo.yes
    return yesNo.no
  }, [state.facility])

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const datetime = sysCtrl.systemDatetime? new Date(sysCtrl.systemDatetime) : new Date();
          
          const symptomDecodeMst = (await executePostDecodeMst({ category: DecodeMstCategory.SYMPTOM })).result
          setSymptomDecodeMaster(symptomDecodeMst)
          // 利用目的別に設定されていて表示内容が1つしかないときの初期化処理
          const initializeSinglePurposeSetting = (facilityData: NullPropsToUndefinedType<GetFacilityDto>) => {
            if (
              ((facilityData.irregularFlag === yesNo.yes && facilityData.useReasonUsageFlagIrregular === yesNo.yes) ? 1 : 0) +
              ((facilityData.emergencyFlag === yesNo.yes && facilityData.useReasonUsageFlagEmg === yesNo.yes) ? 1 : 0) +
              ((facilityData.refreshFlag === yesNo.yes && facilityData.useReasonUsageFlagRefresh === yesNo.yes) ? 1 : 0) === 1
            ) {
              const purposeOfUseTmp = (facilityData.irregularFlag === yesNo.yes && facilityData.useReasonUsageFlagIrregular === yesNo.yes) ? purposeOfUseValue.irregular :
                (facilityData.emergencyFlag === yesNo.yes && facilityData.useReasonUsageFlagEmg === yesNo.yes) ? purposeOfUseValue.emergency :
                (facilityData.refreshFlag === yesNo.yes && facilityData.useReasonUsageFlagRefresh === yesNo.yes) ? purposeOfUseValue.refresh : undefined
              const batchUpdater = new ReactBatchUpdater()
              batchUpdater.add(() =>
                formMethods.reset({
                  ...formMethods.getValues(),
                  ...undefinedPropsToOptional({
                    purposeOfUse: purposeOfUseTmp,
                  }),
                })
              )
              batchUpdater.execute()
              return purposeOfUseTmp
            }
          }
          
          // useReason の初期化処理
          // useReasonCode
          const initializeUseReason = async (useReasonData: GetUseReasonDto, datetime: Date) => {
            let searchDate: Date
            let searchChildId: string
            let searchFacilityId: string
            let searchAvailabilitiesReservationNo: string | null = null
            let reservation: any
            let isNew = true
            let purposeOfUse: string | undefined
            let useReasonDetailIrregular: string | undefined
            let useReasonDetailEmg: string | undefined
            let useReasonDetailRefresh: string | undefined
            const detailDescriptionsIrregular = Array(useReasonData.detailsIrregular.length).fill(null).map(() => ({ value: '' }))
            const detailDescriptionsEmg = Array(useReasonData.detailsEmg.length).fill(null).map(() => ({ value: '' }))
            const detailDescriptionsRefresh = Array(useReasonData.detailsRefresh.length).fill(null).map(() => ({ value: '' }))
            const initialSelectedUsageApiYmds = getInitialSelectedUsageApiYmds(locationState?.isKeep, reserveEntry)
            if (isInsertParams(urlParams)) {
              searchChildId = urlParams.childId
              searchDate = state.activeDate
              searchFacilityId = urlParams.facilityId
            } else {
              reservation = await getReservation(urlParams.reservationNo)
              searchChildId = reservation.childInfoList[0].childId
              searchDate = locationState?.isKeep ? state.activeDate : reservation.usageDate
              purposeOfUse = reservation.useReasonCategory
              searchFacilityId = reservation.facilityId
              searchAvailabilitiesReservationNo = getAvailabilitiesReservationNo(reservation)
              isNew = false
              // 予約変更前情報を格納
              switch (reservation.useReasonCategory) {
                case purposeOfUseValue.irregular:
                  purposeOfUse = reservation.useReasonCategory
                  useReasonDetailIrregular = reservation.useReasonDetailCategory
                  if (reservation.useReasonDetailCategory) {
                    const index = Number(reservation.useReasonDetailCategory) - 1
                    detailDescriptionsIrregular[index].value = reservation.useReasonDetailDescription ?? ''
                  }
                  break
                case purposeOfUseValue.emergency:
                  purposeOfUse = reservation.useReasonCategory
                  useReasonDetailEmg = reservation.useReasonDetailCategory
                  if (reservation.useReasonDetailCategory) {
                    const index = Number(reservation.useReasonDetailCategory) - 1
                    detailDescriptionsEmg[index].value = reservation.useReasonDetailDescription ?? ''
                  }
                  break
                case purposeOfUseValue.refresh:
                  purposeOfUse = reservation.useReasonCategory
                  useReasonDetailRefresh = reservation.useReasonDetailCategory
                  if (reservation.useReasonDetailCategory) {
                    const index = Number(reservation.useReasonDetailCategory) - 1
                    detailDescriptionsRefresh[index].value = reservation.useReasonDetailDescription ?? ''
                  }
                  break
              }
            }
            
            const [facilityData, childrenResponse] = await Promise.all([
              getFacility(searchFacilityId, searchDate),
              executeGetChildren({ facilityId: searchFacilityId }),
            ])
            const childsData = (childrenResponse.result.length) ? childrenResponse.result : []

            if (childsData.length) {
              const isTmpCertificateUseReasonViewFlags = {
                irregularFlag: false,
                emgFlag: false,
                refreshFlag: false,
              }
              if (facilityData.certificateFlag === Flag.ON) {
                childsData.map((v) => {
                  if (!isInsertParams(urlParams) && v.childId !== searchChildId) return 
                  if (facilityData.certificateControlAtypical === Flag.OFF || (facilityData.certificateControlAtypical === Flag.ON && v.useFlagAtypical === Flag.ON)) isTmpCertificateUseReasonViewFlags.irregularFlag = true
                  if (facilityData.certificateControlEmergency === Flag.OFF || (facilityData.certificateControlEmergency === Flag.ON && v.useFlagEmergency === Flag.ON)) isTmpCertificateUseReasonViewFlags.emgFlag = true
                  if (facilityData.certificateControlRefresh === Flag.OFF || (facilityData.certificateControlRefresh === Flag.ON && v.useFlagRefresh === Flag.ON)) isTmpCertificateUseReasonViewFlags.refreshFlag = true
                })
              } else {
                // 設定情報がなければすべて表示するため true にする
                isTmpCertificateUseReasonViewFlags.irregularFlag = true
                isTmpCertificateUseReasonViewFlags.emgFlag = true
                isTmpCertificateUseReasonViewFlags.refreshFlag = true
              }

              setIsCertificateUserReasonViewFlags(isTmpCertificateUseReasonViewFlags)
            }
 
            // 戻るボタンで戻った時の処理
            if (!purposeOfUse) {
              switch (reserveEntry?.useReasonCategory) {
                case purposeOfUseValue.irregular:
                  purposeOfUse = reserveEntry?.useReasonCategory
                  useReasonDetailIrregular = reserveEntry.useReasonDetailCategory
                  if (reserveEntry.useReasonDetailCategory) {
                    const index = Number(reserveEntry.useReasonDetailCategory) - 1
                    detailDescriptionsIrregular[index].value = reserveEntry.useReasonDetailDescription ?? ''
                  }
                  break
                case purposeOfUseValue.emergency:
                  purposeOfUse = reserveEntry?.useReasonCategory
                  useReasonDetailEmg = reserveEntry.useReasonDetailCategory
                  if (reserveEntry.useReasonDetailCategory) {
                    const index = Number(reserveEntry.useReasonDetailCategory) - 1
                    detailDescriptionsEmg[index].value = reserveEntry.useReasonDetailDescription ?? ''
                  }
                  break
                case purposeOfUseValue.refresh:
                  purposeOfUse = reserveEntry?.useReasonCategory
                  useReasonDetailRefresh = reserveEntry.useReasonDetailCategory
                  if (reserveEntry.useReasonDetailCategory) {
                    const index = Number(reserveEntry.useReasonDetailCategory) - 1
                    detailDescriptionsRefresh[index].value = reserveEntry.useReasonDetailDescription ?? ''
                  }
                  break
                default:
                  purposeOfUse = locationState?.purposeOfUse ?? undefined
              }
            }
            
            appendUseReasonDetailDescriptionsIrregular(detailDescriptionsIrregular)
            appendUseReasonDetailDescriptionsEmg(detailDescriptionsEmg)
            appendUseReasonDetailDescriptionsRefresh(detailDescriptionsRefresh)
  
            const batchUpdater = new ReactBatchUpdater()
            batchUpdater.add(() =>
              formMethods.reset({
                ...formMethods.getValues(),
                ...undefinedPropsToOptional({
                  purposeOfUse: purposeOfUse,
                  useReasonDetailIrregular,
                  useReasonDetailEmg,
                  useReasonDetailRefresh,
                  selectedChildrenIds: childsData.filter(({ childId }: {childId: string}) => childId === searchChildId).map((child) => child.childId)
                }),
              })
            )
            batchUpdater.addSetter(setState, { 
              ...state,
              useReason: useReasonData,
              reservation: reservation,
              selectedChildrenIds: childsData.filter(({ childId }: {childId: string}) => childId === searchChildId).map((child) => child.childId)
            })

            batchUpdater.execute()
            
            let useReasonCode: string | undefined = purposeOfUse ?? initializeSinglePurposeSetting(facilityData)
            // 予約受付設定を設定しているか
            if (facilityData.detailSetting.length === 0) {
              notifyDateFetchErrorMessage();
              return;
            }
            
            if (facilityData.detailSetting[0].useReasonCode === null) {
              notifyDateFetchErrorMessage();
              return;
            }
            // 共通設定かどうか
            if (facilityData.detailSetting.length === 1 && facilityData.detailSetting[0].useReasonCode === useReasonCodeValue.common) {
              useReasonCode = '0';
            } else {
              const initialValidationAvailabilities = await getAvailabilitiesByDays(
                searchFacilityId,
                [searchChildId],
                initialSelectedUsageApiYmds,
                searchAvailabilitiesReservationNo,
                facilityData.detailSetting[0].useReasonCode,
              )
              
              if (useReasonCode === undefined) {
                const setChildsData = childsData.map((child) => ({ value: child.childId, label: child.name }))
                setState(prev => ({
                  ...prev!,
                  facilityId: searchFacilityId,
                  activeDate: locationState?.activeDate ? new Date(locationState.activeDate) : datetime,
                  facility: facilityData,
                  useReason: useReason,
                  initialValidationAvailabilities,
                  childs: (isNew) ? setChildsData : setChildsData.filter(({ value }) => value === searchChildId),
                }))
                
                if (initialValidationAvailabilities?.length) {
                  // 初期表示時バリデーション実行
                  formMethods.trigger(undefined, { shouldFocus: true })
                }
              } 
            }
            return useReasonCode
          }
          
          const useReason = await getUseReasonMaster()
          
          if (state.purposeOfUse) {
            let useReasonDetailIrregular: string | undefined
            let useReasonDetailEmg: string | undefined
            let useReasonDetailRefresh: string | undefined
            
            const detailDescriptionsIrregular = Array(useReason.detailsIrregular.length).fill(null).map(() => ({ value: '' }))
            const detailDescriptionsEmg = Array(useReason.detailsEmg.length).fill(null).map(() => ({ value: '' }))
            const detailDescriptionsRefresh = Array(useReason.detailsRefresh.length).fill(null).map(() => ({ value: '' }))
            
            switch (state.purposeOfUse) {
              case purposeOfUseValue.irregular:
                useReasonDetailIrregular = state.useReasonDetailCategory
                if (state.useReasonDetailCategory) {
                  const index = Number(state.useReasonDetailCategory) - 1
                  detailDescriptionsIrregular[index].value = state.useReasonDetailDescription ?? ''
                }
                break
              case purposeOfUseValue.emergency:
                useReasonDetailEmg = state.useReasonDetailCategory
                if (state.useReasonDetailCategory) {
                  const index = Number(state.useReasonDetailCategory) - 1
                  detailDescriptionsEmg[index].value = state.useReasonDetailDescription ?? ''
                }
                break
              case purposeOfUseValue.refresh:
                useReasonDetailRefresh = state.useReasonDetailCategory
                if (state.useReasonDetailCategory) {
                  const index = Number(state.useReasonDetailCategory) - 1
                  detailDescriptionsRefresh[index].value = state.useReasonDetailDescription ?? ''
                }
                break
            }
            
            appendUseReasonDetailDescriptionsIrregular(detailDescriptionsIrregular)
            appendUseReasonDetailDescriptionsEmg(detailDescriptionsEmg)
            appendUseReasonDetailDescriptionsRefresh(detailDescriptionsRefresh)
            const batchUpdater = new ReactBatchUpdater()

            batchUpdater.add(() =>
              formMethods.reset({
                ...formMethods.getValues(),
                ...undefinedPropsToOptional({
                  purposeOfUse: state.purposeOfUse,
                  useReasonDetailIrregular,
                  useReasonDetailEmg,
                  useReasonDetailRefresh,
                }),
              })
            )
            batchUpdater.addSetter(setState, { 
              ...state,
            })
            batchUpdater.execute()
          }
          
          const initUseReasonCode = (state.purposeOfUse) ?? await initializeUseReason(useReason, datetime)
          
          if (!initUseReasonCode) return
          if (locationState?.isKeep && reserveEntry) setIsCertificateUserReasonViewFlags(reserveEntry.isCertificateUseReasonViewFlags)
          if (isInsertParams(urlParams)) {

            const searchChildIds = locationState?.isKeep && reserveEntry
                                    ? reserveEntry.childInfoList.map((child) => child.childId)
                                    : [ urlParams.childId ]
                                    
            searchChildIds.forEach((childId) => {
              attachAccessData({
                accessData: [
                  { 
                    userIdRegFlag: yesNo.yes, 
                    childId: childId
                  }
                ]
              })              
            })

            const facilityWithChilds = await getFacilityAvailabilitiesWithChilds(urlParams.facilityId, searchChildIds[0], state.activeDate, null, initUseReasonCode);
            
            if (facilityWithChilds) {
              /** 正常 */
              setState({
                ...facilityWithChilds, 
                useReason, 
                purposeOfUse: initUseReasonCode, 
                selectedChildrenIds: searchChildIds
              })
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          } else {
            // 予約変更時
            const reservation = await getReservation(urlParams.reservationNo)
            attachAccessData({
              accessData: [
                {
                  userIdRegFlag: yesNo.yes,
                  childId: reservation.childInfoList[0].childId,
                  usageDate: toApiYmd(reservation.usageDate),
                  reservationNo: reservation.reservationNo,                  
                }
              ],
            })

            const searchDate = locationState?.isKeep ? state.activeDate : new Date(reservation.usageDate)

            const availabilitiesReservationNo = getAvailabilitiesReservationNo(reservation)
            const childId = reservation.childInfoList[0].childId;
            const facilityWithChilds = await getFacilityAvailabilitiesWithChilds(
                                          reservation.facilityId,
                                          childId,
                                          searchDate,
                                          availabilitiesReservationNo,
                                          initUseReasonCode,
                                        );


            if (facilityWithChilds) {
              /** 正常 */
              if (!locationState?.isKeep) {
                // 戻る以外の初期表示時のみ変更前範囲を初期選択
                let purposeOfUse: string | undefined
                let useReasonDetailIrregular: string | undefined
                let useReasonDetailEmg: string | undefined
                let useReasonDetailRefresh: string | undefined
                const detailDescriptionsIrregular = Array(useReason.detailsIrregular.length).fill(null).map(() => ({ value: '' }))
                const detailDescriptionsEmg = Array(useReason.detailsEmg.length).fill(null).map(() => ({ value: '' }))
                const detailDescriptionsRefresh = Array(useReason.detailsRefresh.length).fill(null).map(() => ({ value: '' }))
                switch (reservation.useReasonCategory) {
                  case purposeOfUseValue.irregular:
                    purposeOfUse = reservation.useReasonCategory
                    useReasonDetailIrregular = reservation.useReasonDetailCategory
                    if (reservation.useReasonDetailCategory) {
                      const index = Number(reservation.useReasonDetailCategory) - 1
                      detailDescriptionsIrregular[index].value = reservation.useReasonDetailDescription ?? ''
                    }
                    break
                  case purposeOfUseValue.emergency:
                    purposeOfUse = reservation.useReasonCategory
                    useReasonDetailEmg = reservation.useReasonDetailCategory
                    if (reservation.useReasonDetailCategory) {
                      const index = Number(reservation.useReasonDetailCategory) - 1
                      detailDescriptionsEmg[index].value = reservation.useReasonDetailDescription ?? ''
                    }
                    break
                  case purposeOfUseValue.refresh:
                    purposeOfUse = reservation.useReasonCategory
                    useReasonDetailRefresh = reservation.useReasonDetailCategory
                    if (reservation.useReasonDetailCategory) {
                      const index = Number(reservation.useReasonDetailCategory) - 1
                      detailDescriptionsRefresh[index].value = reservation.useReasonDetailDescription ?? ''
                    }
                    break
                  default:
                    purposeOfUse = undefined
                }
                formMethods.reset({
                  usageDesiredDatetimes: [
                    {
                      value: {
                        /** 時間の基準となる日付 */
                        baseDateOfTime: new Date(reservation.usageDate),
                        /** 日時範囲 */
                        range: {
                          from: new Date(reservation.useFromDatetime),
                          to: new Date(reservation.useToDatetime),
                        },
                        // 空き状況ステータスは、空き状況カレンダーコントロールの初期設定に任せる
                      },
                    },
                  ],
                  ...undefinedPropsToOptional({
                    purposeOfUse,
                    useReasonDetailIrregular,
                    useReasonDetailEmg,
                    useReasonDetailRefresh,
                  }),
                  selectedChildrenIds: [childId]
                })
                appendUseReasonDetailDescriptionsIrregular(detailDescriptionsIrregular)
                appendUseReasonDetailDescriptionsEmg(detailDescriptionsEmg)
                appendUseReasonDetailDescriptionsRefresh(detailDescriptionsRefresh)
                
              }
              setState({
                ...facilityWithChilds,
                /** 予約変更時は、子どもが確定しているため、こども一覧から対象の子どものみにする */
                childs: facilityWithChilds.childs.filter(({ value }) => value === childId),
                activeDate: searchDate,
                purposeOfUse: initUseReasonCode,
                reservation: reservation,
                useReason,
                selectedChildrenIds: facilityWithChilds.childs.filter(({ value }: {value: string}) => value === reservation.childInfoList[0].childId).map((child)=> child.value)
              })
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  // コンテンツが表示された後に一番上にスクロールする
  useEffect(() => {
    if (!isCheckUseReason) return
    setTimeout(() => {
      scrollToTop();
    }, 0)
  }, [isCheckUseReason])
  
  // 条件が変わったときに空き状況確認する
  useEffect(() => {
      if (state.selectedChildrenIds.length === 0) return
      if (!state.activeDate) return
      if (commonFlag === null) return

      const useReasonCode = (commonFlag === yesNo.yes) ? '0' : purposeOfUse
      if (useReasonCode === undefined) return

      
      dispatch(
        showLoading({
          process: errorHandle(async () => {
              
            const availabilities = await getAvailabilitiesMultipleChilds(
              castNonNullable(state.facilityId),
              state.selectedChildrenIds,
              state.activeDate,
              getAvailabilitiesReservationNo(state.reservation),
              useReasonCode
            ) 
            
            if (availabilities) {
              setState((old) => (
                { 
                  ...old, 
                  availabilities: availabilities.availabilities 
                })
              );
              if (!isCheckUseReason) setIsCheckUseReason(true)
            }else {
              // 異常処理
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      );
      
  }, [
      state.facilityId,
      state.activeDate,
      state.reservation,
      purposeOfUse,
      state.selectedChildrenIds,
      commonFlag,
    ]
  );

  const changeSelectedChildIds = (childId: string, checked: boolean) => {
    addOperationLog({ operationId: OperationId.OP_00000028 })
    
    if ( !childId ) return
    
    let updatedselectedChildrenIds = [...state.selectedChildrenIds]
    if( checked ) {
      if ( !updatedselectedChildrenIds.includes( childId ) ) {
        updatedselectedChildrenIds.push(childId);
      }
    }else {
      updatedselectedChildrenIds = updatedselectedChildrenIds.filter( ( id ) => id !== childId )
    }
    
    setState((old) => ({
      ...old,
      selectedChildrenIds: updatedselectedChildrenIds
    }))
  }
  
  const changeDropDownSelectedChildId = (event: SelectChangeEvent<string>) => {
    const childId = event.target.value;
    if ( !childId ) return;
    // if ( childId === state.selectedChildrenIds[0]) return
    
    setState((old) => ({
      ...old,
      selectedChildrenIds: [childId]
    }))
  }
  
  const changeDate = (date: Date | null) => {
    addOperationLog({ operationId: OperationId.OP_00000029 })
    
    if (!isValidDate(date)) {
      notifyDateFetchErrorMessage();
      return;
    }
    
    setState((old) => ({
      ...old,
      activeDate: date
    }))
  }
  
  const changeUseReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    formMethods.reset({
      ...formMethods.getValues(),
      purposeOfUse: event.target.value,
    })
    
    setState((old) => ({ 
      ...old, 
      purposeOfUse: event.target.value 
      
    }))
  }

  /**
   * 妥当性検査
   * @param data 入力値
   * @param facilityId 施設ID
   * @returns エラーが存在する場合true
   */
  const validate = useCallback(async (data: Inputs, facilityId: string) => {
    const response: {isError: boolean; showMessages: ShowMessage[]} = {
      isError: false,
      showMessages: []
    } 
    
    const facilityMap = await getFacilityMapByUsageDateElapsed(
      facilityId,
      new Set(data.usageDesiredDatetimes.map((v) => v.value.baseDateOfTime.getTime()))
    )
    
    const areAnyCheckboxesSelected = formMethods.getValues(`selectedChildrenIds`).every(item => item === '')
    if (areAnyCheckboxesSelected) {
      formMethods.setError(`selectedChildrenIds.0`, {
        message: translate(
          'facilityReservationSelection.error.noSelectionChild'
        ),
      })
      response.isError = true
    }

    // 対象日を取得する
    const entriesArray = Array.from(data.usageDesiredDatetimes.entries())

    for (const [idx, { value: { baseDateOfTime, range } }] of entriesArray) {
      const facility = facilityMap.get(baseDateOfTime.getTime())
      const minReservationMinute = facility?.minReservationMinute
      const reservableUnitPatternMinute = facility && reservableUnitPatterMinutes[facility?.reservableUnitPattern]
      if (minReservationMinute != null) {
        // 最低予約時間が設定されていれる場合
        const minute = differenceInMinutes(range.to, range.from)
        if (minute < minReservationMinute) {
          formMethods.setError(`usageDesiredDatetimes.${idx}.value`, {
            message: translate(
              'facilityReservationSelection.error.minReservationMinute',
              fromNumber(minReservationMinute)
            ),
          })
          response.isError = true
        }
      }
      if (reservableUnitPatternMinute != null) {
        // 予約可能単位が設定されていれる場合
        const minute = differenceInMinutes(range.to, range.from)
        if (minute % reservableUnitPatternMinute !== 0) {
          formMethods.setError(`usageDesiredDatetimes.${idx}.value`, {
            message: translate(
              'facilityReservationSelection.error.reservableUnitPattern',
              fromNumber(reservableUnitPatternMinute)
            ),
          })
          response.isError = true
        }
      }
      // 重複チェック
      const prevReservations = await executeGetReservations({ childId: state.selectedChildrenIds[0] ?? null, usageData: formatHyphenYmd(baseDateOfTime) })
      const duplicateConfirmControlFlag = sysCtrl?.duplicateConfirmControlFlag
      const duplicateUnsettledControlFlag = sysCtrl?.duplicateUnsettledControlFlag
      const duplicateWaitControlFlag = sysCtrl?.duplicateWaitControlFlag
      const crossBusinessFlg = sysCtrl?.crossBusinessDuplicationCheckFlag
      
      if (prevReservations.result.length > 0) {
        for (const reservation of prevReservations.result) {
          if (reservation.reservationNo === state?.reservation?.reservationNo) {
            continue;
          }
          
          if (crossBusinessFlg === yesNo.no && facility?.projectName !== reservation?.projectId) {
            continue;
          }
          
          if ( reservation.status === reservationStatus.canceled 
            || reservation.status === reservationStatus.canceledWait
            || reservation.status === reservationStatus.rejected
            || reservation.status === reservationStatus.unused ) {
            continue;
          }
            
          if ( new Date(reservation.useFromDatetime) >=　range.to || new Date(reservation.useToDatetime) <= range.from ) {
            continue;
          }
          
          const duplicateCtrlStatus = [];
          if ( duplicateUnsettledControlFlag === yesNo.yes ) {
            duplicateCtrlStatus.push(translate('facilityReservationSelection.error.duplicateReservationStatus.notFixed'))
          }
          if ( duplicateWaitControlFlag === yesNo.yes ) {
            duplicateCtrlStatus.push(translate('facilityReservationSelection.error.duplicateReservationStatus.wait'))
          }
          if ( duplicateConfirmControlFlag === yesNo.yes ) {
            duplicateCtrlStatus.push(translate('facilityReservationSelection.error.duplicateReservationStatus.fixed'))
          }
          const strDuplicateCtrlStatus = duplicateCtrlStatus.join('・')

          let isError = false
          let message = ''
          
          switch( reservation.status ) {
            case reservationStatus.notFixed:
              if ( duplicateUnsettledControlFlag === yesNo.yes ) {
                isError = true
                message += strDuplicateCtrlStatus
              }
              break
            case reservationStatus.fixed:
              if ( duplicateConfirmControlFlag === yesNo.yes ) {
                isError = true
                message += strDuplicateCtrlStatus
              }
              break
            case reservationStatus.wait:
              if ( duplicateWaitControlFlag === yesNo.yes ) {
                isError = true
                message += strDuplicateCtrlStatus
              }
              break
            case reservationStatus.used:
              isError = true
              message += translate('facilityReservationSelection.error.duplicateReservationStatus.used')
              break
            default:
              break
          }
          
          if ( isError ) {
            formMethods.setError(`usageDesiredDatetimes.${idx}.value`, {
              message: message + translate('facilityReservationSelection.error.duplicateReservationBase') + formatYmdHm(new Date(reservation.useFromDatetime)) + ' ~ ' + formatYmdHm(new Date(reservation.useToDatetime))
            })
            response.isError = true
            break
          }
        }
      }
    }

    return response
  }, [state])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000030 })

      const facilityId = castNonNullable(state.facilityId)
      
      const formatDateToYYYYMMDD = (dateTime: Date) => {
        // 年、月、日を取得
        const year = dateTime.getFullYear();
        const month = String(dateTime.getMonth() + 1).padStart(2, '0');
        const day = String(dateTime.getDate()).padStart(2, '0');
        
        // yyyy-mm-dd形式に変換
        const formattedDate = `${year}-${month}-${day}`
        return formattedDate;
      }
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            // 妥当性検査
            const isValidateError = await validate(data, facilityId)
            if (isValidateError.isError) {
              return
            }
            
            let useReasonDetailCategory = ''
            let useReasonDetailDescription = ''
      
            switch (data.purposeOfUse) {
              case purposeOfUseValue.irregular:
                useReasonDetailCategory = data.useReasonDetailIrregular
                if (useReasonDetailCategory){
                  const indexOfDescription = Number(useReasonDetailCategory) - 1
                  useReasonDetailDescription = data.useReasonDetailDescriptionsIrregular[indexOfDescription].value
                }
                break
              case purposeOfUseValue.emergency:
                useReasonDetailCategory = data.useReasonDetailEmg
                if (useReasonDetailCategory){
                  const indexOfDescription = Number(useReasonDetailCategory) - 1
                  useReasonDetailDescription = data.useReasonDetailDescriptionsEmg[indexOfDescription].value
                }
                break
              case purposeOfUseValue.refresh:
                useReasonDetailCategory = data.useReasonDetailRefresh
                if (useReasonDetailCategory){
                  const indexOfDescription = Number(useReasonDetailCategory) - 1
                  useReasonDetailDescription = data.useReasonDetailDescriptionsRefresh[indexOfDescription].value
                }
                break
            }
            dispatch(
              setFacilityReserve({
                facilityId: castNonNullable(state.facilityId),
                reservationNo: state.reservation?.reservationNo,
                // 確認しやすくするために利用開始日でソートしておく
                showMessages: isValidateError.showMessages
                .sort((a, b) => a.Date.getTime() - b.Date.getTime())
                .map(({ Date, ...rest}) => ({
                  ...rest,
                  Date: Date.toDateString()
                })),
                useReasonCategory: data.purposeOfUse,
                useReasonDetailCategory,
                useReasonDetailDescription,
                
                usageDatetimes: data.usageDesiredDatetimes
                .map((v) => ({
                  usageDate: v.value.baseDateOfTime.getTime(),
                  useFromDatetime: v.value.range.from.getTime(),
                  useToDatetime: v.value.range.to.getTime(),
                  usageBaseDate: formatDateToYYYYMMDD(v.value.baseDateOfTime),
                  status: v.value.status,
                }))
                .sort((a, b) => a.useFromDatetime - b.useFromDatetime),
                
                childInfoList: createChildInfoList(state.selectedChildrenIds, symptomDecodeMaster, state.reservation),
                ...(state.reservation
                    ? {
                        input: nullPropsToUndefined({
                          lunchFlag: state.reservation.lunchFlag,
                          snackFlag: state.reservation.snackFlag,
                          postponeCancelWaitFlag: state.reservation.postponeCancelWaitFlag,
                          citizenNote: state.reservation.citizenNote,
                        }),
                        goingTimes: [state.reservation.goingTime]
                      }
                    : {
                        goingTimes: []
                      }
                    ),
                isCertificateUseReasonViewFlags,
              })
            )
            if (state.reservation) {
              dispatch(setReserveModifyUpdateDatetime(state.reservation.updateDatetime))
            }

            /** 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管 */
            history.replace({
              ...history.location,
              state: {
                isKeep: true,
                facilityId: castNonNullable(state.facilityId),
                activeDate: state.activeDate.getTime(),
                purposeOfUse: data.purposeOfUse,
                useReasonDetailCategory: useReasonDetailCategory,
                useReasonDetailDescription: useReasonDetailDescription,
                selectedChildrenIds: state.selectedChildrenIds,
                childs: state.childs,
              },
            })
            history.push(facilityReservationFormUrl.url())
          }),
          isHiddenMain: false,
        })
      )
    },
    [
      reserveEntry,
      state.facilityId,
      state.activeDate,
      state.reservation,
      validate,
      addOperationLog,
      state.selectedChildrenIds,
    ]
  )
  
  /**
   * 画面を一番上までスクロール
   */
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return {
    facilityId: state.facilityId,
    facility: state.facility,
    childs: state.childs,
    selectedChildrenIds: state.selectedChildrenIds,
    activeDate: state.activeDate,
    availabilities: state.availabilities,
    initialValidationAvailabilities: state.initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    changeSelectedChildIds,
    changeDropDownSelectedChildId,
    changeDate,
    useReason: state?.useReason,
    purposeOfUse,
    useReasonDetailIrregular,
    useReasonDetailEmg,
    useReasonDetailRefresh,
    changeUseReason,
    isCheckUseReason,
    commonFlag,
    multiChildReservationAvailabilityFlag: sysCtrl?.multiChildReservationAvailabilityFlag,
    isCertificateUseReasonViewFlags: isCertificateUseReasonViewFlags,
  }
}

const createChildInfoList = (selectedChildrenIds: string[], symptomDecodeMaster: PostDecodeMstDto[], reservationData?: Reservation): ChildReservationInfoType[] => {
  if ( reservationData ) return reservationData.childInfoList;
  
  const childInfoList: ChildReservationInfoType[] = []
  for (const selectedChildId of selectedChildrenIds) {
    childInfoList.push({
      childId: selectedChildId
    })
  }
  return childInfoList
}

const getFacilityMapByUsageDateElapsed = async (facilityId: string, usageDateElapsedSet: Set<ElapsedMillisecond>) => {
  const facilities = await promiseAllConcurrency(
    Array.from(usageDateElapsedSet).map((usageDateElapsed) => async () => {
      const facility = await getFacility(facilityId, new Date(usageDateElapsed))
      return [usageDateElapsed, facility] as const
    }),
    MAX_WORKER_COUNT
  )
  return new Map(facilities)
}

/**
 * 空き状況取得用の受付No取得
 * キャンセル待ち予約の変更時は受付Noを指定して空き状況を取得する為
 * @param reservation
 * @returns
 */
const getAvailabilitiesReservationNo = (reservation: Reservation | undefined) =>
  reservation?.status === reservationStatus.wait ? reservation.reservationNo : null

/**
 * 空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 空き情報
 */
const getAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null,
  useReasonCode?: string
) => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekCitizen(facilityId, {
    childId,
    targetDate: toApiYmd(targetDate),
    useReasonCode: (useReasonCode) ? useReasonCode : '0',
    reservationNo,
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childId,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 空き情報を取得
 * @param facilityId 施設ID
 * @param childIds お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 空き情報
 */
const getAvailabilitiesMultipleChilds = async (
  facilityId: string,
  childrenIds: string[],
  targetDate: Date,
  reservationNo: string | null,
  useReasonCode?: string
) => {
  const selectedChildrenIds = childrenIds.filter(item => item !== '')
  const concatedSelectedChildrenIds = selectedChildrenIds.join(',')
  const availabilitiesResponse = await executeGetFacilityReservationsWeekCitizen(facilityId, {
    childId: concatedSelectedChildrenIds,
    targetDate: toApiYmd(targetDate),
    useReasonCode: (useReasonCode) ? useReasonCode : '0',
    reservationNo,
  })
  if (availabilitiesResponse.result.length) {
    return {
      selectedChildrenIds: childrenIds,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null,
  useReasonCode?: string
) => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilities(facilityId, childId, targetDate, reservationNo, useReasonCode),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChilds = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null,
  useReasonCode?: string,
) => {
  const [facilityAvailabilities, childrenResponse] = await Promise.all([
    getFacilityAvailabilities(facilityId, childId, targetDate, reservationNo, useReasonCode),
    executeGetChildren({ 
      facilityId,
      getLatestInterviewedFlag: Flag.ON
    }),
  ])

  if (facilityAvailabilities && childrenResponse.result.length) {
    return {
      ...facilityAvailabilities,
      childs: childrenResponse.result.map((child) => ({ value: child.childId, label: child.name })),
    }
  } else {
    return null
  }
}

/**
 * 初期選択の年月日を取得する
 *
 * @param isKeep 戻るで初期表示してreduxから状態を復元する際はtrue
 * @param reserveEntry 保存された状態
 * @returns 初期選択の利用日時から取得した年月日(yyyy-MM-dd)配列。※重複除去
 */
const getInitialSelectedUsageApiYmds = (isKeep?: boolean, reserveEntry?: FacilityReserveEntry) => {
  return isKeep && reserveEntry
    ? Array.from(new Set(reserveEntry.usageDatetimes.map((v) => toApiYmd(new Date(v.usageDate)))))
    : []
}

/**
 * 指定日分の空き状況を取得する
 *
 * @param facilityId 施設ID
 * @param childrenIds
 * @param targetApiYmds 取得対象年月日(yyyy-MM-ddの配列)
 * @param reservationNo キャンセル待ち予約変更時は対象予約No。それ以外はnull
 * @param useReasonCode 予約受付設定コード 0:共通 1:非定型 2:緊急 3:リフレッシュ
 * @returns 空き状況
 */
const getAvailabilitiesByDays = async (
  facilityId: string,
  childrenIds: string[],
  targetApiYmds: string[],
  reservationNo: string | null,
  useReasonCode?: string,
) => {
  const selectedChildrenIds = childrenIds.filter(item => item !== '')
  const concatedSelectedChildrenIds = selectedChildrenIds.join(',')
  let result
  if (targetApiYmds.length) {
    const resp = await executeGetFacilityReservationsDays(facilityId, {
      childId: concatedSelectedChildrenIds,
      targetDates: targetApiYmds,
      reservationNo,
      useReasonCode: (useReasonCode) ? useReasonCode : '0',
    })
    result = resp.result
  }
  return result
}

async function getUseReasonMaster() {
  return await getUseReason()
}
