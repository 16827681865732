export const MEDICAL_DOC_SUBMIT_STATUS = {
  NOT_YET: '未提出',
  UPLOADED: 'アップロード提出済',
  HANDED: '施設に直接提出済み',
} as const

// 提供証明書発行ステータス
export const PROVISIONED_CERTIFICATE_STATUS = {
  /** 未発行 */
  UNISSUED: '1',
  /** 発行済 */
  ISSUE: '2',
} as const
export type PROVISIONED_CERTIFICATE_STATUS =
  (typeof PROVISIONED_CERTIFICATE_STATUS)[keyof typeof PROVISIONED_CERTIFICATE_STATUS]

//
export const PROVISION_CERTIFICATE_USER_REISSUE_STATUS = {
  /** 再発行不可 */
  REISSUE_DISABLED: '1',
  /** 再発行可 */
  REISSUE_ALLOWED: '2',
  /** 差分のみ発行可 */
  DIFFERENCE_ALLOWED: '3',
} as const
export type PROVISION_CERTIFICATE_USER_REISSUE_STATUS =
  (typeof PROVISION_CERTIFICATE_USER_REISSUE_STATUS)[keyof typeof PROVISION_CERTIFICATE_USER_REISSUE_STATUS]

/** 利用料金設定の適応地域 */
export const LOCATION_STATUS = {
  /** 共通 */
  COMMON: 1,
  /** 市内 */
  INSIDE: 2,
  /** 市外 */
  OUTSIDE: 3,
  /** その他 */
  OTHER: 4,
} as const
export type LOCATION_STATUS = (typeof LOCATION_STATUS)[keyof typeof LOCATION_STATUS]

/** 利用料金設定表示ステータス */
export const USAGE_FEE_DISPLAY_STATUS = {
  /** 利用料金設定に登録されている設定 */
  SETTING: 1,
  /** 利用料金設定の最終確認画面の利用料金案内 */
  NOTE: 2,
  /** 両方表示 */
  ALL: 3,
} as const
export type USAGE_FEE_DISPLAY_STATUS = (typeof USAGE_FEE_DISPLAY_STATUS)[keyof typeof USAGE_FEE_DISPLAY_STATUS]

/** 利用料金設定パターン */
export const CALCULATION_PATTERN = {
  /** データが存在しない場合 */
  NOTE: 0,
  /** 年齢別料金設定 */
  PATTERN1: 1,
  /** 時間別料金設定 */
  PATTERN2: 2,
} as const
export type CALCULATION_PATTERN = (typeof CALCULATION_PATTERN)[keyof typeof CALCULATION_PATTERN]

export const AGE_REGION_BASED_FEE_DAY_TYPE = {
  /** 平日 */
  DAY: 1,
} as const
