import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAuthenticatedUser, signOut } from '../../utils/authUtil'
import { loginUrl } from './constant/appUrl'
import { useErrorHandle } from './error/errorHandler'
import { reset, showLoading } from './store/slices/application'
import { useOperationLog } from './operationLog'
import { OperationId } from './constant/operationLog'
import { selectIsOTPAuthenticated } from './store/slices/authority'
import { setIsOTPAuthenticated } from './store/slices/authority'
import { executePutAccountAuthentication } from '../../dataAccess/webApi/dao/accountDao'

export const useLogoutActions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const errorHandle = useErrorHandle()
  const { addOperationLog } = useOperationLog()
  const isOTPAuthenticated = useSelector(selectIsOTPAuthenticated)

  /**
   * ログアウトしてログイン画面へ移動。
   * 未ログイン状態でsignOutを呼び出しても特に何もしない為
   * ログイン・ログアウト共通で利用
   */
  const logout = useCallback(async() => {
    addOperationLog({ operationId: OperationId.OP_00000024 })

    if (isOTPAuthenticated) {
      const user = await getAuthenticatedUser()
      await executePutAccountAuthentication({ userId: user.attributes.sub, loginFlag: '0'})
    }

    dispatch(
      showLoading(
        errorHandle(async () => {
          await signOut()
          dispatch(reset({ isKeepAuthority: false }))
          if (isOTPAuthenticated) {
            dispatch(setIsOTPAuthenticated(false))
          }
          history.push(loginUrl.url())
        })
      )
    )
  }, [dispatch, history, errorHandle, addOperationLog])

  return { logout }
}
