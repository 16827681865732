import React from 'react';
import YesNoField from './common/yesNoField'

export interface FoodAllergyFieldProps {
    childNum: number
    required: boolean
}

const FoodAllergyField = (props: FoodAllergyFieldProps) => {
    const { childNum, required } = props;

    return (
        <YesNoField 
            name={`children.${childNum}.allergy.foodFlag`}
            required={required}
            title={"facilityReservationForm.form.label.foodAllergy"}
            label={"facilityReservationForm.form.label.foodAllergy"}
        />
    );
}

export default FoodAllergyField;