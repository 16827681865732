import { executeGet } from '../common/apiCaller'
import { 
    GetProjectsDto, 
    GetProjectsInputDto,
    GetProjectDetailDto
} from '../dto/projectsDto'

const apiName = 'public'
const apiPath = '/projects'

export const executeGetProjects = (input?: GetProjectsInputDto) => {
  return executeGet<GetProjectsDto[]>(apiName, apiPath, input)
}

export const executeGetProjectDetail = (projectId: string, targetDate: string) => {
  return executeGet<GetProjectDetailDto>(apiName, `${apiPath}/${projectId}/${targetDate}`)
}
