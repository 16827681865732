import { useCallback } from 'react'
import { executeGetControl } from '../../dataAccess/webApi/dao/controlDao'
import { fromApiYmdHms, initializeDateUtil, fromApiYmd } from '../../utils/dateUtil'
import { nullToUndefined, datePropsToNumber } from '../../utils/objectUtil'
import { setSystemControl } from './store/slices/systemControl'
import { useDispatch } from 'react-redux'

type UseControlReturn = {
  getControl: () => Promise<void>
}
type UseControlArg = {
  shouldSetTitle?: boolean
}
type UseControl = (arg: UseControlArg) => UseControlReturn

export const useControl: UseControl = ({ shouldSetTitle = false }) => {
  const dispatch = useDispatch()

  const getControl = useCallback(async () => {
    const controlRes = await executeGetControl()
    const systemDatetime = nullToUndefined(fromApiYmdHms(controlRes.result.systemDatetime))
    const serviceTitle = controlRes.result.serviceTitle
    if (shouldSetTitle) {
      document.title = serviceTitle
      document.querySelector('meta[name="description"]')?.setAttribute('content', serviceTitle)
    }
    initializeDateUtil(systemDatetime)
    dispatch(
      setSystemControl(
        datePropsToNumber({
          serviceTitle,
          serviceUrlForUser: controlRes.result.serviceUrlForUser ?? '',
          serviceUrlForManager: controlRes.result.serviceUrlForManager ?? '',
          iconImageName: controlRes.result.iconImageName,
          termsOfService: controlRes.result.termsOfService ?? '',
          s3BucketNameIcon: controlRes.result.s3BucketNameIcon,
          s3BucketNameCitizenUserReservationFile: controlRes.result.s3BucketNameCitizenUserReservationFile,
          projectSearchFlag: controlRes.result.projectSearchFlag,
          facilityCategorySearchFlag: controlRes.result.facilityCategorySearchFlag,
          ssoUseFlag: controlRes.result.ssoUseFlag,
          ssoUseName: controlRes.result.ssoUseName,
          ssoEmailCertificationFlag: controlRes.result.ssoEmailCertificationFlag,
          smsSubscriptionFlag: controlRes.result.smsSubscriptionFlag,
          phoneCertificationFlag: controlRes.result.phoneCertificationFlag,
          duplicateConfirmControlFlag: controlRes.result.duplicateConfirmControlFlag,
          duplicateUnsettledControlFlag: controlRes.result.duplicateUnsettledControlFlag,
          duplicateWaitControlFlag: controlRes.result.duplicateWaitControlFlag,
          crossBusinessDuplicationCheckFlag: controlRes.result.crossBusinessDuplicationCheckFlag,
          multiChildReservationAvailabilityFlag: controlRes.result.multiChildReservationAvailabilityFlag,
          systemDatetime,
          s3BucketNameManual: controlRes.result.s3BucketNameManual,
          s3BucketNameTermsOfUse: controlRes.result.s3BucketNameTermsOfUse,
          useCouponFunctionFlag: controlRes.result.useCouponFunctionFlag,
          ...(controlRes.result.usageCouponFromDate &&
            controlRes.result.usageCouponToDate && {
              usageCouponFromDate: fromApiYmd(controlRes.result.usageCouponFromDate),
              usageCouponToDate: fromApiYmd(controlRes.result.usageCouponToDate),
            }),

          unsubscribeAcceptFlag: controlRes.result.unsubscribeAcceptFlag,
          childUseEndAcceptFlag: controlRes.result.childUseEndAcceptFlag,
          descriptionForUnsubscribe: controlRes.result.descriptionForUnsubscribe ?? '',
          descriptionForChildUseEnd: controlRes.result.descriptionForChildUseEnd ?? '',
          interviewDailyAcceptFlag: controlRes.result.interviewDailyAcceptFlag,
          interviewBulkReceptionFlag: controlRes.result.interviewBulkReceptionFlag,
          provisionCertificateFeatureStatus: controlRes.result.provisionCertificateFeatureStatus,
          paymentStatusFlag: controlRes.result.paymentStatusFlag,
          provisionCertificatePaymentStatusFlag: controlRes.result.provisionCertificatePaymentStatusFlag,
          provisionCertificateUserReissueStatus: controlRes.result.provisionCertificateUserReissueStatus,
          usageFeeDisplayStatus: controlRes.result.usageFeeDisplayStatus,
          issuableDate: controlRes.result.issuableDate,
        })
      )
    )
  }, [dispatch, shouldSetTitle])

  return {
    getControl,
  }
}
