import { executeGetResidenceCategoryMst } from '../../dataAccess/webApi/dao/residenceCategoryMstDao'
import { LOCATION_STATUS } from './constant/commonConstant'

export type GetLocationStatusReturn = { id: LOCATION_STATUS; name: string }[]
type GetLocationStatus = () => Promise<GetLocationStatusReturn>

/**
 * 適応地域を返す
 *
 * @returns GetLocationStatusReturn
 */
export const getLocationStatus: GetLocationStatus = async () => {
  const res = await executeGetResidenceCategoryMst()
  const residenceCategory = res.result
  const locationStatus: GetLocationStatusReturn = [
    { id: LOCATION_STATUS.COMMON, name: '通常' },
    { id: LOCATION_STATUS.INSIDE, name: residenceCategory.insideName },
  ]
  if (residenceCategory.outsideName)
    locationStatus.push({ id: LOCATION_STATUS.OUTSIDE, name: residenceCategory.outsideName })
  if (residenceCategory.otherName) locationStatus.push({ id: LOCATION_STATUS.OTHER, name: residenceCategory.otherName })

  return locationStatus
}
