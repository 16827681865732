import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface FacilitySearchSettingMst {
  projectCategoryId: string
  projectCategoryName: string
  intermediateDisplayFlag: string
  searchConditionDisplayFlag: string
  ageSearchFlag: string | null
  projectSearchFlag: string | null
  facilityCategorySearchFlag: string | null
  areaSearchFlag: string | null
  reservationSearchFlag: string | null
  otherSearchFlag: string | null
  useReasonSearchFlag: string | null
  information: string | null
  createUser: string
  createDatetime: string
  updateUser: string
  updateDatetime: string
}

export interface ProjectMst {
  projectId: string
  projectName: string
  projectCategoryId: string | null
}


interface FacilitySearchSettingState {
  facilitySearchSettingMsts: FacilitySearchSettingMst[]
  projectMsts: ProjectMst[]
}

const initialState: FacilitySearchSettingState = {
  facilitySearchSettingMsts: [],
  projectMsts: [],
}

export const facilitySearchSettingMsts = createSlice({
  name: 'facilitySearchSettingMaster',
  initialState,
  reducers: {
    setFacilitySearchSettingMsts: (state, action: PayloadAction<FacilitySearchSettingMst[]>) => {
      state.facilitySearchSettingMsts = action.payload
    },
    setProjectMsts: (state, action: PayloadAction<ProjectMst[]>) => {
      state.projectMsts = action.payload
    },
  },
})

export const { setFacilitySearchSettingMsts, setProjectMsts } = facilitySearchSettingMsts.actions
export const selectFacilitySearchSettingMsts = (state: RootState) => state.facilitySearchSetting.facilitySearchSettingMsts
export const selectProjectMsts = (state: RootState) => state.facilitySearchSetting.projectMsts
export default facilitySearchSettingMsts.reducer
