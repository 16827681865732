import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { 
  useLocation,
  // useHistory,
} from 'react-router-dom'
import { showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import {
  selectIsLoggedIn,
  selectIsOTPAuthenticated,
  selectIsVerifiedEmail,
  selectIsVerifiedTel,
  setIsOTPAuthenticated,
} from '../common/store/slices/authority'
import { setLoginForm } from '../common/store/slices/login'
import { executeGetAccountOtp, executePutAccountAuthentication } from '../../dataAccess/webApi/dao/accountDao'
import { getAuthenticatedUser } from '../../utils/authUtil'

interface LocationState {
  from: Location
}
interface Inputs {
  otp: string
}

const ERROR_MESSAGE = {
  MISMATCH: '入力されたワンタイムパスワードは正しくありません。',
  EXPIRATION_PERIOD:
    'ワンタイムパスワードの有効期限が切れています。ログイン画面に戻り、最初からやり直してください。',
}

/**
 * ログイン画面フォームアクション
 */
export const useAction = () => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const { ssoUseFlag, ssoUseName, smsSubscriptionFlag, phoneCertificationFlag } = useSelector(selectSystemControl)
  const isOTPAuthenticated = useSelector(selectIsOTPAuthenticated)
  
  const formMethods = useForm<Inputs>()
  // const history = useHistory()

  const from = location.state?.from

  const isWithinOneHour = (generateDatetime: string) => {
    const now = new Date()
    const targetDate = new Date(generateDatetime)
    const diffInMilliseconds = now.getTime() - targetDate.getTime()

    const oneHourInMilliseconds = 60 * 60 * 1000

    return (
      diffInMilliseconds <= oneHourInMilliseconds && diffInMilliseconds >= 0
    )
  }

  const validateOtp = (otp: string, generateDatetime: string) => {
    if (otp !== formMethods.watch("otp")) {
      dispatch(setLoginForm({
        error: ERROR_MESSAGE.MISMATCH
      }))
      return false
    }
    if (!isWithinOneHour(generateDatetime)) {
      dispatch(setLoginForm({
        error: ERROR_MESSAGE.EXPIRATION_PERIOD
      }))
      return false
    }
    return true
  }

  /**
   * フォーム送信イベント処理
   * @param data フォーム入力値
   */
  const onSubmit = useCallback(
    async () => {
      dispatch(
        // ログインリクエスト中にローディングアイコンを表示する
        showLoading(
          (async () => {
            try {
              const response = await executeGetAccountOtp(true)
              const user = response.result
              const isOTPValid = validateOtp(user.otp ?? '', user.generateDatetime ?? '')
              if (isOTPValid) {
                const loginUser = await getAuthenticatedUser()
                const loginUserId = loginUser.attributes.sub
                const loginFlag = '1'
                await executePutAccountAuthentication({ userId: loginUserId, loginFlag })
                dispatch(setIsOTPAuthenticated(true))
              }              
            } catch (error) {
              console.log(error)
              // エラーメッセージ表示
              dispatch(setLoginForm({
                error: error instanceof Error ? error.message : String(error)
              }))
              dispatch(setIsOTPAuthenticated(false))
            }
          })
        )
      )
    },
    []
  )

  return {
    isLoggedIn,
    formMethods,
    onSubmit,
    isVerifiedEmail,
    isVerifiedTel,
    from,
    ssoUseFlag,
    ssoUseName,
    smsSubscriptionFlag,
    phoneCertificationFlag,
    isOTPAuthenticated,
  }
}
